Handlebars.registerPartial('partials/account-suggestion', Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n			<tbody class=\"show-header-small-screen\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"suggested_accounts") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":95,"column":13}}})) != null ? stack1 : "")
    + "			</tbody>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<input type = \"hidden\" id = accountSuggestionUserId"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":17,"column":56},"end":{"line":17,"column":66}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + " >\n					<input type = \"hidden\" id = accountSuggestionAccountId"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":18,"column":59},"end":{"line":18,"column":69}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"account_id") : depth0), depth0))
    + " >\n                    <input type = \"hidden\" id = accountSuggestionIsSso"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":19,"column":70},"end":{"line":19,"column":80}}}) : helper)))
    + " value = "
    + alias4(((helper = (helper = lookupProperty(helpers,"is_sso") || (depth0 != null ? lookupProperty(depth0,"is_sso") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"is_sso","hash":{},"data":data,"loc":{"start":{"line":19,"column":89},"end":{"line":19,"column":99}}}) : helper)))
    + " >\n                    <input type = \"hidden\" id = accountSuggestionIsActive"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":20,"column":73},"end":{"line":20,"column":83}}}) : helper)))
    + " value = "
    + alias4(((helper = (helper = lookupProperty(helpers,"ACTIVE") || (depth0 != null ? lookupProperty(depth0,"ACTIVE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACTIVE","hash":{},"data":data,"loc":{"start":{"line":20,"column":92},"end":{"line":20,"column":102}}}) : helper)))
    + " >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"vanity_url") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authn_url") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sso_id") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":29,"column":27}}})) != null ? stack1 : "")
    + "\n\n					<tr>\n						<td data-title=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_name",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":33,"column":22},"end":{"line":33,"column":58}}}))
    + "\" class=\"mya-suggested-account-name-cell\">\n							<input type = \"hidden\" id = accountSuggestionLogin"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":34,"column":57},"end":{"line":34,"column":67}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + " >\n							<p class=\"accountSuggestionContent\">"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"first_name") : depth0), depth0))
    + " "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"last_name") : depth0), depth0))
    + "&nbsp;\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"sso_id") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":11},"end":{"line":43,"column":44}}})) != null ? stack1 : "")
    + "							</p>\n						</td>\n\n						<td data-title=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userName",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":47,"column":22},"end":{"line":47,"column":62}}}))
    + "\" class=\"mya-suggested-account-userName-cell\">\n						    <p class=\"accountSuggestionContent\" >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"login") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":49,"column":14},"end":{"line":53,"column":21}}})) != null ? stack1 : "")
    + "    						 </p>\n                        </td>\n\n						<td data-title=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_company",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":57,"column":22},"end":{"line":57,"column":61}}}))
    + "\">\n						    <p class=\"accountSuggestionContent\"><span class=\"country-flag "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"point_of_sales_name") : depth0), depth0))
    + " col-1\"></span><span class=\"company-name col-11\" > "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"company_name") : depth0), depth0))
    + "</span></p>\n						</td>\n\n\n                        <td data-title=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_loginType",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":62,"column":40},"end":{"line":62,"column":81}}}))
    + "\">\n                            <p class=\"accountSuggestionContent capitalized\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"login") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":64,"column":32},"end":{"line":68,"column":39}}})) != null ? stack1 : "")
    + "                            </p>\n                        </td>\n\n						<td class=\"action-column\" data-title=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_actions",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":72,"column":44},"end":{"line":72,"column":83}}}))
    + "\">\n\n							<button class=\"btn btn-text-action btn-med\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isTravelDirector") : depths[1]),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.program(26, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":87,"column":35}}})) != null ? stack1 : "")
    + "\n\n							id=add_account_"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":90,"column":22},"end":{"line":90,"column":33}}}) : helper)))
    + ">\n							    <p><span class=\"btn-label text-btn-label\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_linkAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":91,"column":53},"end":{"line":91,"column":96}}}))
    + "</span></p>\n							</button>\n    					</td>\n    				</tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type = \"hidden\" id = accountSuggestionVanityUrl"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":22,"column":78},"end":{"line":22,"column":88}}}) : helper)))
    + " value = "
    + alias4(((helper = (helper = lookupProperty(helpers,"vanity_url") || (depth0 != null ? lookupProperty(depth0,"vanity_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vanity_url","hash":{},"data":data,"loc":{"start":{"line":22,"column":97},"end":{"line":22,"column":111}}}) : helper)))
    + " >\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type = \"hidden\" id = accountSuggestionAuthnUrl"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":25,"column":77},"end":{"line":25,"column":87}}}) : helper)))
    + " value = "
    + alias4(((helper = (helper = lookupProperty(helpers,"authn_url") || (depth0 != null ? lookupProperty(depth0,"authn_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"authn_url","hash":{},"data":data,"loc":{"start":{"line":25,"column":96},"end":{"line":25,"column":109}}}) : helper)))
    + " >\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type = \"hidden\" id = accountSuggestionSSOId"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":28,"column":74},"end":{"line":28,"column":84}}}) : helper)))
    + " value = "
    + alias4(((helper = (helper = lookupProperty(helpers,"sso_id") || (depth0 != null ? lookupProperty(depth0,"sso_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sso_id","hash":{},"data":data,"loc":{"start":{"line":28,"column":93},"end":{"line":28,"column":103}}}) : helper)))
    + " >\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"BLOCKED") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":42,"column":23}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        								<span data-control=\"tooltip\" data-trigger=\"hover\" data-content=\""
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_blockedAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":39,"column":80},"end":{"line":39,"column":126}}}))
    + "\">\n        									<i class=\"alert-text-error icon icon-warn\"></i>\n        								</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"login") || (depth0 != null ? lookupProperty(depth0,"login") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"login","hash":{},"data":data,"loc":{"start":{"line":50,"column":36},"end":{"line":50,"column":45}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sso_id") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":45},"end":{"line":50,"column":89}}})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;/<br />"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sso_id") || (depth0 != null ? lookupProperty(depth0,"sso_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sso_id","hash":{},"data":data,"loc":{"start":{"line":50,"column":72},"end":{"line":50,"column":82}}}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sso_id") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":14},"end":{"line":53,"column":14}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    						        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sso_id") || (depth0 != null ? lookupProperty(depth0,"sso_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sso_id","hash":{},"data":data,"loc":{"start":{"line":52,"column":18},"end":{"line":52,"column":28}}}) : helper)))
    + "\n    						    ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"manageYourAccount_standard",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":65,"column":36},"end":{"line":65,"column":76}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sso_id") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":76},"end":{"line":65,"column":145}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;/<br />"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_sso",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":65,"column":103},"end":{"line":65,"column":138}}}));
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sso_id") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":32},"end":{"line":68,"column":32}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_sso",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":67,"column":36},"end":{"line":67,"column":71}}}))
    + "\n                                ";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    onClick = \"mergeFunction(this,"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":76,"column":66},"end":{"line":76,"column":76}}}) : helper)))
    + ",true)\"\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sso_id") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":78,"column":32},"end":{"line":86,"column":39}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    onClick = \"openModal("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":79,"column":57},"end":{"line":79,"column":67}}}) : helper)))
    + ")\"\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ACTIVE") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":81,"column":36},"end":{"line":85,"column":43}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  stack1 = ((helper = (helper = lookupProperty(helpers,"BLOCKED") || (depth0 != null ? lookupProperty(depth0,"BLOCKED") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"BLOCKED","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":36},"end":{"line":85,"column":36}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"BLOCKED")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { return stack1; }
  else { return ''; }
},"31":function(container,depth0,helpers,partials,data) {
    return "                                    onClick = 'EG.showErrorTag(\"mergeAccountBlockedUser\")'\n                                    ";
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tbody>\n				<tr>\n					<td>\n					    <p>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_noRecordFound",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":101,"column":12},"end":{"line":101,"column":57}}}))
    + "</p>\n					</td>\n					<td>\n					</td>\n					<td>\n					</td>\n					<td>\n                    </td>\n				</tr>\n			</tbody>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n	<table class = \"data-table table-highlight\" data-control=\"table\">\n		<thead>\n			<tr>\n			    <th class=\"mya-suggested-account-name\"><p class=\"accountSuggestionHeadings\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_name",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":5,"column":83},"end":{"line":5,"column":119}}}))
    + "</p></th>\n				<th class=\"mya-suggested-account-userName\"><p class=\"accountSuggestionHeadings\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userName",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":6,"column":84},"end":{"line":6,"column":124}}}))
    + "</p></th>\n				<th class=\"mya-suggested-account-company\"><p class=\"accountSuggestionHeadings\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_company",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":7,"column":83},"end":{"line":7,"column":122}}}))
    + "</p></th>\n				<th class=\"mya-suggested-account-login\"><p class=\"accountSuggestionHeadings\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_loginType",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":8,"column":81},"end":{"line":8,"column":122}}}))
    + "</p></th>\n                <th class=\"mya-suggested-account-linkAccount\"><p class=\"accountSuggestionHeadings\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_action",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":9,"column":99},"end":{"line":9,"column":137}}}))
    + "</p></th>\n			</tr>\n		</thead>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"suggested_accounts") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(33, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":111,"column":9}}})) != null ? stack1 : "")
    + "	</table>\n";
},"useData":true,"useDepths":true}));