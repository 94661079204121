Handlebars.registerPartial('partials/manage-accounts-partial', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <h4 id=\"searchBoxSubheading\" class=\"search-option-heading\"> "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_searchAccountHeading",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":20,"column":100},"end":{"line":20,"column":152}}}))
    + " </h4> ";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"arbitrary-search-standard-login-div\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "	<div id=\"accountSuggestionPassword-modal\" class=\"modal-content\">\n	</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"emailDisambiguation-modal\" class=\"modal-content\">\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"userDisambiguation-modal\" class=\"modal-content\">\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"manageAccountsHeader\">\n    <h1 class=\" col-small-screen-6 linked_accounts_heading\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_ManageYourAccounts",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":2,"column":60},"end":{"line":2,"column":110}}}))
    + "</h1>\n    <div class=\"col-small-screen-6\" id=\"back-to-context\"></div>\n</div>\n<span id=\"message\"></span>\n<div class=\"row\">\n    <div class=\"col col-12\">\n    	<span id=\"linkedAccounts\"></span>\n    </div>\n</div>\n\n<div class=\"row\" id=\"account-search-div\">\n    <div class=\"col col-12\"><h2 class=\"heading-arbitrary-search\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_findAccountsLabel",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":13,"column":65},"end":{"line":13,"column":114}}}))
    + "</h2></div>\n    <div id=\"account-suggestions-grid\" class=\"col col-8\">\n        <h4 class=\"search-option-heading\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_suggestedAccountHeading",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":15,"column":42},"end":{"line":15,"column":97}}}))
    + "</h4>\n        <span id=\"accountSuggestions\"></span>\n        <span id=\"arbitrarySearchReuslt\"></span>\n    </div>\n    <div class=\"col col-4\">\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"searchBoxSubheading") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":170}}})) != null ? stack1 : "")
    + "\n        <div id=\"arbitrarySearchBox\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_esi_sso_enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":37},"end":{"line":21,"column":121}}})) != null ? stack1 : "")
    + "></div>\n    </div>\n</div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/modal"),depth0,{"name":"partials/uitk/modal","hash":{"class":"tool-modal","id":"accountSuggestionPassword"},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/modal"),depth0,{"name":"partials/uitk/modal","hash":{"class":"tool-modal","id":"emailDisambiguationModal"},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/modal"),depth0,{"name":"partials/uitk/modal","hash":{"class":"tool-modal","id":"userDisambiguationModal"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));