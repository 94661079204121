Handlebars.registerPartial('partials/arbitrary-search-result', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<h3 class = \"heading\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_manualSearchResult",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":74}}}))
    + "</h3>\n		<table class = \"usedTables\">\n			<thead>\n				<tr class=\"tableHeader\">\n					<th class = \"leftAlignTableHeader\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_user",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":76}}}))
    + "</th>\n					<th class = \"tableHeaderRow\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_company",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":73}}}))
    + "</th>\n					<th class = \"tableHeaderRow\"></th>\n				</tr>\n			</thead>\n\n			<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"account_suggestions") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":37,"column":20}}})) != null ? stack1 : "")
    + "        		</tbody>\n        	</table>\n        	<br>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<input type = \"hidden\" id = searchUser_id"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":15,"column":46},"end":{"line":15,"column":56}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + " >\n					<input type = \"hidden\" id = searchAccount_id"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":59}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"account_id") : depth0), depth0))
    + " >\n					<input type = \"hidden\" id = searchPassword"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":17,"column":47},"end":{"line":17,"column":57}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"password") : depth0), depth0))
    + ">\n					\n					<tr class = \"suggestion tableRow\">\n						<td class = \"leftAlignTableData\">\n							<input type = \"hidden\" id = searchLogin"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":21,"column":46},"end":{"line":21,"column":56}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + " >\n							"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"first_name") : depth0), depth0))
    + " "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"last_name") : depth0), depth0))
    + "<br>\n							<i>("
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + ")</i>\n						</td>\n												\n						<td class = \"centerAlignTableData\">\n							<span class=\"country-flag "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"point_of_sales_name") : depth0), depth0))
    + "\"></span> "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"companyName") : depth0), depth0))
    + "\n						</td>\n									\n						<td class = \"actionButton\">\n							<button class=\"btn btn-action btn-med    \" onClick = \"addArbitrarySearch("
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":31,"column":80},"end":{"line":31,"column":90}}}) : helper)))
    + ")\" >\n        						<span class=\"btn-label\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_linkAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":32,"column":38},"end":{"line":32,"column":81}}}))
    + "</span>\n        					</button>\n        				</td>\n        			</tr>\n											\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"account_suggestions") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":41,"column":15}}})) != null ? stack1 : "");
},"useData":true}));