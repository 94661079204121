Handlebars.registerPartial('partials/user-date-of-birth-confirmation-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\" popup-header flex-container\">\n    <p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"update_date_of_birth_title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":61}}}))
    + " </p>\n</div>\n\n<p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"update_date_of_birth_message",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":5,"column":59}}}))
    + "</p>\n\n<div class=\"popup-date-picker\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/dob"),depth0,{"name":"partials/dob","hash":{"name":"date-of-birth-picker","uitk":(depth0 != null ? lookupProperty(depth0,"uitk") : depth0),"id":"date-of-birth-picker","yearRange":(depth0 != null ? lookupProperty(depth0,"yearRange") : depth0),"monthRange":(depth0 != null ? lookupProperty(depth0,"monthRange") : depth0),"dateRange":(depth0 != null ? lookupProperty(depth0,"dateRange") : depth0),"attrs":(depth0 != null ? lookupProperty(depth0,"attrs") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n\n<p class=\"alert-text-error displayNone\" id=\"error-message\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"something_went_wrong",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":11,"column":59},"end":{"line":11,"column":107}}}))
    + "</p>\n\n<ul class=\"list-nobullet popup-body\">\n     <li><uitk-icon name=\"info\"></uitk-icon> "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"update_date_of_birth_instruction",(depth0 != null ? lookupProperty(depth0,"privacyURL") : depth0),{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":14,"column":45},"end":{"line":14,"column":116}}}))
    + "</li>\n </ul>\n\n<footer class=\"modal-footer cf\">\n<div class=\"modal-footer-btns date-of-birth-footer-btns popup-footer-btn\">\n        <button class=\"btn btn-action btn-med btn-no-margin auth-header-wrapper-fullwidth\" id=\"save\" onclick=\"javascript:updateDateOfBirth(this, '"
    + alias3(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":19,"column":146},"end":{"line":19,"column":156}}}) : helper)))
    + "','"
    + alias3(((helper = (helper = lookupProperty(helpers,"redirectionURL") || (depth0 != null ? lookupProperty(depth0,"redirectionURL") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectionURL","hash":{},"data":data,"loc":{"start":{"line":19,"column":159},"end":{"line":19,"column":177}}}) : helper)))
    + "');\">\n            <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"update_date_of_birth_save",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":89}}}))
    + "</span>\n        </button>\n     </div>\n</footer>\n";
},"usePartial":true,"useData":true}));