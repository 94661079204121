Handlebars.registerPartial('v2/sso-vanity-search', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"site-content\" id=\"sso-details-container\">\n    <div class=\"auth-header-wrapper-fullwidth align-center\">\n        <h1 class=\"font-light\">\n            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_main_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":62}}}))
    + "\n        </h1>\n        <h4>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_secondary_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":67}}}))
    + "</h4>\n\n        <div id=\"sso-disabled-message-container\">\n        </div>\n    </div>\n    <div class=\"auth-content-wrapper\">\n        <div id=\"sso-search-container\" class=\"box\">\n            <div class=\"box-padded\">\n                <div class=\"sso-search\">\n                    <form method=\"post\" action=\"javascript:void(0);\">\n                        <div class=\"input-group\">\n                            <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_email_label",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":85}}}))
    + "</label>\n                            <input id=\"email-id\" name=\"email-id\" type=\"email\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"emailId") || (depth0 != null ? lookupProperty(depth0,"emailId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"emailId","hash":{},"data":data,"loc":{"start":{"line":18,"column":85},"end":{"line":18,"column":96}}}) : helper)))
    + "\" autofocus\n                                   class=\"replay-safe\">\n                        </div>\n                        <div id=\"recaptcha-block\">\n                        </div>\n                        <div id=\"turnstile-block\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/turnstile-content"),depth0,{"name":"partials/turnstile-content","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                        <div id=\"captcha-parent-sso\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/captcha-content"),depth0,{"name":"partials/captcha-content","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n\n                        <p class=\"alert-text-error\" id=\"error-message\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":30,"column":71},"end":{"line":30,"column":87}}}) : helper)))
    + "</p>\n\n                        <button class=\"btn btn-action btn-med\" target=\"_parent\" type=\"submit\" id=\"search-sso-details\">\n                            <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_button_label",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":33,"column":52},"end":{"line":33,"column":103}}}))
    + "</span>\n                        </button>\n\n                        <p><a id=\"standard-login\" href=\"javascript:void(0);\" class=\"login-links\">\n                            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_standard_login_label",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":37,"column":87}}}))
    + "\n                        </a></p>\n                    </form>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true}));