Handlebars.registerPartial('partials/redirection-info-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center\">\n    <img src=\"images/redirection-image.png\" alt=\"rediretion_img\" id=\"redirection-modal-image\">\n    <div id=\"redirection-info-modal-div\">\n        <span class=\"loader loading loader-secondary\"></span>\n        <h3 id=\"redirection-info-modal-heading\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"redirection_modal_heading",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":87}}}))
    + "</h3>\n    </div>\n</div>\n";
},"useData":true}));