Handlebars.registerPartial('user-date-of-birth-confirmation-page', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n<head>\n\n    <title>\n        "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"terms_and_policy_page_title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":63}}}))
    + "\n    </title>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-meta-tag"),depth0,{"name":"auth-header-meta-tag","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-styles"),depth0,{"name":"auth-header-styles","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <meta name=\"description\" content=\"Egencial SSO\">\n    <meta name=\"author\" content=\"#\">\n    <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n    <COMMENT ID=PageId TITLE=\"AUTH_WEBAPP_LOGIN\"></COMMENT>\n</head>\n\n<body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"egencia-global-header"),depth0,{"name":"egencia-global-header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<script language=\"JavaScript\" type=\"text/JavaScript\">\n    overRideCommentTag();\n    $(document).ready(function(){\n\n        function redirectUserToRedirectionPage() {\n            externalCancelDateOfBirthRedirection(uitk, '"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"redirectionURL") || (depth0 != null ? lookupProperty(depth0,"redirectionURL") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectionURL","hash":{},"data":data,"loc":{"start":{"line":31,"column":56},"end":{"line":31,"column":76}}}) : helper))) != null ? stack1 : "")
    + "');\n        }\n        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));\n\n        var dateRange = range(1,31,1);\n        var monthRange = moment.months();\n        var yearRange = "
    + alias3(((helper = (helper = lookupProperty(helpers,"yearRange") || (depth0 != null ? lookupProperty(depth0,"yearRange") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"yearRange","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":37}}}) : helper)))
    + ";\n\n        uitk.subscribe(\"modal.close\", redirectUserToRedirectionPage);\n        var renderedContent = Handlebars.templates[\"partials/user-date-of-birth-confirmation-modal\"]({'redirectionURL' : '"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"redirectionURL") || (depth0 != null ? lookupProperty(depth0,"redirectionURL") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectionURL","hash":{},"data":data,"loc":{"start":{"line":40,"column":122},"end":{"line":40,"column":142}}}) : helper))) != null ? stack1 : "")
    + "','userId':'"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":40,"column":154},"end":{"line":40,"column":166}}}) : helper))) != null ? stack1 : "")
    + "','lastValidDate':'"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"lastValidDate") || (depth0 != null ? lookupProperty(depth0,"lastValidDate") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"lastValidDate","hash":{},"data":data,"loc":{"start":{"line":40,"column":185},"end":{"line":40,"column":204}}}) : helper))) != null ? stack1 : "")
    + "'\n            ,'dateRange':dateRange, 'monthRange':monthRange,'yearRange':yearRange, 'privacyURL':'"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"privacyURL") || (depth0 != null ? lookupProperty(depth0,"privacyURL") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"privacyURL","hash":{},"data":data,"loc":{"start":{"line":41,"column":97},"end":{"line":41,"column":113}}}) : helper))) != null ? stack1 : "")
    + "'});\n\n\n        var dateOfBirthPopUpModal = uitk.modal.create({\n            modalId: 'user-date-of-birth-confirmation',\n            content : renderedContent,\n            dismiss : false}, true);\n\n        var dateOfBirthPopUpModalCloseBtn = document.getElementsByClassName(\"modal-close\");\n        dateOfBirthPopUpModalCloseBtn[0].classList.add(\"disabled\");\n        dateOfBirthPopUpModalCloseBtn[0].classList.add(\"hidden\");\n\n\n        dateOfBirthPopUpModal.open();\n        sendPageStatus(\"Date of birth\",\"Auth.DateOfBirth.Popin.Displayed\");\n    });\n</script>\n\n<div class=\"site-content\">\n    <div class=\"row\">\n        <div class=\"col col-8 col-push-2\">\n            <div class=\"term-polic-div\">\n            </div>\n        </div>\n    </div>\n</div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n</body>\n</html>\n";
},"usePartial":true,"useData":true}));