Handlebars.registerPartial('partials/user-disambiguation-modal', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                \n                                    <tr>\n                                        <input type = \"hidden\" id = userDisambigAccountId"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":25,"column":89},"end":{"line":25,"column":99}}}) : helper)))
    + " value = "
    + alias4(((helper = (helper = lookupProperty(helpers,"account_id") || (depth0 != null ? lookupProperty(depth0,"account_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"account_id","hash":{},"data":data,"loc":{"start":{"line":25,"column":108},"end":{"line":25,"column":122}}}) : helper)))
    + " >\n                                        <td>\n                                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"company_name") || (depth0 != null ? lookupProperty(depth0,"company_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company_name","hash":{},"data":data,"loc":{"start":{"line":27,"column":44},"end":{"line":27,"column":60}}}) : helper)))
    + "\n                                        </td>\n                                        \n                                        <td>\n                                            <span class=\"country-flag "
    + alias4(((helper = (helper = lookupProperty(helpers,"point_of_sales_name") || (depth0 != null ? lookupProperty(depth0,"point_of_sales_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"point_of_sales_name","hash":{},"data":data,"loc":{"start":{"line":31,"column":70},"end":{"line":31,"column":93}}}) : helper)))
    + " resize-secondary-flag\">\n                                        </td>\n                                        \n                                        <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"roles") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":44},"end":{"line":37,"column":53}}})) != null ? stack1 : "")
    + "                                        </td>\n                                                    \n                                        <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"last_logon") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":44},"end":{"line":43,"column":51}}})) != null ? stack1 : "")
    + "                                        </td>\n                                                    \n                                        <td class=\"action-column\">\n                                            <button type=\"button\" class=\"btn btn-action btn-med disambig\" onclick=\"userModalMerge(this, "
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":47,"column":136},"end":{"line":47,"column":146}}}) : helper)))
    + ")\" id=user_disambig_"
    + alias4(((helper = (helper = lookupProperty(helpers,"company_name") || (depth0 != null ? lookupProperty(depth0,"company_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company_name","hash":{},"data":data,"loc":{"start":{"line":47,"column":166},"end":{"line":47,"column":182}}}) : helper)))
    + ">\n                                                <span class=\"btn-label\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_selectAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":48,"column":72},"end":{"line":48,"column":117}}}))
    + "</span>\n                                            </button>\n                                        </td>\n                                    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":56},"end":{"line":36,"column":86}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return ", ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"last_logon") || (depth0 != null ? lookupProperty(depth0,"last_logon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"last_logon","hash":{},"data":data,"loc":{"start":{"line":42,"column":48},"end":{"line":42,"column":62}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  \n            <center>\n                    <h3>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userDisambigHeading1",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":76}}}))
    + "</h3>\n                    <h4>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userDisambigHeading2",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":76}}}))
    + "</h4>\n\n                    <input type = \"hidden\" id = \"encryptedPassword\" value = "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"encryptedPassword") : depth0), depth0))
    + " >\n\n                    <input type = \"hidden\" id = \"userMergeUserName\" value = "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + " >\n\n                    <table class = \"data-table table-striped table-highlight\">\n                        <thead>\n                            <tr>\n                                <th style=\"width: 300px;\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_disambigLogin_companyName",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":13,"column":58},"end":{"line":13,"column":115}}}))
    + "</th>\n                                <th style=\"width: 50px;\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_disambigLogin_pos",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":14,"column":57},"end":{"line":14,"column":106}}}))
    + "</th>\n                                <th>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_disambigLogin_user_role",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":15,"column":36},"end":{"line":15,"column":91}}}))
    + "</th>\n                                <th>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_disambigLogin_user_lastSignIn",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":97}}}))
    + "</th>\n                                <th></th>\n                            </tr>\n                        </thead>\n\n                            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"disambiguationData") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":52,"column":41}}})) != null ? stack1 : "")
    + "                            </tbody>\n                    </table>\n            </center>\n";
},"useData":true}));