Handlebars.registerPartial('partials/auth-lang-switcher', Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"auth-header-menu\" class=\"last auth-nav-item\" style=\"padding: 0px;\">\n    <nav id=\"primary-nav-desktop\" class=\"primary-nav\">\n   <ul >\n      <li>\n         <a data-control=\"tooltip\" data-pos=\"bl\" data-trigger=\"click\" data-content-id=\"language-menu\" data-width=\"120\" data-tooltip-id=\"language-selector-menu\" data-pos-offset=\"0\" data-arrow=\"false\">\n             <div class=\"language-menu-tooltip\">\n                 <div id=\"language-switcher-title\"></div>\n                 <uitk-icon name=\"caret180\" class=\"language-menu-icon\"></uitk-icon>\n             </div>\n         </a>\n         <div id=\"language-menu\" class=\"visuallyhidden\" >\n            <ul class=\"language-list-tooltip\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authLanguages") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":15},"end":{"line":29,"column":24}}})) != null ? stack1 : "")
    + "            </ul>\n         </div>\n      </li>\n   </ul>\n</nav>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"loadRefreshPage") : depths[1]),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":27,"column":26}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                       <li >\n                           <a href= \"javascript:changeLanguageAndRefreshPage('"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":17,"column":78},"end":{"line":17,"column":86}}}) : helper)))
    + "');\"  class=\"language-list\" id=\"language-select-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":17,"column":134},"end":{"line":17,"column":142}}}) : helper)))
    + "\">\n                               "
    + alias4(container.lambda(depth0, depth0))
    + "\n                           </a>\n                       </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                       <li >\n                           <a href= \"javascript:changeLanguageAndRefreshEgenciaPage('"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":23,"column":85},"end":{"line":23,"column":93}}}) : helper)))
    + "');\" class=\"language-list\" id=\"language-select-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":23,"column":140},"end":{"line":23,"column":148}}}) : helper)))
    + "\">\n                               "
    + alias4(container.lambda(depth0, depth0))
    + "\n                           </a>\n                       </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authLanguages") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":36,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));