Handlebars.registerPartial('partials/terms-and-policy', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='loginType' id='loginType' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"loginType") : stack1), depth0))
    + "' />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='companyId' id='companyId' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"companyId") : stack1), depth0))
    + "' />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='userName' id='userName' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1), depth0))
    + "' />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='userId' id='userId' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"userId") : stack1), depth0))
    + "' />\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='tpid' id='tpid' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"tpid") : stack1), depth0))
    + "' />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='tempAccessCode' id='tempAccessCode' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"tempAccessCode") : stack1), depth0))
    + "' />\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='returnUrl' id='returnUrl' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"returnUrl") : stack1), depth0))
    + "' />\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='locale' id='locale' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1), depth0))
    + "' />\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                                    <input type='hidden' name='locale' id='locale' value='en_US' />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='email' id='email' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "' />\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='posid' id='posid' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"posid") : stack1), depth0))
    + "' />\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='gpid' id='gpid' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"gpid") : stack1), depth0))
    + "' />\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='uid' id='uid' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"uid") : stack1), depth0))
    + "' />\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='cid' id='cid' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"cid") : stack1), depth0))
    + "' />\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='authorization' id='authorization' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"authorization") : stack1), depth0))
    + "' />\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='sessionIds' id='sessionIds' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"sessionIds") : stack1), depth0))
    + "' />\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='nameIdData' id='nameIdData' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"nameIdData") : stack1), depth0))
    + "' />\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='IDPPlatform' id='IDPPlatform' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"IDPPlatform") : stack1), depth0))
    + "' />\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='sourceIDP' id='sourceIDP' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"sourceIDP") : stack1), depth0))
    + "' />\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='redirectionURL' id='redirectionURL' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"redirectionURL") : stack1), depth0))
    + "' />\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='resetPassword' id='resetPassword' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"resetPassword") : stack1), depth0))
    + "' />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"site-content\">\n            <div class=\"row\">\n                <div class=\"col col-8 col-push-2\">\n                    <div class=\"box\">\n                        <div class=\"box-padded\">\n                                <p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"terms_and_policy_acceptance_sentence",((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"termsOfUseURL") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"privacyURL") : stack1),{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":175}}}))
    + "</p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"loginType") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":10,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"companyId") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":14,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":18,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"userId") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":32},"end":{"line":22,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"tpid") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":26,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"tempAccessCode") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":30,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"returnUrl") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":32},"end":{"line":34,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":36,"column":32},"end":{"line":40,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"email") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":32},"end":{"line":44,"column":39}}})) != null ? stack1 : "")
    + "                                \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"posid") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":48,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"gpid") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":32},"end":{"line":52,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"uid") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":32},"end":{"line":56,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"cid") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":32},"end":{"line":60,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"authorization") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":32},"end":{"line":64,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"sessionIds") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":32},"end":{"line":68,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"nameIdData") : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":72,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"IDPPlatform") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":32},"end":{"line":76,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"sourceIDP") : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":32},"end":{"line":80,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"redirectionURL") : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":32},"end":{"line":84,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"resetPassword") : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":32},"end":{"line":88,"column":39}}})) != null ? stack1 : "")
    + "\n                                <button type=\"submit\" id=\"termsPolicyContinue\" class=\"btn btn-action btn-med\" target=\"_parent\" onclick=''>\n                                    <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"terms_and_policy_submit_button_label",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"term-policy-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":91,"column":60},"end":{"line":91,"column":141}}}))
    + "</span>\n                                </button>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n";
},"useData":true}));