Handlebars.registerPartial('partials/sso-search-result', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    \n                        <tr>\n                            \n                            <td>\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"company_name") || (depth0 != null ? lookupProperty(depth0,"company_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company_name","hash":{},"data":data,"loc":{"start":{"line":20,"column":32},"end":{"line":20,"column":48}}}) : helper)))
    + "\n                            </td>\n                            \n                            <td>\n                                <span class=\"country-flag "
    + alias4(((helper = (helper = lookupProperty(helpers,"point_of_sales_name") || (depth0 != null ? lookupProperty(depth0,"point_of_sales_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"point_of_sales_name","hash":{},"data":data,"loc":{"start":{"line":24,"column":58},"end":{"line":24,"column":81}}}) : helper)))
    + " resize-secondary-flag\">\n                            </td>\n                                        \n                            <td class=\"action-column\">\n                                <button type=\"button\" class=\"btn btn-action btn-med disambig\" onclick=\"javascript:redirectForSSOMerge('"
    + alias4(((helper = (helper = lookupProperty(helpers,"vanity_url") || (depth0 != null ? lookupProperty(depth0,"vanity_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vanity_url","hash":{},"data":data,"loc":{"start":{"line":28,"column":135},"end":{"line":28,"column":149}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"authn_url") || (depth0 != null ? lookupProperty(depth0,"authn_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"authn_url","hash":{},"data":data,"loc":{"start":{"line":28,"column":153},"end":{"line":28,"column":166}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"sso_id") || (depth0 != null ? lookupProperty(depth0,"sso_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sso_id","hash":{},"data":data,"loc":{"start":{"line":28,"column":170},"end":{"line":28,"column":180}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"account_id") || (depth0 != null ? lookupProperty(depth0,"account_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"account_id","hash":{},"data":data,"loc":{"start":{"line":28,"column":184},"end":{"line":28,"column":198}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":28,"column":202},"end":{"line":28,"column":213}}}) : helper)))
    + "')\" id=sso_disambig_"
    + alias4(((helper = (helper = lookupProperty(helpers,"company_name") || (depth0 != null ? lookupProperty(depth0,"company_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company_name","hash":{},"data":data,"loc":{"start":{"line":28,"column":233},"end":{"line":28,"column":249}}}) : helper)))
    + ">\n                                    <span class=\"btn-label\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_selectAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":29,"column":60},"end":{"line":29,"column":105}}}))
    + "</span>\n                                </button>\n                            </td>\n                        </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <center>\n        <h3>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userDisambigHeading1",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":64}}}))
    + "</h3>\n        <h4>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userDisambigHeading2",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":64}}}))
    + "</h4>\n        \n        <table class = \"data-table table-striped table-highlight\" id=\"sso-disambig-table\">\n            <thead>\n                <tr>\n                    <th style=\"width: 300px;\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_disambigLogin_companyName",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":103}}}))
    + "</th>\n                    <th style=\"width: 50px;\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_disambigLogin_pos",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":94}}}))
    + "</th>\n                    <th></th>\n                </tr>\n            </thead>\n\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"disambiguationData") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":33,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\n        </table>\n</center>\n";
},"useData":true}));