Handlebars.registerPartial('partials/ers-trust-widget-v2', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <script type=\"text/javascript\" src= "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ersTrustProxyUrl") || (depth0 != null ? lookupProperty(depth0,"ersTrustProxyUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ersTrustProxyUrl","hash":{},"data":data,"loc":{"start":{"line":32,"column":40},"end":{"line":32,"column":60}}}) : helper)))
    + "></script>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <script async src=\"https://trust.expedia.com/trustService/getTrustWidget?pid=3498a34f-fa15-4407-9cf5-f534f37a4a86\"></script>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<script type=\"text/javascript\">\n    function getSessionIdViaJs() {\n        return \"MY_SESSION_ID_FROM_JS\";\n    }\n</script>\n<script type=\"text/javascript\">\n        var trustApi = trustApi || {};\n        trustApi.clientConfiguration = {\n            \"webSessionProviderMethod\": \"JS\",\n            \"webSessionProviderMethodParam\": \"return getSessionIdViaJs()\",\n            \"placement\": \"LOGIN\",\n            \"placementPage\": \"1\",\n            \"reportingSegment\": window.location.host\n        }\n        if(!trustApi.clientConfiguration[\"reportingSegment\"]){\n        	trustApi.clientConfiguration[\"reportingSegment\"] = \"www.egencia.com\";\n        }\n        function renderTrustWidgetClientPayload() {\n            if(trustApi && typeof(trustApi.getTrustPayload) == \"function\"){\n                var twPayload = trustApi.getTrustPayload();\n                var twPayloadElement = document.getElementById('twPayload');\n                if(twPayloadElement){\n                    twPayloadElement.value=twPayload;\n                }\n            }\n            else{\n            	uitk.logger._log('error', 'Ers trust widget script not available, configuredERSTrustWidgetProxyUrl: ' + '"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ersTrustProxyUrl") || (depth0 != null ? lookupProperty(depth0,"ersTrustProxyUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ersTrustProxyUrl","hash":{},"data":data,"loc":{"start":{"line":27,"column":118},"end":{"line":27,"column":138}}}) : helper)))
    + "');\n            }\n        }\n</script>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ersTrustProxyUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true}));