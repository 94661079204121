Handlebars.registerPartial('partials/sso-details', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"row_start") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "                    <div class=\"col col-5 nested-grid\">\n                    <div class=\"box\">\n                        <div class=\"box-padded row row-ordered-cols\">\n                        \n                            <div class=\"col col-4 inner-col col-medium-screen-12 col-order-1\">\n                                <div id=\"country-content\">\n                                <uitk-country-flag isocode=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pos_code") || (depth0 != null ? lookupProperty(depth0,"pos_code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pos_code","hash":{},"data":data,"loc":{"start":{"line":32,"column":60},"end":{"line":32,"column":72}}}) : helper)))
    + "\"></uitk-country-flag>\n                                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"country_name") || (depth0 != null ? lookupProperty(depth0,"country_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"country_name","hash":{},"data":data,"loc":{"start":{"line":33,"column":38},"end":{"line":33,"column":54}}}) : helper)))
    + "</span>\n                                </div>\n                            </div>\n                            <div class=\"col col-4 inner-col col-medium-screen-12 col-order-1\">\n                                <span class=\"label bold\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"company_name") || (depth0 != null ? lookupProperty(depth0,"company_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company_name","hash":{},"data":data,"loc":{"start":{"line":37,"column":57},"end":{"line":37,"column":73}}}) : helper)))
    + "</span>\n                             </div>\n                            <div class=\"col col-4 col-medium-screen-12 inner-col\">\n                             <button class=\"btn btn-action btn-med btn-no-margin\" onclick=\"window.location.href = '"
    + alias4(((helper = (helper = lookupProperty(helpers,"vanity_url") || (depth0 != null ? lookupProperty(depth0,"vanity_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vanity_url","hash":{},"data":data,"loc":{"start":{"line":40,"column":115},"end":{"line":40,"column":129}}}) : helper)))
    + "'\"> <span class=\"btn-label\">Launch</span>\n                            </button>\n                             </div>\n                            </div> \n                        </div>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"row_start") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":16},"end":{"line":49,"column":27}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"row row-ordered-cols row-ordered-cols-horizontal\">\n                    <div class=\"col col-1\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"col col-1\"></div>\n                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"alert-text-error\" id=\"error-message\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"sso_search_empty_results_label",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":51,"column":59},"end":{"line":51,"column":117}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"site-content\">\n    <div class=\"auth-header-wrapper-fullwidth align-center\">\n            <h1 class=\"font-light\">\n                    "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_results_main_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":4,"column":20},"end":{"line":4,"column":78}}}))
    + "\n            </h1>\n            <h4>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_results_secondary_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":79}}}))
    + "</h4>\n    </div>\n    \n\n            \n    <div class=\"sso-details center\">\n            <div class=\"row row-ordered-cols row-ordered-cols-horizontal\">\n                    <div class=\"col col-1\"></div>\n                    <div class=\"col col-5 nested-grid col-order-2 align-left\">\n                    <a id=\"sso-details-back\" class=\"font-bold\">\n                    <i class=\"icon icon-arrow270\"></i> "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"sso_search_results_back_label",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":16,"column":55},"end":{"line":16,"column":112}}}))
    + "\n                    </a>\n                    </div>\n                    <div class=\"col col-5 align-right\"></div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sso-details") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":52,"column":17}}})) != null ? stack1 : "")
    + "     </div>\n\n\n\n</div>\n\n";
},"useData":true}));