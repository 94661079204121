Handlebars.registerPartial('partials/linked-account', Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PRIMARY") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":23,"column":14}}})) != null ? stack1 : "")
    + "                \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LINKED") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":7},"end":{"line":85,"column":14}}})) != null ? stack1 : "")
    + "    			\n    			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INACTIVE") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":7},"end":{"line":133,"column":23}}})) != null ? stack1 : "")
    + "\n                <input type = \"hidden\" id = linkedAccountUser_id"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":135,"column":64},"end":{"line":135,"column":74}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + " >\n                <input type = \"hidden\" id = linkedAccountAccount_id"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":136,"column":67},"end":{"line":136,"column":77}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"account_id") : depth0), depth0))
    + " >\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    				<tr class=\"primary-row\">\n                    \n                        <td class=\"action-column primary-account-data\">\n                            <span class=\"country-flag "
    + alias4(((helper = (helper = lookupProperty(helpers,"point_of_sales_name") || (depth0 != null ? lookupProperty(depth0,"point_of_sales_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"point_of_sales_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":54},"end":{"line":9,"column":77}}}) : helper)))
    + " resize-primary-flag\"></span> \n                        </td>\n    				\n    					<td class=\"primaryUserDetails primary-account-data\">\n    						<input type = \"hidden\" id = linkedAccountLogin"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":13,"column":56},"end":{"line":13,"column":66}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + " >\n    						<h3 class=\"primary-name-label\">"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"first_name") : depth0), depth0))
    + " "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"last_name") : depth0), depth0))
    + " </h3>\n    						<p><span class=\"grayscale5\">"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + "</span>&nbsp;<span id=\"PrimaryAccount\" value="
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + ">("
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_primary",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":15,"column":113},"end":{"line":15,"column":152}}}))
    + ")</span></p>\n    					</td>		\n    									\n    					<td class=\"company-name-column primary-account-data\">\n    						<p class=\"company-name\">"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"company_name") : depth0), depth0))
    + "</p>\n    					</td>\n    					\n    				</tr>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    			    <tr class=\"secondary-row\">\n                		<td class=\"action-column\">                        \n                                                                       \n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"is_read_only") : depths[1]),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":72,"column":40}}})) != null ? stack1 : "")
    + "                        </td>\n                        \n                        <td class=\"secondaryUserDetails\">\n                            <input type = \"hidden\" id = linkedAccountLogin"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":76,"column":74},"end":{"line":76,"column":84}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + " >\n                            <p class=\"linkedAcocountPII\"><span class=\"country-flag "
    + alias4(((helper = (helper = lookupProperty(helpers,"point_of_sales_name") || (depth0 != null ? lookupProperty(depth0,"point_of_sales_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"point_of_sales_name","hash":{},"data":data,"loc":{"start":{"line":77,"column":83},"end":{"line":77,"column":106}}}) : helper)))
    + " resize-secondary-flag\"></span>  "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"first_name") : depth0), depth0))
    + " "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"last_name") : depth0), depth0))
    + "&nbsp;\n                            <span class=\"grayscale5\">("
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + ")</span></p>\n                        </td>       \n                                        \n                        <td class=\"company-name-column\">\n                            <p class=\"company-name\">"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"company_name") : depth0), depth0))
    + "</p>\n                        </td>\n                    </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <p class=\"toolTip-styling\">\n                                    <span data-control=\"tooltip\" data-trigger=\"click\" data-content-id=tooltip2"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":31,"column":110},"end":{"line":31,"column":120}}}) : helper)))
    + "  data-pos=\"br\" \n                                            class = \"linkedAccountAction\" id=action_"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":32,"column":84},"end":{"line":32,"column":95}}}) : helper)))
    + " data-js-theme=\"profile\" >\n                                        <i class=\"icon icon-settingsalt linked-account-setting-icon\"></i>&nbsp;<i class=\"icon icon-caret180\"></i>\n                                    </span>\n                                </p>\n            \n                                <div id=tooltip2"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":37,"column":48},"end":{"line":37,"column":58}}}) : helper)))
    + " class=\"visuallyhidden\">\n                                    <ul style = \"list-style: none; margin: 0; padding: 0;\">\n                                        <li class=\"tool-tip-inner-mya\">\n                                            <div class=\"row no-margin\">\n                                                <button onClick = \"makePrimaryFunction(this, "
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":41,"column":93},"end":{"line":41,"column":103}}}) : helper)))
    + ")\" class=\"actionsOptions\" id=\"makeAccountPrimary_"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":41,"column":152},"end":{"line":41,"column":163}}}) : helper)))
    + "\">\n                                                    <div align=\"left\" class=\"makePrimaryOptionDiv col-7\">\n                                                        <h4 class=\"tool-tip-option\">\n                                                            "
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_makePrimary",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":44,"column":60},"end":{"line":44,"column":103}}}))
    + "\n                                                        </h4>\n                                                    </div>\n\n                                                    <div align=\"left\" class=\"col-6\">\n                                                        <h5 class=\"optionDescription\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_makePrimaryDiscription",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":49,"column":86},"end":{"line":49,"column":140}}}))
    + "</h5>\n                                                    </div>\n                                                </button>\n                                            </div>\n                                         </li>\n            \n                                        <li class=\"tool-tip-inner-mya\">\n                                            <div class=\"row no-margin\">\n                                                <button onClick = \"unmergeFunction(this, "
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":57,"column":89},"end":{"line":57,"column":99}}}) : helper)))
    + ")\" class=\"actionsOptions\" id=\"unlinkAccount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":57,"column":143},"end":{"line":57,"column":154}}}) : helper)))
    + "\">\n                                                    <div align=\"left\" class = \"makePrimaryOptionDiv col-7\">\n                                                        <h4 class=\"tool-tip-option\">\n                                                            "
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_unlinkAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":60,"column":60},"end":{"line":60,"column":105}}}))
    + "\n                                                        </h4>\n                                                    </div>\n\n                                                    <div align=\"left\" class=\"col-6\">\n                                                        <h5 class=\"optionDescription\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_unlinkAccountDiscription",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":65,"column":86},"end":{"line":65,"column":142}}}))
    + "</h5>\n                                                    </div>\n                                                </button>\n                                            </div>\n                                        </li>\n                                    </ul>\n                                 </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"is_take_control") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":20},"end":{"line":132,"column":27}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr class=\"secondary-row inactive-row\">\n                                <td class=\"action-column\">                        \n                                        <h2 class=\"toolTip-styling\">\n                                            <span data-control=\"tooltip\" data-trigger=\"click\" data-content-id=tooltip2"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":93,"column":118},"end":{"line":93,"column":128}}}) : helper)))
    + "  data-pos=\"br\" \n                                                    class = \"linkedAccountAction\" id=action_"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":94,"column":92},"end":{"line":94,"column":103}}}) : helper)))
    + " >\n                                                <i class=\"icon icon-settingsalt\"></i>&nbsp;<i class=\"icon icon-caret180\"></i>\n                                            </span>\n                                        </h2>\n                    \n                                        <div id=tooltip2"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":99,"column":56},"end":{"line":99,"column":66}}}) : helper)))
    + " class=\"visuallyhidden\">\n                                            <ul style = \"list-style: none; margin: 0; padding: 0;\">\n                                                <li class=\"tool-tip-inner-mya\">\n                                                    <button onClick = \"unmergeFunction(this, "
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":102,"column":93},"end":{"line":102,"column":103}}}) : helper)))
    + ")\" class=\"actionsOptions\" id=\"unlinkAccount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":102,"column":147},"end":{"line":102,"column":158}}}) : helper)))
    + "\">\n                                                        <div align=\"left\" class = \"unlinkOptionDiv\">\n                                                            <h4 class=\"tool-tip-option\">\n                                                                "
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_unlinkAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":105,"column":64},"end":{"line":105,"column":109}}}))
    + "\n                                                            </h4>\n                                                        </div>\n                \n                                                        <div align=\"left\" >\n                                                            <h5 class=\"optionDescription grayscale5\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_unlinkAccountDiscription",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":110,"column":101},"end":{"line":110,"column":157}}}))
    + "</h5>\n                                                        </div>  \n                                                    </button>\n                                                </li>\n                                          </ul>\n                                     </div>\n                            </td>\n                            \n                            <td class=\"secondaryUserDetails\">\n                                <span data-control=\"tooltip\" data-trigger=\"hover\" data-content=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_inactiveAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":119,"column":96},"end":{"line":119,"column":143}}}))
    + "\">\n                                    <input type = \"hidden\" id = linkedAccountLogin"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":120,"column":82},"end":{"line":120,"column":92}}}) : helper)))
    + " value = "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + " >\n                                    <h3 class=\"linkedAcocountPII\"><span class=\"country-flag "
    + alias4(((helper = (helper = lookupProperty(helpers,"point_of_sales_name") || (depth0 != null ? lookupProperty(depth0,"point_of_sales_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"point_of_sales_name","hash":{},"data":data,"loc":{"start":{"line":121,"column":92},"end":{"line":121,"column":115}}}) : helper)))
    + " resize-secondary-flag\"></span>  "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"first_name") : depth0), depth0))
    + " "
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"last_name") : depth0), depth0))
    + "&nbsp;\n                                    <span class=\"grayscale5\">("
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + ")</span></h3>\n                                </span>\n                            </td>       \n                                            \n                            <td class=\"company-name-column\">\n                                <span data-control=\"tooltip\" data-trigger=\"hover\" data-content=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_inactiveAccount",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":127,"column":96},"end":{"line":127,"column":143}}}))
    + "\">\n                                    <h3 class=\"company-name\">"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"company_name") : depth0), depth0))
    + "</h3>\n                                </span>\n                            </td>\n                        </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class = \"data-table table-highlight\">\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"accountProfiles") : depth0)) != null ? lookupProperty(stack1,"linked_accounts") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":3},"end":{"line":137,"column":12}}})) != null ? stack1 : "")
    + "		</tbody>\n	</table>\n";
},"useData":true,"useDepths":true}));