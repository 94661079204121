Handlebars.registerPartial('auth-header-scripts', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"company_id") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":9,"column":7},"end":{"line":13,"column":14}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         EG = initializeEGForOmniture(EG, "
    + alias4(((helper = (helper = lookupProperty(helpers,"tuid") || (depth0 != null ? lookupProperty(depth0,"tuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tuid","hash":{},"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":50}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"company_id") || (depth0 != null ? lookupProperty(depth0,"company_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company_id","hash":{},"data":data,"loc":{"start":{"line":10,"column":52},"end":{"line":10,"column":66}}}) : helper)))
    + ");\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         EG = initializeEGForOmniture(EG, "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tuid") || (depth0 != null ? lookupProperty(depth0,"tuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tuid","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":50}}}) : helper)))
    + ");\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "       EG = initializeEGForOmniture(EG);\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-js"),depth0,{"name":"partials/uitk/core-js","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"outputType":"script","bundleName":"uitk-analytics"},"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":102}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"auth-extended"},"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":4,"column":80}}})) != null ? stack1 : "")
    + "\n\n<script language=\"JavaScript\" type=\"text/JavaScript\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tuid") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "</script>\n";
},"usePartial":true,"useData":true}));