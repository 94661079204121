Handlebars.registerPartial('auth-header-styles', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-css"),depth0,{"name":"partials/uitk/core-css","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"stylesheet":"auth-webapp-core"},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":79}}})) != null ? stack1 : "")
    + "\n\n";
},"usePartial":true,"useData":true}));