Handlebars.registerPartial('partials/esi-popup-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<center>\n    <h2>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"esi_model_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":52}}}))
    + "</h2>\n    <p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"esi_model_sentence",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":3,"column":7},"end":{"line":3,"column":53}}}))
    + "</p>\n    <footer class=\"modal-footer cf\">\n        <div class=\"modal-footer-btns\">\n            <button id=\"esi-modal-cance\" class=\"btn btn-utility btn-med\" onclick=\"javascript:cancelRedirectToManageAccount(this, '"
    + alias3(((helper = (helper = lookupProperty(helpers,"redirectUrl") || (depth0 != null ? lookupProperty(depth0,"redirectUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectUrl","hash":{},"data":data,"loc":{"start":{"line":6,"column":130},"end":{"line":6,"column":145}}}) : helper)))
    + "');\">\n                <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"esi_model_cancel",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":84}}}))
    + "</span>\n            </button>\n            <button id=\"esi-modal-confirm\" class=\"btn btn-action btn-med\" onclick=\"javascript:redirectToManageAccount(this, '"
    + alias3(((helper = (helper = lookupProperty(helpers,"manageAccountUrl") || (depth0 != null ? lookupProperty(depth0,"manageAccountUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"manageAccountUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":125},"end":{"line":9,"column":145}}}) : helper)))
    + "');\">\n                <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"esi_model_confirm",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":85}}}))
    + " </span>\n            </button>\n        </div>\n    </footer>\n</center>";
},"useData":true}));