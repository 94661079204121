Handlebars.registerPartial('a3-app/auth-admin-app', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <title>\n        "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"adminApp_pageTitle",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":40}}}))
    + " | Egencia\n    </title>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div >\n        <h3 align= \"center\">Auth Admin App</h3>\n    </div>\n    \n    \n    <div class=\"admin-content\" style=\"margin-left: 10px;\">\n     \n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/tabs"),depth0,{"name":"partials/uitk/tabs","hash":{"state":"false","theme":"box"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    	\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/tab-panes"),depth0,{"name":"partials/uitk/tab-panes","fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	\n	</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/tab"),depth0,{"name":"partials/uitk/tab","hash":{"id":"tab-a","label":"PERMISSION MANAGEMENT"},"data":data,"indent":"        \t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/tab"),depth0,{"name":"partials/uitk/tab","hash":{"id":"tab-b","label":"SERVICE REGISTRATION"},"data":data,"indent":"        \t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    	\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/tab-pane"),depth0,{"name":"partials/uitk/tab-pane","hash":{"id":"tab-a"},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/tab-pane"),depth0,{"name":"partials/uitk/tab-pane","hash":{"id":"tab-b"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    	\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"a3-app/manage-permission"),depth0,{"name":"a3-app/manage-permission","data":data,"indent":"        \t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"a3-app/register-service"),depth0,{"name":"a3-app/register-service","data":data,"indent":"        \t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"a3app"},"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":33,"column":77}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"partial")||(depth0 && lookupProperty(depth0,"partial"))||alias2).call(alias1,"title",{"name":"partial","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"partial")||(depth0 && lookupProperty(depth0,"partial"))||alias2).call(alias1,"content",{"name":"partial","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":31,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"partial")||(depth0 && lookupProperty(depth0,"partial"))||alias2).call(alias1,"more-scripts",{"name":"partial","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":0},"end":{"line":34,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"layouts/default"),depth0,{"name":"layouts/default","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));