Handlebars.registerPartial('mfa-container-page', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n<head>\n    <meta content=IE=edge http-equiv=X-UA-Compatible>\n\n    <title>\n        "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"forgotPassword.title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":56}}}))
    + " | Egencia\n    </title>\n\n    <meta name=\"description\" content=\"\">\n    <meta name=\"author\" content=\"\">\n    <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-meta-tag"),depth0,{"name":"auth-header-meta-tag","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-styles"),depth0,{"name":"auth-header-styles","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</head>\n\n\n<script type=\"text/javascript\" src=\"https://assets.egencia.com/mfa-component/"
    + alias3(((helper = (helper = lookupProperty(helpers,"mfaVersion") || (depth0 != null ? lookupProperty(depth0,"mfaVersion") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mfaVersion","hash":{},"data":data,"loc":{"start":{"line":23,"column":77},"end":{"line":23,"column":91}}}) : helper)))
    + "/mfa-component.js\"></script>\n\n<body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"site-content\">\n        <div class=\"spaceDiv\"></div>\n            <div class=\"auth-content-wrapper\">\n                <div class=\"box\">\n                    <div class=\"box-padded\">\n                        <div id=\"mfa-container\">\n                            <div id=\"root\">\n\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n    </div>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<script>\n    $(document).ready(function () {\n        $(\"#language-menu\").addClass(\"displayNone\");\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"header-right"),(depth0 != null ? lookupProperty(depth0,"nonLoggedInHeaderEnabled") : depth0),{"name":"header-right","hash":{"loadRefreshPage":false},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        window.uitk.publish('interstitial.changed', {\n            contentId: \"mfa-container\"\n        });\n\n        var requestData ={\n            \"userId\": "
    + alias3(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":55,"column":22},"end":{"line":55,"column":32}}}) : helper)))
    + ",\n            \"companyId\": "
    + alias3(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":56,"column":25},"end":{"line":56,"column":38}}}) : helper)))
    + ",\n            \"posId\": "
    + alias3(((helper = (helper = lookupProperty(helpers,"posId") || (depth0 != null ? lookupProperty(depth0,"posId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"posId","hash":{},"data":data,"loc":{"start":{"line":57,"column":21},"end":{"line":57,"column":30}}}) : helper)))
    + ",\n            \"useCaseId\": \""
    + alias3(((helper = (helper = lookupProperty(helpers,"useCaseId") || (depth0 != null ? lookupProperty(depth0,"useCaseId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"useCaseId","hash":{},"data":data,"loc":{"start":{"line":58,"column":26},"end":{"line":58,"column":39}}}) : helper)))
    + "\",\n            \"locale\" : \""
    + alias3(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":59,"column":34}}}) : helper)))
    + "\",\n            \"successCallback\":\""
    + alias3(((helper = (helper = lookupProperty(helpers,"successCallback") || (depth0 != null ? lookupProperty(depth0,"successCallback") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"successCallback","hash":{},"data":data,"loc":{"start":{"line":60,"column":31},"end":{"line":60,"column":50}}}) : helper)))
    + "\",\n            \"refId\":\""
    + alias3(((helper = (helper = lookupProperty(helpers,"refId") || (depth0 != null ? lookupProperty(depth0,"refId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"refId","hash":{},"data":data,"loc":{"start":{"line":61,"column":21},"end":{"line":61,"column":30}}}) : helper)))
    + "\",\n            \"callbackUrl\":\""
    + alias3(((helper = (helper = lookupProperty(helpers,"cbl") || (depth0 != null ? lookupProperty(depth0,"cbl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"cbl","hash":{},"data":data,"loc":{"start":{"line":62,"column":27},"end":{"line":62,"column":34}}}) : helper)))
    + "\"\n        };\n        var mfaHolder = mfaComponent;\n        if(typeof(mfaHolder.display)!=='function'){\n            mfaHolder = mfaHolder.mfaComponent;\n        }\n        mfaHolder.display(document.getElementById(\"root\"),{data:requestData});\n\n        mfaHolder.pubsub.subscribe(\"mfa_successful\",function(eventName,data){\n            var request = {\n                \"user_id\":"
    + alias3(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":72,"column":26},"end":{"line":72,"column":36}}}) : helper)))
    + ",\n                \"company_id\":"
    + alias3(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":73,"column":29},"end":{"line":73,"column":42}}}) : helper)))
    + ",\n                \"pos_id\":"
    + alias3(((helper = (helper = lookupProperty(helpers,"posId") || (depth0 != null ? lookupProperty(depth0,"posId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"posId","hash":{},"data":data,"loc":{"start":{"line":74,"column":25},"end":{"line":74,"column":34}}}) : helper)))
    + ",\n                \"use_case_id\":\""
    + alias3(((helper = (helper = lookupProperty(helpers,"useCaseId") || (depth0 != null ? lookupProperty(depth0,"useCaseId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"useCaseId","hash":{},"data":data,"loc":{"start":{"line":75,"column":31},"end":{"line":75,"column":44}}}) : helper)))
    + "\",\n                \"mfa_type\":\"DQ\",\n                \"callback_url\":\""
    + alias3(((helper = (helper = lookupProperty(helpers,"cbl") || (depth0 != null ? lookupProperty(depth0,"cbl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"cbl","hash":{},"data":data,"loc":{"start":{"line":77,"column":32},"end":{"line":77,"column":39}}}) : helper)))
    + "\"\n            };\n            uitk.publish(\"mfa_successful\",data);\n            $.ajax({\n                url: '/auth/v1/mfa/reset-password-link',\n                type: 'POST',\n                headers:{\"Mfa-Token\":data.mfa_token},\n                contentType: 'application/json',\n                data: JSON.stringify(request),\n                success: function success(data) {\n                    if(data.reset_pswd_link){\n                        window.location.href = data.reset_pswd_link;\n                    }\n                    else{\n                        uitk.logger._log(\"error\",\"Mfa Reset pswd link received null/empty\");\n                    }\n                },\n                error: function (req,status,err) {\n                    uitk.logger._log(\"error\",\"status \"+ status + \"error \"+err);\n                }\n            });\n\n        });\n        mfaHolder.pubsub.subscribe(\"mfa.init_refresh_params\", function (eventName, refreshParams) {\n            if (window.history && window.history.replaceState) {\n                if (refreshParams) {\n                    window.history.replaceState(\"\", \"\", \"?refreshParams=\" + refreshParams);\n                }\n            }\n        });\n    });\n</script>\n</body>\n\n</html>\n";
},"usePartial":true,"useData":true}));