Handlebars.registerPartial('partials/turnstile-content', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"turnstile-container\">\n        <div id=\"cf-turnstile\" class=\"cf-turnstile\" data-sitekey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"turnstile_site_key") || (depth0 != null ? lookupProperty(depth0,"turnstile_site_key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"turnstile_site_key","hash":{},"data":data,"loc":{"start":{"line":6,"column":66},"end":{"line":6,"column":88}}}) : helper)))
    + "\"\n             data-callback=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"success_call_back") || (depth0 != null ? lookupProperty(depth0,"success_call_back") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"success_call_back","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":49}}}) : helper)))
    + "\"\n             data-theme=\"light\"\n             data-error-callback=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"error_call_back") || (depth0 != null ? lookupProperty(depth0,"error_call_back") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"error_call_back","hash":{},"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":53}}}) : helper)))
    + "\"\n             data-appearance=\"always\"\n             data-unsupported-callback=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"unsupported_call_back") || (depth0 != null ? lookupProperty(depth0,"unsupported_call_back") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"unsupported_call_back","hash":{},"data":data,"loc":{"start":{"line":11,"column":40},"end":{"line":11,"column":65}}}) : helper)))
    + "\"\n        >\n        </div>\n        <input type='hidden' name='turnstile-enabled' id='turnstile-enabled' value='true' />\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"captchaFields") : depth0)) != null ? lookupProperty(stack1,"captchaEnabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":16,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true}));