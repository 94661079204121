Handlebars.registerPartial('header-right', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authLanguages") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":5},"end":{"line":9,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        EG.Auth = EG.Auth || {};\n        EG.Auth.currentLocale = \""
    + alias4(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":33},"end":{"line":4,"column":43}}}) : helper)))
    + "\";\n        EG.Auth.availableLanguages = "
    + alias4((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"authLanguages") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":59}}}))
    + ";\n        var langSwitcher = Handlebars.templates[\"partials/auth-lang-switcher\"]({'authLanguages':EG.Auth.availableLanguages,'loadRefreshPage':"
    + alias4(((helper = (helper = lookupProperty(helpers,"loadRefreshPage") || (depth0 != null ? lookupProperty(depth0,"loadRefreshPage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"loadRefreshPage","hash":{},"data":data,"loc":{"start":{"line":6,"column":141},"end":{"line":6,"column":160}}}) : helper)))
    + " });\n        $(\"#site-utilities\").append(langSwitcher);\n        selectCurrentLocale();\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authLanguageSelectorHeaderEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "")
    + "{\nvar siteLogoLink = $(\"#site-logo\").find(\"a#primary-nav-home\");\nsiteLogoLink = siteLogoLink && siteLogoLink[0];\n    if (siteLogoLink) {\n        siteLogoLink.href = \""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"egenciaSiteLogoLink") || (depth0 != null ? lookupProperty(depth0,"egenciaSiteLogoLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"egenciaSiteLogoLink","hash":{},"data":data,"loc":{"start":{"line":15,"column":29},"end":{"line":15,"column":54}}}) : helper))) != null ? stack1 : "")
    + "\";\n    }\n}\n\n";
},"useData":true}));