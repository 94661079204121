Handlebars.registerPartial('a3-app/display-success-message', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"box color-my-class\" >\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/alert-tag"),depth0,{"name":"partials/uitk/alert-tag","hash":{"close":true,"id":"makePrimaryFailure","msgLabel":(depth0 != null ? lookupProperty(depth0,"message") : depth0),"type":"success"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true}));