Handlebars.registerPartial('v2/login-base', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            EG.Auth.oAuthParameters="
    + container.escapeExpression((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"oAuthParameters") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":33,"column":36},"end":{"line":33,"column":60}}}))
    + ";\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            EG.Auth.captchaContent = "
    + container.escapeExpression((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"captchaContent") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":36,"column":37},"end":{"line":36,"column":60}}}))
    + ";\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            EG.Auth.loginModel = "
    + container.escapeExpression((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loginModel") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":39,"column":33},"end":{"line":39,"column":52}}}))
    + ";\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                var securityTokenValidityWindow = getAcceptableSecurityTokenExpiryWindow("
    + alias4(((helper = (helper = lookupProperty(helpers,"securityTokenValidity") || (depth0 != null ? lookupProperty(depth0,"securityTokenValidity") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"securityTokenValidity","hash":{},"data":data,"loc":{"start":{"line":50,"column":89},"end":{"line":50,"column":114}}}) : helper)))
    + ","
    + alias4(((helper = (helper = lookupProperty(helpers,"securityTokenValidityMinimum") || (depth0 != null ? lookupProperty(depth0,"securityTokenValidityMinimum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"securityTokenValidityMinimum","hash":{},"data":data,"loc":{"start":{"line":50,"column":115},"end":{"line":50,"column":147}}}) : helper)))
    + ");\n                reloadBeforeExpiryOfSToken(securityTokenValidityWindow,"
    + alias4(((helper = (helper = lookupProperty(helpers,"securityTokenUserInactivityRefreshWindow") || (depth0 != null ? lookupProperty(depth0,"securityTokenUserInactivityRefreshWindow") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"securityTokenUserInactivityRefreshWindow","hash":{},"data":data,"loc":{"start":{"line":51,"column":71},"end":{"line":51,"column":115}}}) : helper)))
    + ");\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type='hidden' name='sid' id='sid' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"securityToken") : stack1), depth0))
    + "' />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n    <head>\n     	<link rel='dns-prefetch' href='//app-sjh.marketo.com' />\n     	<script type='text/javascript' src='//app-sjh.marketo.com/js/forms2/js/forms2.min.js?x63682'></script>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header"),depth0,{"name":"auth-header","data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n         <title>\n            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"egenciaLogin.title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":58}}}))
    + " | Egencia\n        </title>\n\n        <meta name=\"description\" content=\"\">\n        <meta name=\"author\" content=\"\">\n        <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n        <script src=\"https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit\" async defer></script>\n        <script src=\"https://www.google.com/recaptcha/api.js?render=explicit&hl="
    + alias3(((helper = (helper = lookupProperty(helpers,"lang") || (depth0 != null ? lookupProperty(depth0,"lang") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":80},"end":{"line":17,"column":88}}}) : helper)))
    + "\" async defer></script>\n    </head>\n\n    <body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page login-base\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/captcha-scripts"),depth0,{"name":"partials/captcha-scripts","hash":{"captchaFields":((stack1 = (depth0 != null ? lookupProperty(depth0,"captchaContent") : depth0)) != null ? lookupProperty(stack1,"loginCaptchaContent") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/gam-advertisement-header"),depth0,{"name":"partials/gam-advertisement-header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/gtm-analytics"),depth0,{"name":"partials/gtm-analytics","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <script language=\"JavaScript\" type=\"text/JavaScript\">\n        EG.Auth = EG.Auth || {};\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"oAuthParameters") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":34,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"captchaContent") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginModel") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "")
    + "        EG.Auth.loginModel = "
    + alias3((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"loginModel") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":41,"column":29},"end":{"line":41,"column":48}}}))
    + ";\n\n        EG.Auth.showGamAdvertisement = "
    + alias3(((helper = (helper = lookupProperty(helpers,"showGamAdvertisement") || (depth0 != null ? lookupProperty(depth0,"showGamAdvertisement") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"showGamAdvertisement","hash":{},"data":data,"loc":{"start":{"line":43,"column":39},"end":{"line":43,"column":63}}}) : helper)))
    + "; // This was not set for usage in JS which is needed for v2/login ads;\n\n        $(document).ready(function(){\n            uitk.analytics.setAbTests(\"auth.captcha.audio_enabled\");\n            sendPageNameToOmniture(uitk, \"page.auth.LoginV2\");\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"security_token_auto_refresh_feature_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":52,"column":19}}})) != null ? stack1 : "")
    + "        });\n    </script>\n    <script language=\"JavaScript\" type=\"text/JavaScript\">\n        document.addEventListener('DOMContentLoaded',  function() {\n            var host = window.location.hostname;\n            var hostName = host.substring(host.lastIndexOf(\".\", host.lastIndexOf(\".\") - 1) + 1);\n            if(hostName === 'localhost'){\n                document.getElementById('isLocalhost').value = true;\n            }\n        });\n    </script>\n        <input type='hidden' name='locale' id='locale' value='"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1), depth0))
    + "' />\n        <input type='hidden' name='isLocalhost' id='isLocalhost' value='false' />\n	    <div id=\"login-base-display\">\n	    </div>\n	    \n         \n		"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"login-v2"},"data":data,"loc":{"start":{"line":70,"column":2},"end":{"line":70,"column":77}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"securityToken") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":8},"end":{"line":74,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/gtm-analytics-fallback"),depth0,{"name":"partials/gtm-analytics-fallback","data":data,"indent":"       ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </body>\n</html>\n";
},"usePartial":true,"useData":true}));