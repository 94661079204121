Handlebars.registerPartial('partials/mixed-mode-account-suggestion-modal', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	                      \n	                            <div class=\"input-group\">\n	                                <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userName",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":22,"column":80}}}))
    + "</label>\n	                                <input id=\"modalUsername\" type=\"text\" value=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + "\" name=\"modalUsername\"\n	                                       disabled=\"\">\n	                            </div>\n	\n	                       \n	                            <div class=\"input-group\">\n	                                <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_password",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":29,"column":40},"end":{"line":29,"column":80}}}))
    + " <span class=\"alert-text-error\"> *</span></label>\n	                                <input id=\"modalPassword\" type=\"password\" value=\"\" name=\"modalPassword\"></input>\n	                                <input type=\"hidden\" id=\"accountSuggestionUserId\" value= "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"userId") : depth0), depth0))
    + "></input>\n	                                <input type=\"hidden\" id=\"accountSuggestionAccountId\" value= "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"accountId") : depth0), depth0))
    + "></input>\n	                                <input type=\"hidden\" id=\"accountSuggestionLogin\" value= "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + "></input>\n	                            </div>\n	                      \n	                      \n	                            <div class=\"input-group\">\n	                                <button class=\"btn btn-action btn-med\" onclick=\"mergeFunction(this)\" id=\"add_account\"\n	                                        type=\"submit\">\n	                                    <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_submit",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":40,"column":61},"end":{"line":40,"column":99}}}))
    + "</span>\n	                                </button>\n	                            </div>\n	                       \n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	                        <p id=\"modalBlockedUser\" class=\"passwordIncorrectModal\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_blockedUser",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":45,"column":81},"end":{"line":46,"column":66}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"mix-mode-suggestion-modal\" class=\"center\">\n<div class=\"mix-mode-modal-top-label\">\n	          <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_selectAccountToLogin",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":70}}}))
    + "</label>\n	         </div>\n    <table class=\"data-table\" data-control=\"table\">\n        <tbody>\n        <tr id=\"login-row\">\n        <td col-span=\"2\">\n            <div id=\"login-mode-option-div\" class=\"search-mode-option\">\n                <div class=\"input-group\" id=\"login-mode-radio-div\">\n                    <input id=\"login-search-radio\" type=\"radio\" name=\"login-mode-search\" checked=\"\">\n                    <label for=\"login-search-radio\" id=\"login-mode-search-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_standard",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":12,"column":81},"end":{"line":13,"column":58}}}))
    + "</label>\n                </div>\n            </div>\n\n            <div id=\"login-mode-div\">\n                <form class=\"form-inline\" onsubmit=\"return false\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":19,"column":21},"end":{"line":47,"column":28}}})) != null ? stack1 : "")
    + "                </form>\n                <p id=\"modalPasswordIncorrect\" class=\"passwordIncorrectModal\"></p>\n            </div>\n            </td>\n        </tr>\n\n        <tr id=\"sso-row\" class=\"tr-disabled\">\n        <td>\n            <div class=\"form-inline\">\n            <div id=\"sso-mode-option-div\" class=\"search-mode-option\">\n                <div class=\"input-group\" id=\"sso-mode-radio-div\">\n                    <input id=\"sso-search-radio\" type=\"radio\" name=\"sso-mode-search\">\n                    <label class=\"capitalized\" for=\"sso-search-radio\" id=\"sso-mode-search-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_sso",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":60,"column":97},"end":{"line":60,"column":132}}}))
    + "</label>\n                </div>\n            </div>\n            <div id=\"sso-mode-div\">\n                <button class=\"btn btn-action btn-med no-margin\" onClick=\"javascript:redirectForSSOMerge('"
    + alias3(((helper = (helper = lookupProperty(helpers,"vanity_url") || (depth0 != null ? lookupProperty(depth0,"vanity_url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"vanity_url","hash":{},"data":data,"loc":{"start":{"line":64,"column":106},"end":{"line":64,"column":120}}}) : helper)))
    + "', '"
    + alias3(((helper = (helper = lookupProperty(helpers,"authn_url") || (depth0 != null ? lookupProperty(depth0,"authn_url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"authn_url","hash":{},"data":data,"loc":{"start":{"line":64,"column":124},"end":{"line":64,"column":137}}}) : helper)))
    + "', '"
    + alias3(((helper = (helper = lookupProperty(helpers,"sso_id") || (depth0 != null ? lookupProperty(depth0,"sso_id") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"sso_id","hash":{},"data":data,"loc":{"start":{"line":64,"column":141},"end":{"line":64,"column":151}}}) : helper)))
    + "', '"
    + alias3(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":64,"column":155},"end":{"line":64,"column":168}}}) : helper)))
    + "')\" id=add_account_sso\n                        type=\"submit\">\n                    <span class=\"btn-label\">Continue</span>\n                </button>\n            </div>\n            </div>\n            </td>\n        </tr>\n        </tbody>\n    </table>\n</div>\n";
},"useData":true}));