Handlebars.registerPartial('redirect-page', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n<head>\n</head>\n<body>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "   <script>\n    sendSuccessfulLoginAnalyticsData(uitk);\n    var loginType= \""
    + alias4(((helper = (helper = lookupProperty(helpers,"loginType") || (depth0 != null ? lookupProperty(depth0,"loginType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"loginType","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":33}}}) : helper)))
    + "\";\n    var userName= \""
    + alias4(((helper = (helper = lookupProperty(helpers,"user-name") || (depth0 != null ? lookupProperty(depth0,"user-name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user-name","hash":{},"data":data,"loc":{"start":{"line":10,"column":19},"end":{"line":10,"column":32}}}) : helper)))
    + "\";\n    if(isLocalStorgeExist()){\n        var userData;\n        var ssoUsersList = getSSOUsersListFromLocalStorage();\n        if(ssoUsersList  && ssoUsersList[userName]){\n            userData = ssoUsersList[userName] || {};\n            userData['sso'] = true;\n            if(loginType === 'SITE'){\n                userData['sso']= false;\n            }\n        }\n        setSSOUserInLocalStorage(userName,userData)\n    }\n\n    window.location=decodeURIComponent(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"redirectUrl") || (depth0 != null ? lookupProperty(depth0,"redirectUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"redirectUrl","hash":{},"data":data,"loc":{"start":{"line":24,"column":40},"end":{"line":24,"column":55}}}) : helper)))
    + "\");\n</script>\n</body>\n</html>\n";
},"usePartial":true,"useData":true}));