Handlebars.registerPartial('partials/arbitrary-search-standard-login', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h5>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_standard",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":56}}}))
    + "</h5>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"arbitrary-serach-div\">\n    <form id=\"standard-search-form\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_esi_sso_enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":19}}})) != null ? stack1 : "")
    + "        \n        <div class=\"input-group\">\n            <input id=\"searchUserName\" name=\"userName\" type=\"text\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":8,"column":74},"end":{"line":8,"column":86}}}) : helper)))
    + "\" \n                                placeholder=\""
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userName",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":85}}}))
    + "\" class=\"replay-safe search-box-fields\"></input>\n        </div>                  \n        \n        <div class=\"input-group\">\n            <input id=\"searchPassword\" name=\"password\" type=\"password\" value=\"\" \n                placeholder=\""
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_password",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":14,"column":29},"end":{"line":14,"column":69}}}))
    + "\" class=\"search-box-fields\">\n        </div>      \n        \n        <input id=\"fromManualSearch\" name=\"fromManualSearch\" type=\"hidden\" value=\"\">\n        <input id=\"primaryAccountID\" name=\"primaryAccountID\" type=\"hidden\" value=\"\">\n        <input id=\"primaryUserID\" name=\"primaryUserID\" type=\"hidden\" value=\"\">\n        \n        <div class=\"search-error-div\">\n            <p id=\"arbitrarySearchErrorMessage\" class = \"alert-text-error\"></p>\n        </div>\n        \n        <div>\n            <button class=\"btn btn-action btn-med  manualSearchButton\" style=\"width: 100%;\" id=\"arbitrarySearchButton\" \n                type=\"submit\" form=\"standard-search-form\" value=\"Submit\">\n                <span class=\"btn-label\"><span id=\"login-search-loader-span\" class=\"\"></span>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_findAccounts",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":28,"column":92},"end":{"line":28,"column":136}}}))
    + "</span>\n            </button>\n        </div>\n     </form>\n</div>";
},"useData":true}));