Handlebars.registerPartial('partials/gam-advertisement-header', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <script async src=\"https://www.googletagservices.com/tag/js/gpt.js\"></script>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            initializeAndDisplayGamAd(window, \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gamAdvertisementPath") || (depth0 != null ? lookupProperty(depth0,"gamAdvertisementPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gamAdvertisementPath","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":71}}}) : helper)))
    + "\");\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGamAdvertisement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "")
    + "\n<script type=\"text/javascript\">\n    $(document).ready(function(){\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGamAdvertisement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "    });\n</script>";
},"useData":true}));