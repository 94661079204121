Handlebars.registerPartial('partials/mixed-mode-redirection-info-modal', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img src="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"imagePath") || (depth0 != null ? lookupProperty(depth0,"imagePath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imagePath","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":30}}}) : helper)))
    + " alt=\"rediretion_img\" id=\"vanity-redirection-modal-image\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <img src=\"images/redirection-image.png\" alt=\"rediretion_img\" id=\"vanity-redirection-modal-image\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imagePath") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"nested-grid\">\n        <div id=\"mixed-mode-redirection-info-modal-div\">\n            <div class=\"row\">\n                <div class=\"col col-12\"  id=\"mixed-mode-redirection-modal-heading-div\">\n                    <p id=\"mixed-mode-redirection-info-modal-sub-heading\" style=\"padding-top: 1em;\">\n                        "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"mixed_mode_redirection_modal_content",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":74}}}))
    + "\n                    </p>\n                </div>\n            </div>\n        </div>\n     </div>\n</div>\n";
},"useData":true}));