Handlebars.registerPartial('partials/reset-password', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='locale' id='locale' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1), depth0))
    + "' />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "				<input type='hidden' name='locale' id='locale' value='en_US' />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/alert-tag"),depth0,{"name":"partials/uitk/alert-tag","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1),"msgLabel":"resetPassword_passwordExpired","type":"info"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    <br />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"adminUpdatedPassword") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":22,"column":16}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/alert-tag"),depth0,{"name":"partials/uitk/alert-tag","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1),"msgLabel":"resetPassword_adminUpdatedPassword","type":"info"},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        <br />\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"firstLogin") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":22,"column":16}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/alert-tag"),depth0,{"name":"partials/uitk/alert-tag","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1),"msgLabel":"resetPassword.firstLogin","type":"info"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            <br />\n                ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"resetPassword_heading",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":42,"column":71}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"resetPassword_headingTwo",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":45,"column":6},"end":{"line":45,"column":74}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":5},"end":{"line":167,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":13},"end":{"line":177,"column":11}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"errorMessage") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":5},"end":{"line":74,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"isUnrecoverableError") : stack1),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":3},"end":{"line":166,"column":23}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  \n			  <div id=\"generic-error\" class=\"row\">\n                <div class=\"col col-12\">\n					<span class=\"label reset-password-label alert-text-error\">\n						"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"errorMessage") : stack1), depth0))
    + "\n					</span>\n				</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"passwordHistory") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":72,"column":20}}})) != null ? stack1 : "")
    + "              </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"passwordHistoryPlacementTop") : stack1),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":14},"end":{"line":71,"column":27}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    				    <div class=\"col col-12\">\n                      <p id=\"password-history-size\" class=\"secondary alert-text-error\">\n                        <span>\n                        "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"localizedPasswordHistoryMessageKey") : depth0),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1)) != null ? lookupProperty(stack1,"password_history_size") : stack1),{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":67,"column":152}}}))
    + "\n                        </span>\n                      </p>\n                  	</div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":145,"column":22}}})) != null ? stack1 : "")
    + "              <div class=\"row\">\n                <div class=\"col col-12\">\n                  <div class=\"input-group\" id=\"password-container\">\n                    <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_new",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":149,"column":27},"end":{"line":149,"column":97}}}))
    + "<span class=\"alert-text-error\">*</span></label>\n                    <input id=\"password\" name=\"password\" type=\"password\" value=\"\" autocomplete=\"off\" autofocus>\n                  </div>\n                  <div class=\"input-group\" id=\"confirm-password-container\">\n                    <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_Reenter",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":153,"column":27},"end":{"line":153,"column":101}}}))
    + "<span class=\"alert-text-error\">*</span></label>\n                    <input id=\"confirmPassword\" name=\"confirmPassword\" type=\"password\" value=\"\" autocomplete=\"off\">\n                    <span id=\"error-message\" class=\"input-message\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"reset_password_passwords_mismatch",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":155,"column":67},"end":{"line":155,"column":144}}}))
    + "</span>\n                  </div>\n                  \n                  <div class=\"input-group\">\n                    <button class=\"btn btn-action btn-med disabled\" type=\"button\" target=\"_parent\" id=\"resetPasswordButton\">\n                      <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_save",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":160,"column":46},"end":{"line":160,"column":117}}}))
    + "</span>\n                    </button>\n                  </div>\n                </div>\n              </div>\n            </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"row\">\n                <div class=\"col col-12\">\n                  <p class=\"secondary\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_policies",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":80,"column":39},"end":{"line":80,"column":114}}}))
    + "</p>\n                </div>\n              </div>\n              \n              <div class=\"row\">\n                <div class=\"col col-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1)) != null ? lookupProperty(stack1,"password_history_size") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":18},"end":{"line":97,"column":25}}})) != null ? stack1 : "")
    + "                  <p id=\"password-min-size\" class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"passwordLength") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":62},"end":{"line":98,"column":140}}})) != null ? stack1 : "")
    + "\">\n                    <span class=\"reset-check "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"passwordLength") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":45},"end":{"line":99,"column":112}}})) != null ? stack1 : "")
    + "\">\n                      <i class=\"icon icon-success\"></i>\n                      <i class=\"icon icon-close\"></i>\n                    </span>\n                    <span>\n                    "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_minimum_size",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":104,"column":20},"end":{"line":104,"column":99}}}))
    + ": "
    + alias3(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1)) != null ? lookupProperty(stack1,"min_password_length") : stack1), depth0))
    + "\n                    </span>\n                  </p>\n                  \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1)) != null ? lookupProperty(stack1,"use_special_char") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":18},"end":{"line":118,"column":25}}})) != null ? stack1 : "")
    + "                  \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1)) != null ? lookupProperty(stack1,"use_numbers") : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":18},"end":{"line":130,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1)) != null ? lookupProperty(stack1,"use_upper_case") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":18},"end":{"line":141,"column":25}}})) != null ? stack1 : "")
    + "                   \n                </div>\n              </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"passwordHistoryPlacementTop") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":20},"end":{"line":96,"column":27}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div id=\"password-history-size\" class=\"secondary pwd-history-info "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"passwordHistory") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":86},"end":{"line":88,"column":165}}})) != null ? stack1 : "")
    + "\">\n                      \n                        <i class=\"icon icon-info\"></i>\n                      \n                      <div class=\"pwd-history-info-message\">\n                        <p>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"localizedPasswordHistoryMessageKey") : depth0),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyDetails") : stack1)) != null ? lookupProperty(stack1,"password_history_size") : stack1),{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":93,"column":27},"end":{"line":93,"column":155}}}))
    + "</p>\n                        </div>\n                    </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "alert-text-error";
},"29":function(container,depth0,helpers,partials,data) {
    return "error";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p id=\"password-special-char\" class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"specialChars") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":66},"end":{"line":109,"column":142}}})) != null ? stack1 : "")
    + "\">\n                    <span class=\"reset-check "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"specialChars") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":45},"end":{"line":110,"column":110}}})) != null ? stack1 : "")
    + "\">\n                      <i class=\"icon icon-success\"></i>\n                      <i class=\"icon icon-close\"></i>\n                    </span>\n                    <span>\n                    "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"resetPassword_password_special_char_policy1",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":115,"column":20},"end":{"line":115,"column":107}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"specialCharactersList") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":115,"column":108},"end":{"line":115,"column":230}}})) != null ? stack1 : "")
    + "\n                    </span>\n                  </p>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"specialCharactersList") : stack1), depth0));
},"34":function(container,depth0,helpers,partials,data) {
    return "~!@#$%*-&{}?/:+=()[]_";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p id=\"password-use-num\" class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"numerals") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":61},"end":{"line":121,"column":133}}})) != null ? stack1 : "")
    + "\">\n                    <span class=\"reset-check "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"numerals") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":45},"end":{"line":122,"column":106}}})) != null ? stack1 : "")
    + "\">\n                      <i class=\"icon icon-success\"></i>\n                      <i class=\"icon icon-close\"></i>\n                    </span>\n                    <span>\n                    "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"resetPassword_password_use_num_policy1",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":127,"column":20},"end":{"line":127,"column":102}}}))
    + "\n                    </span>\n                  </p>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p id=\"password-use-uppercase\" class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"upperCase") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":67},"end":{"line":132,"column":140}}})) != null ? stack1 : "")
    + "\">\n                    <span class=\"reset-check "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"policyViolations") : stack1)) != null ? lookupProperty(stack1,"upperCase") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":45},"end":{"line":133,"column":107}}})) != null ? stack1 : "")
    + "\">\n                      <i class=\"icon icon-success\"></i>\n                      <i class=\"icon icon-close\"></i>\n                    </span>\n                    <span>\n                    "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"resetPassword_password_upper_case_policy1",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":138,"column":20},"end":{"line":138,"column":105}}}))
    + "\n                    </span>\n                  </p>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<br />\n					<p class=\"reset-message\">\n						"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1), depth0))
    + "\n						<br /> \n						<a id=\"redirectLink\" name=\"redirectLink\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"redirectUrl") : stack1), depth0))
    + "\">\n							"
    + alias2((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"resetPassword_continue",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":174,"column":7},"end":{"line":174,"column":73}}}))
    + "\n						</a>\n					</p>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"label reset-password-message\"><font color='red' size=\"1px\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"authorizationErrorMessage") : stack1), depth0))
    + "<br /><br /></font> </span>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"userName\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1), depth0))
    + "\" />\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"tempAccessCode\" id=\"tempAccessCode\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"tempAccessCode") : stack1), depth0))
    + "\" />\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            	<input type=\"hidden\" name=\"userId\" id=\"userId\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"userId") : stack1), depth0))
    + "\" />\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            	<input type=\"hidden\" name=\"tpid\" id=\"tpid\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"tpid") : stack1), depth0))
    + "\" />\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"resetFor\" id=\"resetFor\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"resetFor") : stack1), depth0))
    + "\" />\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"hidden\" name=\"isAdminResetPassword\" id=\"isAdminResetPassword\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"isAdminResetPassword") : stack1), depth0))
    + "\" />\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"companyId\" id=\"companyId\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"companyId") : stack1), depth0))
    + "\" />\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"globalCompanyId\" id=\"globalCompanyId\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"globalCompanyId") : stack1), depth0))
    + "\" />\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"errorMessage\" id=\"errorMessage\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"errorMessage") : stack1), depth0))
    + "\" />\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"market\" id=\"market\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"market") : stack1), depth0))
    + "\" />\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"returnUrl\" id=\"returnUrl\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"returnUrl") : stack1), depth0))
    + "\" />\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"passedFromLogin\" id=\"passedFromLogin\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"passedFromLogin") : stack1), depth0))
    + "\" />\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"ra\" id=\"ra\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"ra") : stack1), depth0))
    + "\" />\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\"cbl\" id=\"cbl\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"cbl") : stack1), depth0))
    + "\" />\n";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            	<input type=\"hidden\" name=\"setPassword\" id=\"setPassword\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"setPassword") : stack1), depth0))
    + "\" />\n";
},"74":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='isAdmin' id='isAdmin' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"isAdmin") : stack1), depth0))
    + "' />\n";
},"76":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='accs' id='accs' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"accs") : stack1), depth0))
    + "' />\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='processCallbackUrl' id='processCallbackUrl' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"processCallbackUrl") : stack1), depth0))
    + "' />\n";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='firstTimeLogin' id='firstTimeLogin' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"firstTimeLogin") : stack1), depth0))
    + "' />\n";
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='client_id' id='client_id' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"client_id") : stack1), depth0))
    + "' />\n";
},"84":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='redirect_uri' id='redirect_uri' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"redirect_uri") : stack1), depth0))
    + "' />\n";
},"86":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='response_type' id='response_type' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"response_type") : stack1), depth0))
    + "' />\n";
},"88":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='scope' id='scope' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"scope") : stack1), depth0))
    + "' />\n";
},"90":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='state' id='state' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "' />\n";
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='code' id='code' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "' />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"reset-password-form\" method=\"post\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":6,"column":10}}})) != null ? stack1 : "")
    + "\n            <div id=\"global-header-ghost-wrapper\"></div>\n		\n			<div id=\"page\">\n			  <div id=\"content\" class=\"site-content narrow-content\">\n			  \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"passwordExpired") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":22,"column":23}}})) != null ? stack1 : "")
    + "			    \n			    <div class=\"row\">\n			      <div class=\"col col-12 align-center\">\n			        <h1 class=\"font-light\">\n			          "
    + alias2((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"resetPassword_email",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":27,"column":13},"end":{"line":27,"column":76}}}))
    + " <br>\n			          <span class=\"font-semibold\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1), depth0))
    + "</span>\n			        </h1>\n			      </div>\n			    </div>\n    \n		    <div class=\"row\">\n		      <div class=\"col col-12\">\n		        <div class=\"box\">\n		          <div class=\"box-padded\">\n            \n            <div class=\"row\">\n              <div class=\"col col-12 align-center\">\n                <h3>		\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":17},"end":{"line":43,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"statusMessage") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":5},"end":{"line":46,"column":12}}})) != null ? stack1 : "")
    + "				</h3>\n              </div>\n            </div>\n            \n            <div class=\"box-padded\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"authorizationErrorMessage") : stack1),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":12},"end":{"line":178,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"authorizationErrorMessage") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":12},"end":{"line":181,"column":16}}})) != null ? stack1 : "")
    + "            \n          </div>\n        </div>\n      </div>\n    </div>\n    \n  </div>\n</div>\n		\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":3},"end":{"line":193,"column":10}}})) != null ? stack1 : "")
    + "		\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"tempAccessCode") : stack1),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":3},"end":{"line":197,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"userId") : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":9},"end":{"line":201,"column":16}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"tpid") : stack1),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":203,"column":9},"end":{"line":205,"column":16}}})) != null ? stack1 : "")
    + "		\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"resetFor") : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":3},"end":{"line":209,"column":10}}})) != null ? stack1 : "")
    + "					\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"isAdminResetPassword") : stack1),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":3},"end":{"line":213,"column":19}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"companyId") : stack1),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":215,"column":3},"end":{"line":217,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"globalCompanyId") : stack1),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":3},"end":{"line":221,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"errorMessage") : stack1),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":223,"column":3},"end":{"line":225,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"market") : stack1),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":227,"column":3},"end":{"line":229,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"returnUrl") : stack1),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":231,"column":3},"end":{"line":233,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"passedFromLogin") : stack1),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":3},"end":{"line":237,"column":10}}})) != null ? stack1 : "")
    + "		\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"ra") : stack1),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":239,"column":3},"end":{"line":241,"column":10}}})) != null ? stack1 : "")
    + "		\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"cbl") : stack1),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":243,"column":3},"end":{"line":245,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"setPassword") : stack1),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":247,"column":9},"end":{"line":249,"column":16}}})) != null ? stack1 : "")
    + "            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"isAdmin") : stack1),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":251,"column":12},"end":{"line":253,"column":19}}})) != null ? stack1 : "")
    + "            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"accs") : stack1),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":255,"column":12},"end":{"line":257,"column":19}}})) != null ? stack1 : "")
    + "            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"processCallbackUrl") : stack1),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":259,"column":12},"end":{"line":261,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"firstTimeLogin") : stack1),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":263,"column":12},"end":{"line":265,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"client_id") : stack1),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":266,"column":12},"end":{"line":268,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"redirect_uri") : stack1),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":269,"column":12},"end":{"line":271,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"response_type") : stack1),{"name":"if","hash":{},"fn":container.program(86, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":272,"column":12},"end":{"line":274,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"scope") : stack1),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":275,"column":12},"end":{"line":277,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),{"name":"if","hash":{},"fn":container.program(90, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":278,"column":12},"end":{"line":280,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"code") : stack1),{"name":"if","hash":{},"fn":container.program(92, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":281,"column":12},"end":{"line":283,"column":19}}})) != null ? stack1 : "")
    + "</form>\n";
},"usePartial":true,"useData":true}));