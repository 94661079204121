Handlebars.registerPartial('partials/user-phone-number-confirmation-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popup-header flex-container\">\n    <i class=\"icon icon-info\"></i> <p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"update_mobile_number_title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":92}}}))
    + " </p>\n</div>\n\n<p class=\"update-mobile-text\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"covid_update_mobile_number_message_new",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":96}}}))
    + "</p>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/user-mobile"),depth0,{"name":"partials/user-mobile","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<footer class=\"modal-footer cf\">\n    <div class=\"modal-footer-btns footer-btn-right\">\n\n        <button id=\"covid-update-later\" class=\"btn btn-text-action btn-med btn-no-margin\" onclick=\"javascript:redirectToHomePage(this, '"
    + alias3(((helper = (helper = lookupProperty(helpers,"homePageUrl") || (depth0 != null ? lookupProperty(depth0,"homePageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"homePageUrl","hash":{},"data":data,"loc":{"start":{"line":11,"column":136},"end":{"line":11,"column":151}}}) : helper)))
    + "');\">\n            <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"covid_update_later",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":82}}}))
    + "</span>\n        </button>\n        <button id=\"covid-update-now\" class=\"btn btn-action btn-med btn-no-margin\" onclick=\"javascript:updateUserMobileNumber(this, '"
    + alias3(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":14,"column":133},"end":{"line":14,"column":143}}}) : helper)))
    + "','"
    + alias3(((helper = (helper = lookupProperty(helpers,"homePageUrl") || (depth0 != null ? lookupProperty(depth0,"homePageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"homePageUrl","hash":{},"data":data,"loc":{"start":{"line":14,"column":146},"end":{"line":14,"column":161}}}) : helper)))
    + "');\">\n            <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"covid_update_link",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":15,"column":36},"end":{"line":15,"column":81}}}))
    + "</span>\n        </button>\n</div>\n</footer>\n</center>\n";
},"usePartial":true,"useData":true}));