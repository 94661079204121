Handlebars.registerPartial('partials/help-center', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    helpUrl = \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"helpUrl") || (depth0 != null ? lookupProperty(depth0,"helpUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"helpUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":26}}}) : helper)))
    + "\";\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    createHelpCenterDrawer(helpUrl,helpLinkClass)\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "var helpLink= $(\"ul a[id='help']\");\nvar helpUrl= \"/help-center/panel/anonymous\";\nvar $body = $(\"body\");\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"helpUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "if(helpLink && helpLink.length >0){\n    helpLink.removeClass(\"contextual-help-link\");\n    helpLink.addClass(\"contextual-help-link-auth\");\n    helpLink.attr(\"href\",\"javascript:void(0);\");\n    var helpLinkClass = \"contextual-help-link-auth\";\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"dontBindClickEvent") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "}\n";
},"useData":true}));