Handlebars.registerPartial('a3-app/role-permission-matrix', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	    		 	<td ><b>\n	    		 		"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":18}}}) : helper)))
    + "\n	   				</td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	    			<tr>\n	    				<td><b>\n	    		  			"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":20}}}) : helper)))
    + "\n	    		  		</td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":9},"end":{"line":35,"column":18}}})) != null ? stack1 : "")
    + "	    			</tr>	\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	    		  			<td><b>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":28,"column":13},"end":{"line":32,"column":20}}})) != null ? stack1 : "")
    + "	    		  				</b>\n	    		  			</td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "	    		  					<i class=\"alert-text-success icon icon-success\"></i>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "	    		  					<i class=\"alert-text-error icon icon-close\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	\n	<div class=\"row\">\n	    <table class=\"striped data-table fixed\" data-control=\"table\" >\n	    \n	    	<thead>\n	    		<td style=\"min-width:300px\">\n	    			<b>Permission <i class=\" icon icon-arrow90\"></i>\n	    			<br>\n	    			Role <i class=\" icon icon-arrow180\"></i></b>\n	    		</td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"permSetFull") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":7},"end":{"line":15,"column":16}}})) != null ? stack1 : "")
    + "	    	</thead>\n	    	</br>\n	    	</br>\n	    		<tr>\n	    			\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"rolePermissionMapDisp") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":37,"column":17}}})) != null ? stack1 : "")
    + "	    	    </tr>\n		</table>\n	    			\n	</div>";
},"useData":true}));