Handlebars.registerPartial('auth-footer', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"additional_footer_links") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":29},"end":{"line":23,"column":38}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lablel_id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":33},"end":{"line":22,"column":40}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                     "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":37},"end":{"line":21,"column":108}}})) != null ? stack1 : "")
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"lablel_id") : depth0),{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":21,"column":108},"end":{"line":21,"column":143}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":143},"end":{"line":21,"column":165}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href = "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"url","hash":{},"data":data,"loc":{"start":{"line":21,"column":58},"end":{"line":21,"column":65}}}) : helper)))
    + " target=\"_blank\" class=\"grayscale5\">";
},"6":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n	<head>\n	<meta name=\"description\" content=\"Egencial SSO\">\n	<meta name=\"author\" content=\"#\">\n\n</head>\n<body id=\"footer-page\" class=\"wrap cf l-singlecol\">\n		<input type='hidden' name='termsURL' id='termsURL' value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"termsURL") || (depth0 != null ? lookupProperty(depth0,"termsURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"termsURL","hash":{},"data":data,"loc":{"start":{"line":10,"column":60},"end":{"line":10,"column":72}}}) : helper)))
    + "' />\n		<input type='hidden' name='privacyPolicyURL' id='privacyPolicyURL' value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"privacyPolicyURL") || (depth0 != null ? lookupProperty(depth0,"privacyPolicyURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"privacyPolicyURL","hash":{},"data":data,"loc":{"start":{"line":11,"column":76},"end":{"line":11,"column":96}}}) : helper)))
    + "' />\n		<input type='hidden' name='contactUsURL' id='contactUsURL' value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"contactUsURL") || (depth0 != null ? lookupProperty(depth0,"contactUsURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contactUsURL","hash":{},"data":data,"loc":{"start":{"line":12,"column":68},"end":{"line":12,"column":84}}}) : helper)))
    + "' /> \n		 \n		 <div id=\"footer\" class=\"cf\">\n    		 <div class=\"site-navigation\">\n        		 <div class=\"center\">\n                     <div id=\"footer-copyright\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"additional_footer_links") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":25},"end":{"line":24,"column":32}}})) != null ? stack1 : "")
    + "                         <h6>&copy; 2020 Egencia LLC</h6>\n                         <h6>"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"footer.disclaimer",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":26,"column":29},"end":{"line":26,"column":74}}}))
    + "</h6>\n                         <div><i class=\"icon icon-egencia-birdalt\"></i></div>\n                     </div>\n                 </div>\n    	     </div>\n		</div>\n</body>\n</html>\n";
},"useData":true}));