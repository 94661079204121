Handlebars.registerPartial('admin-hub-page', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='locale' id='locale' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":70},"end":{"line":21,"column":80}}}) : helper)))
    + "' />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <input type='hidden' name='locale' id='locale' value='en_US' />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&egencia_authtoken="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"egencia_authtoken") || (depth0 != null ? lookupProperty(depth0,"egencia_authtoken") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"egencia_authtoken","hash":{},"data":data,"loc":{"start":{"line":57,"column":199},"end":{"line":57,"column":220}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n								<tr>\n									<td id =admin-disambig-name"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":103,"column":36},"end":{"line":103,"column":46}}}) : helper)))
    + " class=\"vertical-align-middle admin-tool-td\">\n									    <p id=admin-name-"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"tpid") : depth0), depth0))
    + ">"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"countryName") : depth0), depth0))
    + " ("
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"tpid") : depth0), depth0))
    + ")</p>\n									</td>\n                                    <td id =admin-disambig-select"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":106,"column":65},"end":{"line":106,"column":75}}}) : helper)))
    + " class=\"admin-tool-select admin-tool-td\">\n									    <a id=admin-select-"
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"tpid") : depth0), depth0))
    + " class=\"admin-tool-select\" target=\"NEWWINDOW\" href=\"/.."
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"contextPath") : stack1), depth0))
    + "/v1/switchProfile?admin=true&tuid="
    + alias4(alias5((depth0 != null ? lookupProperty(depth0,"tuid") : depth0), depth0))
    + "\">\n											"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"disambigLogin.select",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":108,"column":11},"end":{"line":108,"column":59}}}))
    + "\n										</a>\n									</td>\n								</tr>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n	<head>\n		 \n		<title>\n			"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"disambigLogin.title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":7,"column":3},"end":{"line":7,"column":50}}}))
    + " | Egencia\n		</title>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-meta-tag"),depth0,{"name":"auth-header-meta-tag","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		<meta name=\"description\" content=\"\">\n		<meta name=\"author\" content=\"#\">\n		<meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		\n	</head>\n	<body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n		<form method=\"post\" action=\"./admin-tools-access\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locale") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":20,"column":6},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "			<input type='hidden' name='isAdmin' id='isAdmin' value=\"true\" />\n		    <input type='hidden' name='userName' id='userName' value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":26,"column":64},"end":{"line":26,"column":76}}}) : helper)))
    + "\" />\n            <input type='hidden' name='egencia_authtoken' id='egencia_authtoken' value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"egencia_authtoken") || (depth0 != null ? lookupProperty(depth0,"egencia_authtoken") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"egencia_authtoken","hash":{},"data":data,"loc":{"start":{"line":27,"column":88},"end":{"line":27,"column":109}}}) : helper)))
    + "\" />\n            <input type='hidden' name='callback-url' id='callback-url' value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"callback-url") || (depth0 != null ? lookupProperty(depth0,"callback-url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"callback-url","hash":{},"data":data,"loc":{"start":{"line":28,"column":78},"end":{"line":28,"column":94}}}) : helper)))
    + "\" />\n            \n			\n            <div id=\"global-header\">\n                <div class=\"site-content\">\n                    <div class=\"site-navigation\">\n                        <div id=\"site-logo\">\n                            <a id=\"primary-nav-home\" class=\"first\">\n                                <span class=\"visuallyhidden\">Egencia</span>\n                            </a>\n                        </div>\n                        <nav id=\"primary-nav-desktop\" class=\"primary-nav\"></nav>\n                        <div id=\"auth-header-menu\" class=\"last auth-nav-item\" style=\"padding: 0px;\">          					\n        					<a id=\"admin-name\" href=\"#\" class=\"auth-nav-item\" data-control=\"tooltip\" data-content-id=\"admin-menu\" data-width=\"240\" data-pos=\"br\" data-prevent-flip=\"true\">\n    	                            "
    + alias3(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":42,"column":33},"end":{"line":42,"column":46}}}) : helper)))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":42,"column":47},"end":{"line":42,"column":59}}}) : helper)))
    + "                            \n    	                        <i class=\"icon icon-toggle180\"></i>\n    	                    </a>  \n        	                \n        	                <div id=\"admin-menu\" class=\"visuallyhidden\"> \n            	                <div class=\"user-control-bar cf\">\n                                \n                                    <div class=\"right\">\n                                        <a id=admin-update-password class=\"admin-tool-header\" href=\"javascript:submitAdminPasswordUpdate()\">\n                                            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"disambigLogin.adminTool.updatePassword",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":51,"column":44},"end":{"line":51,"column":110}}}))
    + "\n                                        </a>\n                                    </div>\n                                </div>\n                                \n                                <div class=\"cf\">                                                               \n                                    <a id=admin-logout class=\"admin-tool-header  right\" href=\"/.."
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"contextPath") : stack1), depth0))
    + "/v1/logout?callback-url="
    + alias3(((helper = (helper = lookupProperty(helpers,"callback-url") || (depth0 != null ? lookupProperty(depth0,"callback-url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"callback-url","hash":{},"data":data,"loc":{"start":{"line":57,"column":139},"end":{"line":57,"column":155}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"egencia_authtoken") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":155},"end":{"line":57,"column":227}}})) != null ? stack1 : "")
    + "\">\n                                        "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"disambigLogin.adminTool.signout",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":58,"column":40},"end":{"line":58,"column":99}}}))
    + "\n                                    </a>                                \n                                </div>\n        	                </div>\n        	            </div>\n					</div>\n				</div>\n			</div>\n			\n			<div id=\"global-header-ghost-wrapper\"></div>\n            \n            <meta charset=\"utf-8\">\n            <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\" />\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-css"),depth0,{"name":"partials/uitk/core-css","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-js"),depth0,{"name":"partials/uitk/core-js","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||alias2).call(alias1,{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"stylesheet":"auth-webapp-core"},"data":data,"loc":{"start":{"line":74,"column":12},"end":{"line":74,"column":91}}})) != null ? stack1 : "")
    + "\n\n            "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"outputType":"script","bundleName":"uitk-analytics"},"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":114}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"auth-extended"},"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":77,"column":92}}})) != null ? stack1 : "")
    + "\n\n            <script language=\"JavaScript\" type=\"text/JavaScript\">\n                EG = initializeEGForOmniture(EG);\n            </script>\n            \n			<div class=\"site-content-wrap\">\n				<div class=\"admin-tool-div\">\n					<div class=\"admin-disambig-data-container\">\n					\n						<p class=\"disambigHeader\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"disambigLogin.adminTool.headin",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":87,"column":32},"end":{"line":87,"column":90}}}))
    + "</p>\n						<br />\n\n					<table class=\"data-table table-striped table-highlight\">\n						<thead>\n							<tr>\n								<th><h5 class=\"admin-table-heading\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"disambigLogin.adminTool.links",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":93,"column":44},"end":{"line":93,"column":101}}}))
    + "</h5></th>\n                                <th></th>\n							</tr>\n						</thead>\n\n						<tbody>\n						    \n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"disambigDataList") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":7},"end":{"line":113,"column":16}}})) != null ? stack1 : "")
    + "						</tbody>\n					</table>\n				</div>\n                </div>\n			</div>	\n			\n\n	        \n	        <script language=\"JavaScript\" type=\"text/JavaScript\">\n	            $(document).ready(function(){\n	                sendPageNameToOmniture(uitk, \"page.auth.adminHub\");\n	            });\n	            \n	        </script>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</form>\n	</body>\n</html>\n";
},"usePartial":true,"useData":true}));