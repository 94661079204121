Handlebars.registerPartial('welcome-page', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='locale' id='locale' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":58},"end":{"line":27,"column":68}}}) : helper)))
    + "' />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "				<input type='hidden' name='locale' id='locale' value='en_US' />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='redirectURL' id='redirectURL' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"redirectURL") || (depth0 != null ? lookupProperty(depth0,"redirectURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"redirectURL","hash":{},"data":data,"loc":{"start":{"line":33,"column":68},"end":{"line":33,"column":83}}}) : helper)))
    + "' />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='email' id='email' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":37,"column":56},"end":{"line":37,"column":65}}}) : helper)))
    + "' />\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='gpid' id='gpid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gpid") || (depth0 != null ? lookupProperty(depth0,"gpid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gpid","hash":{},"data":data,"loc":{"start":{"line":41,"column":54},"end":{"line":41,"column":62}}}) : helper)))
    + "' />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='uid' id='uid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uid") || (depth0 != null ? lookupProperty(depth0,"uid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"uid","hash":{},"data":data,"loc":{"start":{"line":45,"column":52},"end":{"line":45,"column":59}}}) : helper)))
    + "' />\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='cid' id='cid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":49,"column":52},"end":{"line":49,"column":59}}}) : helper)))
    + "' />\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='token' id='token' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"token") || (depth0 != null ? lookupProperty(depth0,"token") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"token","hash":{},"data":data,"loc":{"start":{"line":54,"column":56},"end":{"line":54,"column":65}}}) : helper)))
    + "' />\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='userId' id='userId' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":59,"column":70},"end":{"line":59,"column":80}}}) : helper)))
    + "' />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='authorization' id='authorization' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"authorization") || (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authorization","hash":{},"data":data,"loc":{"start":{"line":63,"column":72},"end":{"line":63,"column":89}}}) : helper)))
    + "' />\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='sessionIds' id='sessionIds' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sessionIds") || (depth0 != null ? lookupProperty(depth0,"sessionIds") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sessionIds","hash":{},"data":data,"loc":{"start":{"line":67,"column":66},"end":{"line":67,"column":80}}}) : helper)))
    + "' />\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type='hidden' name='nameIdData' id='nameIdData' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"nameIdData") || (depth0 != null ? lookupProperty(depth0,"nameIdData") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nameIdData","hash":{},"data":data,"loc":{"start":{"line":71,"column":66},"end":{"line":71,"column":80}}}) : helper)))
    + "' />\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <p><ol type=\"1\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),{"name":"each","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":40},"end":{"line":87,"column":49}}})) != null ? stack1 : "")
    + "                                    </ol>\n                                        </p>\n                                        <br><br>\n\n                                        <a href=\"javascript:redirectToSite();\" style=\"font-weight:bold\">\n                                            "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"welcome.continueLink",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":93,"column":44},"end":{"line":93,"column":92}}}))
    + "\n                                        </a>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                                            "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n                                            <br />\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <ul class=\"welcome-page-language-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"preferredLanguageLinks") : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":44},"end":{"line":103,"column":53}}})) != null ? stack1 : "")
    + "                                        </ul>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <li class=\"welcome-page-language-select\">\n                                                    <a id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":101,"column":59},"end":{"line":101,"column":67}}}) : helper)))
    + "\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":101,"column":75},"end":{"line":101,"column":83}}}) : helper)))
    + "\" href=\"javascript:updateProfileLanguage('"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":101,"column":125},"end":{"line":101,"column":133}}}) : helper)))
    + "');\">"
    + alias4(container.lambda(depth0, depth0))
    + "</a>\n                                                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n	<head>\n		\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header"),depth0,{"name":"auth-header","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "   	 <title>\n			"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"welcome.title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":8,"column":3},"end":{"line":8,"column":44}}}))
    + "\n		</title>\n		<meta name=\"description\" content=\"Welcome Page\">\n		<meta name=\"author\" content=\"#\">\n		<meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n	</head>\n	\n	<body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <script language=\"JavaScript\" type=\"text/JavaScript\">\n        $(document).ready(function(){\n            sendPageNameToOmniture(uitk, \"page.auth.Welcome\");\n        });\n    </script>\n\n    <form>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locale") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":26,"column":3},"end":{"line":30,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"redirectURL") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":3},"end":{"line":34,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":3},"end":{"line":38,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"gpid") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":3},"end":{"line":42,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"uid") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":3},"end":{"line":46,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cid") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":3},"end":{"line":50,"column":10}}})) != null ? stack1 : "")
    + "			\n			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"token") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":5},"end":{"line":55,"column":10}}})) != null ? stack1 : "")
    + "\n            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userId") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":3},"end":{"line":60,"column":19}}})) != null ? stack1 : "")
    + "	            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authorization") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":3},"end":{"line":64,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sessionIds") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":3},"end":{"line":68,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nameIdData") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":3},"end":{"line":72,"column":10}}})) != null ? stack1 : "")
    + "			\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"egencia-global-header"),depth0,{"name":"egencia-global-header","data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <div class=\"site-content\">\n                <div class=\"row\">\n                    <div class=\"col col-8 col-push-2\">\n                        <div class=\"box\">\n                            <div class=\"box-padded\">\n                                <div class=\"welcome-page-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":36},"end":{"line":95,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"preferredLanguageLinks") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":36},"end":{"line":105,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n			\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n		</form>\n	</body>\n</html>\n";
},"usePartial":true,"useData":true}));