Handlebars.registerPartial('a3-app/display-registered-service', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "	    			<td>\n	    			<b>\n	    				SECRET\n	    			</b>\n	    			</td>\n	    		\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		    			<td>\n		    				"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"plain_text_secret") || (depth0 != null ? lookupProperty(depth0,"plain_text_secret") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"plain_text_secret","hash":{},"data":data,"loc":{"start":{"line":51,"column":10},"end":{"line":51,"column":31}}}) : helper)))
    + "\n		    			</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"row\">\n	    <table class=\"data-table\" data-control=\"table\">\n	    <b>\n	    	<thead>\n	    	<b>\n	    		<td>\n	    		<b>\n	    			ID\n	    		</b>\n	    		</td>\n	    		<td>\n	    		<b>\n	    			CLIENT NAME\n	    		</b>\n	    		</td>\n	    		<td>\n	    		<b>\n	    			ALLOWED SCOPES\n	    		</b>\n	    		</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain_text_secret") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":7},"end":{"line":28,"column":14}}})) != null ? stack1 : "")
    + "	    		\n	    		<td>\n	    		<b>\n	    			AUTHENTICATION TYPE\n	    		</b>\n	    		</td>\n	    	\n	    	</thead>\n	    	</b>\n	    	<tbody>\n	    		<tr>\n	    			<td>\n	    				"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":9},"end":{"line":41,"column":15}}}) : helper)))
    + "\n	    			</td>\n	    			<td>\n	    				"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":44,"column":9},"end":{"line":44,"column":17}}}) : helper)))
    + "\n	    			</td>\n	    			<td>\n	    				"
    + alias4(((helper = (helper = lookupProperty(helpers,"allowed_scopes") || (depth0 != null ? lookupProperty(depth0,"allowed_scopes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"allowed_scopes","hash":{},"data":data,"loc":{"start":{"line":47,"column":9},"end":{"line":47,"column":27}}}) : helper)))
    + "\n	    			</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain_text_secret") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "	    			<td>\n	    				"
    + alias4(((helper = (helper = lookupProperty(helpers,"authentication_type") || (depth0 != null ? lookupProperty(depth0,"authentication_type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"authentication_type","hash":{},"data":data,"loc":{"start":{"line":55,"column":9},"end":{"line":55,"column":32}}}) : helper)))
    + "\n	    			</td>\n	    		</tr>\n	    	</tbody>\n		</table>\n	    			\n	</div>		\n	";
},"useData":true}));