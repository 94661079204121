Handlebars.registerPartial('na-platform-post', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                action=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"emainHostName") || (depth0 != null ? lookupProperty(depth0,"emainHostName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emainHostName","hash":{},"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":41}}}) : helper)))
    + "\"\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                action=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"returnUrl") || (depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"returnUrl","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":37}}}) : helper)))
    + "\"\n            ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='uurl' id='uurl' value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"emainUurl") || (depth0 != null ? lookupProperty(depth0,"emainUurl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emainUurl","hash":{},"data":data,"loc":{"start":{"line":22,"column":66},"end":{"line":22,"column":79}}}) : helper)))
    + "\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type='hidden' name='original_redirect' id='original_redirect' value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"original_redirect") || (depth0 != null ? lookupProperty(depth0,"original_redirect") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"original_redirect","hash":{},"data":data,"loc":{"start":{"line":26,"column":92},"end":{"line":26,"column":113}}}) : helper)))
    + "\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n    <head>\n        <script language=\"JavaScript\" type=\"text/JavaScript\">\n            $(document).ready(function(){\n                sendPageNameToOmniture(uitk, \"page.auth.NaPlatformPost\");\n            });\n        </script>\n    </head>\n    \n    <body onload=javascript:document.forms[0].submit()>\n\n        <form method=\"post\" \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"emainHostName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + " >\n        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"emainUurl") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"original_redirect") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "        </form>\n\n    </body>\n</html>";
},"useData":true}));