Handlebars.registerPartial('manage-accounts', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <title>\n        "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"manageYourAccount_pageTitle",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":49}}}))
    + " | Egencia\n    </title>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"site-content\">\n    	<span id=\"content\"></span>\n\n    	<div id = \"loaderDiv\">\n    		<center>\n    			<h1>\n    				<span class=\"loader loading\" id=\"onScreenLoader\"></span>\n    			</h1>\n    		</center>\n    	</div>\n\n    	<div id = \"manageYourAccount\">\n    	</div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"manage-accounts"},"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":23,"column":84}}})) != null ? stack1 : "")
    + "\n\n        "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"outputType":"script","bundleName":"uitk-analytics"},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":110}}})) != null ? stack1 : "")
    + "\n\n        <script language=\"JavaScript\" type=\"text/JavaScript\">\n            $(document).ready(function(){\n                sendPageNameToOmniture(uitk, \"page.auth.ManageAccount\");\n            });\n        </script>\n\n		<script type=\"text/javascript\">\n			EG.accountProfile = "
    + alias3((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountProfiles") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":34,"column":23},"end":{"line":34,"column":47}}}))
    + ";\n			EG.isTravelDirector = "
    + alias3(((helper = (helper = lookupProperty(helpers,"isTravelDirector") || (depth0 != null ? lookupProperty(depth0,"isTravelDirector") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"isTravelDirector","hash":{},"data":data,"loc":{"start":{"line":35,"column":25},"end":{"line":35,"column":45}}}) : helper)))
    + ";\n			EG.allowTMTologin = "
    + alias3(((helper = (helper = lookupProperty(helpers,"allowAccountLinking") || (depth0 != null ? lookupProperty(depth0,"allowAccountLinking") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"allowAccountLinking","hash":{},"data":data,"loc":{"start":{"line":36,"column":23},"end":{"line":36,"column":46}}}) : helper)))
    + ";\n			EG.mst = "
    + alias3((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"securityToken") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":34}}}))
    + ";\n		</script>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"partial")||(depth0 && lookupProperty(depth0,"partial"))||alias2).call(alias1,"title",{"name":"partial","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"partial")||(depth0 && lookupProperty(depth0,"partial"))||alias2).call(alias1,"content",{"name":"partial","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":21,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"partial")||(depth0 && lookupProperty(depth0,"partial"))||alias2).call(alias1,"more-scripts",{"name":"partial","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":40,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"layouts/default"),depth0,{"name":"layouts/default","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));