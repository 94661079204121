Handlebars.registerPartial('partials/mfa-changes-forgot-password-page', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"security-question-button\" class=\"security-question-button\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"userInfoList") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":18,"column":17}}})) != null ? stack1 : "")
    + "\n        <div  ><span><a id=\"answer-security-questions\"  href=\"javascript:void(0);\" onclick='javascript:launchMfa();return false;'>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"forgot.mfa.dq.security_questions_label",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":20,"column":130},"end":{"line":20,"column":196}}}))
    + " </a></span></div>\n\n        <script language=\"JavaScript\" type=\"text/JavaScript\">\n            function launchMfa() {\n                sendPageInteraction(uitk,\"Global.MFA.DQ.Forgot.username.password.link\");\n                sendDataToOmniture(uitk);\n                document.forms[0].action =\"/auth/v1/mfa/initiate\";\n                uitk.logger._log('info',\"mfa initiated via /auth/v1/mfa/initiate\");\n                document.forms[0].submit();\n            }\n        </script>\n\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"hidden\" value="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userId") : depth0), depth0))
    + " id=\"userId\" name=\"userId\">\n            <input type=\"hidden\" value="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"companyId") : depth0), depth0))
    + " id=\"companyId\" name=\"companyId\">\n            <input type=\"hidden\" value="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + " id=\"posId\" name=\"posId\">\n            <input type=\"hidden\" value="
    + alias2(((helper = (helper = lookupProperty(helpers,"useCaseId") || (depth0 != null ? lookupProperty(depth0,"useCaseId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"useCaseId","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":52}}}) : helper)))
    + " id=\"useCaseId\" name=\"useCaseId\">\n            <input type=\"hidden\" value=\"en-GB\" id=\"locale\" name=\"locale\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"\">\n\n<ul>\n<li class=\"generic-message-heading\" > "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"messageHeading") || (depth0 != null ? lookupProperty(depth0,"messageHeading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageHeading","hash":{},"data":data,"loc":{"start":{"line":5,"column":38},"end":{"line":5,"column":58}}}) : helper))) != null ? stack1 : "")
    + " </li>\n<li class=\"generic-message-heading\" > "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"messageSubheading") || (depth0 != null ? lookupProperty(depth0,"messageSubheading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageSubheading","hash":{},"data":data,"loc":{"start":{"line":6,"column":38},"end":{"line":6,"column":61}}}) : helper))) != null ? stack1 : "")
    + " </li>\n</ul>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enableMFAForResetPassword") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":33,"column":7}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true}));