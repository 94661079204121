Handlebars.registerPartial('partials/dob', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <option name=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <option name=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-12 col-medium-12 date-of-birth nested-grid\">\n    <div  class=\"row\" data-control=\"dob\"\n         id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":19}}}) : helper)))
    + "\">\n        <div class=\"col col-4 input-group select\">\n            <select id=\"date\">\n                <option value=\"\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"date",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":65}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"dateRange") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"col col-4 input-group select\" >\n            <select id=\"month\">\n                <option value=\"\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"month",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":14,"column":33},"end":{"line":14,"column":66}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthRange") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":17,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"col col-4 input-group select\" >\n            <select id=\"year\">\n                <option value=\"\">"
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"year",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":22,"column":33},"end":{"line":22,"column":65}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"yearRange") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":25,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n\n    <input class=\"dob-value\" type=\"hidden\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":30,"column":49},"end":{"line":30,"column":57}}}) : helper)))
    + "\" data-control=\"dob-ctl\">\n</div>\n";
},"useData":true}));