Handlebars.registerPartial('disabled-feature', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p id=\"info-message\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"additional_info") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":41,"column":45},"end":{"line":41,"column":72}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n<head>\n    <!-- stylesheets -->\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-css"),depth0,{"name":"partials/uitk/core-css","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-js"),depth0,{"name":"partials/uitk/core-js","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||alias2).call(alias1,{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"stylesheet":"manage-accounts-core"},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":88}}})) != null ? stack1 : "")
    + "\n\n    <script>\n		window.EG = "
    + alias3((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EG") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":14,"column":25}}}))
    + " || {};\n\n		function trimAuthTokenFromUrl() {\n		var url = window.location.href;\n		url = url.split('?')[0];\n		url = url.substring(url.lastIndexOf('/auth') + 1);\n		window.history.pushState(\"Trimming the auth token\", \"Title\", \"/\" + url );\n	}\n\n	$(document).ready(function() {trimAuthTokenFromUrl()});\n\n	</script>\n\n</head>\n\n<body>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    "
    + ((stack1 = (lookupProperty(helpers,"block")||(depth0 && lookupProperty(depth0,"block"))||alias2).call(alias1,"content",{"name":"block","hash":{},"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":31,"column":25}}})) != null ? stack1 : "")
    + "\n\n    <!-- Scripts ONLY below this line -->\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-js"),depth0,{"name":"partials/uitk/core-js","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"site-content\">\n        <div class=\"auth-content-wrapper col col-8 col-push-2\" id=\"featureDisabled\">\n            <div class=\"box\">\n                <div class=\"box-padded\">\n                    <p id=\"error-message\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_unAuthorized",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":39,"column":42},"end":{"line":39,"column":86}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"additional_info") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":42,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n    <div id=\"featureDisabledFooter\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n\n</body>\n\n</html>\n";
},"usePartial":true,"useData":true}));