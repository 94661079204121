Handlebars.registerPartial('a3-app/manage-permission', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "        					<option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        					<option>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":54,"column":21},"end":{"line":54,"column":29}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div id=\"show_permission_error\">\n</div>\n<div class=\"box\">\n<div class=\"box-padded\">\n<h3>DEFINE NEW PERMISSION</h3>\n	<div class=\"row\" style=\"vertical-align: bottom;\">\n	    <div class=\"col col-3\" data-control=\"tooltip\" data-trigger=\"hover\" data-content-id=\"tooltip-permission\">\n	    		<div class=\"input-group select\">\n    				<select id=\"permission_name1\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"lineOfBusiness") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "    				</select>\n    			</div>	\n	    		\n		</div>	\n		<div id = \"tooltip-permission\" class = \"visuallyhidden col col-3\">\n			Please select what kind of permission you want to create. Shared(use option ‘all’) or Specific(use other options)?\n		</div>\n		<div class=\"col col-1\" style=\"vertical-align: bottom;\">\n			<center style=\"vertical-align: bottom;height: 60px;\"><b>.</b></center>\n		</div>\n		<div class=\"col col-3\" data-control=\"tooltip\" data-trigger=\"hover\" data-content-id=\"tooltip-verb\">\n				<div class=\"input-group\">\n	    				<input type=\"text\" id=\"permission_name2\">\n	    		</div>		\n		</div>\n		<div id = \"tooltip-verb\" class = \"visuallyhidden col col-3\">\n			Please mention the verb part . Ex : config.<b>manage</b>.security\n		</div>\n		<div class=\"col col-1\" style=\"vertical-align: bottom;\">\n			<center style=\"vertical-align: bottom;height: 60px;\"><b>.</b></center>\n		</div>\n		<div class=\"col col-3\" data-control=\"tooltip\" data-trigger=\"hover\" data-content-id=\"tooltip-noun\">\n				<div class=\"input-group\">\n	    				<input type=\"text\" id=\"permission_name3\">\n	    		</div>		\n		</div>\n		<div id = \"tooltip-noun\" class = \"visuallyhidden col col-3\">\n			Please mention the noun part. Ex : config.manage.<b>security</b>\n		</div>\n	</div>\n	\n	<br>\n	<br>\n	<div class=\"row\" style=\"vertical-align: bottom;\">\n	    <div class=\"col col-3\" style=\"width:322.25px;\">\n	    		\n	    		<div class=\"input-group select\">\n	    		<label>SELECT_A_ROLE</label>\n    				<select id=\"role\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"roles") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":55,"column":21}}})) != null ? stack1 : "")
    + "        				\n    				</select>\n    			</div>	\n	    		\n		</div>	\n		\n	</div>\n\n	<br>\n	<br>\n	\n\n	<div class=\"row\">\n			<div class=\"col col-4 remove-margin\">\n				<br>\n			</div>\n	\n			<div class=\"col col-2 remove-margin\">\n    			<button class=\"btn btn-action btn-med\" id=\"add-permission\">\n        		    <span class=\"btn-label\">Add Permission</span>\n        		</button>\n			</div>\n			<div class=\"col col-2 remove-margin\">\n                <button class=\"btn btn-action btn-med\" id=\"remove-permission\">\n                    <span class=\"btn-label\">Remove Permission</span>\n                </button>\n			</div>\n			\n		\n	</div>\n	<br>\n	<br>\n	<br>\n	</div>\n</div>\n	\n	\n<div id=\"display_matrix\">\n		\n</div>		\n\n\n\n\n";
},"useData":true}));