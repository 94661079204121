Handlebars.registerPartial('partials/vanity-redirection', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "var vanityRedirectionUrl = \""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"vanityRedirectionUrl") || (depth0 != null ? lookupProperty(depth0,"vanityRedirectionUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vanityRedirectionUrl","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":54}}}) : helper))) != null ? stack1 : "")
    + "\"; \nif (vanityRedirectionUrl) {\n    setPageStatus(\"Page.auth.forgot.password.SSORedirection\");\n    var redirectionModalWaitingTime = \""
    + alias4(((helper = (helper = lookupProperty(helpers,"redirectionModalWaitingTime") || (depth0 != null ? lookupProperty(depth0,"redirectionModalWaitingTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"redirectionModalWaitingTime","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":70}}}) : helper)))
    + "\"; \n    if (redirectionModalWaitingTime) {\n        redirectionModalWaitingTime = parseInt(redirectionModalWaitingTime);\n    } else {\n        redirectionModalWaitingTime = 2000;\n    }\n\n    var data = {};\n    data[\"redirectionToVanity\"] = true;\n    data[\"imagePath\"] = \""
    + alias4(((helper = (helper = lookupProperty(helpers,"imagePath") || (depth0 != null ? lookupProperty(depth0,"imagePath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imagePath","hash":{},"data":data,"loc":{"start":{"line":13,"column":25},"end":{"line":13,"column":38}}}) : helper)))
    + "\" || \"images/redirection-image.png\";\n    var redirectionModal = uitk.modal.create({\n        modalId: 'vanity-redirection-modal',\n        content: Handlebars.templates[\"partials/vanity-redirection-info-modal\"](data),\n        dismiss : false\n        });\n    redirectionModal.open();\n    setTimeout(function () {\n        window.location.href = vanityRedirectionUrl;\n    },redirectionModalWaitingTime);\n}";
},"useData":true}));