Handlebars.registerPartial('post-login-msg-modal-page', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n    <head>\n\n        <title>\n            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"post_login_msg_modal_title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":66}}}))
    + "\n        </title>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-meta-tag"),depth0,{"name":"auth-header-meta-tag","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-styles"),depth0,{"name":"auth-header-styles","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <meta name=\"description\" content=\"Egencial SSO\">\n        <meta name=\"author\" content=\"#\">\n        <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n        <COMMENT ID=PageId TITLE=\"AUTH_WEBAPP_LOGIN\"></COMMENT>\n\n    </head>\n\n    <body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"egencia-global-header"),depth0,{"name":"egencia-global-header","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <script language=\"JavaScript\" type=\"text/JavaScript\">\n            overRideCommentTag();\n            $(document).ready(function(){\n\n               function redirectUserToRedirectionPage() {\n               		uitk.logger._log('info', 'Post Login Msg Modal Shown and User has Clicked on close button for companyId: '+'"
    + alias3(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":33,"column":125},"end":{"line":33,"column":138}}}) : helper)))
    + "');\n                    redirectUserToGivenPage('"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"redirectionURL") || (depth0 != null ? lookupProperty(depth0,"redirectionURL") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectionURL","hash":{},"data":data,"loc":{"start":{"line":34,"column":45},"end":{"line":34,"column":65}}}) : helper))) != null ? stack1 : "")
    + "');\n                }\n\n               uitk.logger._log('info', 'Post Login Msg Modal Opened for gpid: '+'"
    + alias3(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":37,"column":82},"end":{"line":37,"column":95}}}) : helper)))
    + "');\n               uitk.subscribe(\"modal.close\", redirectUserToRedirectionPage);\n               var renderedContent = Handlebars.templates[\"partials/post-login-msg-modal\"]({'redirectUrl' : '"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"redirectionURL") || (depth0 != null ? lookupProperty(depth0,"redirectionURL") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectionURL","hash":{},"data":data,"loc":{"start":{"line":39,"column":109},"end":{"line":39,"column":129}}}) : helper))) != null ? stack1 : "")
    + "','companyId' : '"
    + alias3(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":39,"column":146},"end":{"line":39,"column":159}}}) : helper)))
    + "'});\n                var postLoginMsgModal = uitk.modal.create({\n                    modalId: 'post-login-msg-modal',\n                    content : renderedContent,\n                    dismiss : false,\n                    closeBtn : false}, true);\n                postLoginMsgModal.open();\n            });\n        </script>\n\n        <div class=\"site-content\">\n            <div class=\"row\">\n                <div class=\"col col-8 col-push-2\">\n                    <div class=\"term-polic-div\">\n                    </div>\n                 </div>\n            </div>\n        </div>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "     </body>\n</html>\n";
},"usePartial":true,"useData":true}));