Handlebars.registerPartial('partials/new-login-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"new_login_modal_content",(depth0 != null ? lookupProperty(depth0,"userName") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":49}}}))
    + "</p>\n\n";
},"useData":true}));