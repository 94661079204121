Handlebars.registerPartial('terms-and-policy-page', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='loginType' id='loginType' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"loginType") || (depth0 != null ? lookupProperty(depth0,"loginType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"loginType","hash":{},"data":data,"loc":{"start":{"line":37,"column":96},"end":{"line":37,"column":109}}}) : helper)))
    + "' />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='companyId' id='companyId' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":41,"column":96},"end":{"line":41,"column":109}}}) : helper)))
    + "' />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='userName' id='userName' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":45,"column":94},"end":{"line":45,"column":106}}}) : helper)))
    + "' />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='userId' id='userId' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":49,"column":90},"end":{"line":49,"column":100}}}) : helper)))
    + "' />\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='tpid' id='tpid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tpid") || (depth0 != null ? lookupProperty(depth0,"tpid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tpid","hash":{},"data":data,"loc":{"start":{"line":53,"column":86},"end":{"line":53,"column":94}}}) : helper)))
    + "' />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='tempAccessCode' id='tempAccessCode' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tempAccessCode") || (depth0 != null ? lookupProperty(depth0,"tempAccessCode") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tempAccessCode","hash":{},"data":data,"loc":{"start":{"line":57,"column":106},"end":{"line":57,"column":124}}}) : helper)))
    + "' />\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='returnUrl' id='returnUrl' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"returnUrl") || (depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"returnUrl","hash":{},"data":data,"loc":{"start":{"line":61,"column":96},"end":{"line":61,"column":109}}}) : helper)))
    + "' />\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='locale' id='locale' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":90},"end":{"line":65,"column":100}}}) : helper)))
    + "' />\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                                    <input type='hidden' name='locale' id='locale' value='en_US' />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='email' id='email' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":71,"column":88},"end":{"line":71,"column":97}}}) : helper)))
    + "' />\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='posid' id='posid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"posid") || (depth0 != null ? lookupProperty(depth0,"posid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"posid","hash":{},"data":data,"loc":{"start":{"line":75,"column":88},"end":{"line":75,"column":97}}}) : helper)))
    + "' />\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='gpid' id='gpid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gpid") || (depth0 != null ? lookupProperty(depth0,"gpid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gpid","hash":{},"data":data,"loc":{"start":{"line":79,"column":86},"end":{"line":79,"column":94}}}) : helper)))
    + "' />\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='uid' id='uid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uid") || (depth0 != null ? lookupProperty(depth0,"uid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"uid","hash":{},"data":data,"loc":{"start":{"line":83,"column":84},"end":{"line":83,"column":91}}}) : helper)))
    + "' />\n";
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='cid' id='cid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":87,"column":84},"end":{"line":87,"column":91}}}) : helper)))
    + "' />\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='authorization' id='authorization' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"authorization") || (depth0 != null ? lookupProperty(depth0,"authorization") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authorization","hash":{},"data":data,"loc":{"start":{"line":91,"column":104},"end":{"line":91,"column":121}}}) : helper)))
    + "' />\n";
},"31":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='sessionIds' id='sessionIds' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sessionIds") || (depth0 != null ? lookupProperty(depth0,"sessionIds") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sessionIds","hash":{},"data":data,"loc":{"start":{"line":95,"column":98},"end":{"line":95,"column":112}}}) : helper)))
    + "' />\n";
},"33":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='nameIdData' id='nameIdData' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"nameIdData") || (depth0 != null ? lookupProperty(depth0,"nameIdData") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nameIdData","hash":{},"data":data,"loc":{"start":{"line":99,"column":98},"end":{"line":99,"column":112}}}) : helper)))
    + "' />\n";
},"35":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='IDPPlatform' id='IDPPlatform' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"IDPPlatform") || (depth0 != null ? lookupProperty(depth0,"IDPPlatform") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"IDPPlatform","hash":{},"data":data,"loc":{"start":{"line":103,"column":100},"end":{"line":103,"column":115}}}) : helper)))
    + "' />\n";
},"37":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='sourceIDP' id='sourceIDP' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sourceIDP") || (depth0 != null ? lookupProperty(depth0,"sourceIDP") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sourceIDP","hash":{},"data":data,"loc":{"start":{"line":107,"column":96},"end":{"line":107,"column":109}}}) : helper)))
    + "' />\n";
},"39":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='redirectionURL' id='redirectionURL' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"redirectionURL") || (depth0 != null ? lookupProperty(depth0,"redirectionURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"redirectionURL","hash":{},"data":data,"loc":{"start":{"line":111,"column":106},"end":{"line":111,"column":124}}}) : helper)))
    + "' />\n";
},"41":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type='hidden' name='resetPassword' id='resetPassword' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resetPassword") || (depth0 != null ? lookupProperty(depth0,"resetPassword") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resetPassword","hash":{},"data":data,"loc":{"start":{"line":115,"column":104},"end":{"line":115,"column":121}}}) : helper)))
    + "' />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n    <head>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header"),depth0,{"name":"auth-header","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <title>\n            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"terms_and_policy_page_title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":67}}}))
    + " | Egencia\n        </title>\n\n        <meta name=\"description\" content=\"Egencial SSO\">\n        <meta name=\"author\" content=\"#\">\n        <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n        <COMMENT ID=PageId TITLE=\"AUTH_WEBAPP_LOGIN\"></COMMENT>\n\n\n    </head>\n\n    <body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"egencia-global-header"),depth0,{"name":"egencia-global-header","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <script language=\"JavaScript\" type=\"text/JavaScript\">\n            $(document).ready(function(){\n                setPageNameAnalyticsData(uitk, \"page.auth.termsAndPolicy\");\n                sendDataToOmniture(uitk);\n            });\n        </script>\n\n        <div class=\"site-content\">\n            <div class=\"row\">\n                <div class=\"col col-8 col-push-2\">\n                    <div class=\"box\">\n                        <div class=\"box-padded\">\n                            <form method=\"post\" action=\"/auth/v1/termsAndPolicy\">\n                                <p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"terms_and_policy_acceptance_sentence",(depth0 != null ? lookupProperty(depth0,"termsOfUseURL") : depth0),(depth0 != null ? lookupProperty(depth0,"privacyURL") : depth0),{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":34,"column":35},"end":{"line":34,"column":124}}}))
    + "</p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginType") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":32},"end":{"line":38,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"companyId") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":32},"end":{"line":42,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userName") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":32},"end":{"line":46,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userId") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":32},"end":{"line":50,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tpid") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":32},"end":{"line":54,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tempAccessCode") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":32},"end":{"line":58,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":32},"end":{"line":62,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locale") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":64,"column":32},"end":{"line":68,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":72,"column":39}}})) != null ? stack1 : "")
    + "                                \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"posid") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":32},"end":{"line":76,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"gpid") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":32},"end":{"line":80,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"uid") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":32},"end":{"line":84,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cid") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":32},"end":{"line":88,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authorization") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":32},"end":{"line":92,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sessionIds") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":32},"end":{"line":96,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nameIdData") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":32},"end":{"line":100,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IDPPlatform") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":32},"end":{"line":104,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sourceIDP") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":32},"end":{"line":108,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"redirectionURL") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":32},"end":{"line":112,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resetPassword") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":32},"end":{"line":116,"column":39}}})) != null ? stack1 : "")
    + "\n                                <button type=\"submit\" id=\"termsPolicyContinue\" class=\"btn btn-action btn-med\" target=\"_parent\" onclick=''>\n                                    <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"terms_and_policy_submit_button_label",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":119,"column":60},"end":{"line":119,"column":124}}}))
    + "</span>\n                                </button>\n                            </form>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "     </body>\n</html>\n";
},"usePartial":true,"useData":true}));