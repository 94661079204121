Handlebars.registerPartial('partials/reset-password-mobile-view', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/alert-tag"),depth0,{"name":"partials/uitk/alert-tag","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0),"msgLabel":"resetPassword.passwordExpired","type":"info"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <br/>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"adminUpdatedPassword") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":12,"column":4}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/alert-tag"),depth0,{"name":"partials/uitk/alert-tag","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0),"msgLabel":"resetPassword_adminUpdatedPassword","type":"info"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <br/>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"firstLogin") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":12,"column":4}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/alert-tag"),depth0,{"name":"partials/uitk/alert-tag","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0),"msgLabel":"resetPassword.firstLogin","type":"info"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <br/>\n    ";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"flex-container\">\n                                        <div class=\"flex-grow\"> <p  id=\"reset-mfa-success-msg\" class=\"reset-mfa-success-msg\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"reset_page_mfa_scucces_msg",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":26,"column":125},"end":{"line":26,"column":179}}}))
    + "</p></div>\n\n                                        <span class=\"reset-mfa-success-icon\"> <i class=\"icon icon-success\"></i></span>\n                                    </div>\n                                    <div class=\"flex-container\">\n                                        <div class=\"reset-page-heading reset-page-heading-success\">\n                                        <span id=\"main-reset-heading\">\n                                            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_heading",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":33,"column":44},"end":{"line":33,"column":93}}}))
    + "\n                                        </span>\n                                        </div>\n                                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"flex-container\">\n                                        <div class=\"reset-page-heading\">\n                                        <span id=\"main-reset-heading\">\n                                            "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"reset_page_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":41,"column":44},"end":{"line":41,"column":89}}}))
    + "\n                                        </span>\n                                        </div>\n                                    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"statusMessage") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":24},"end":{"line":308,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"statusMessage") : depth0),{"name":"if","hash":{},"fn":container.program(97, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":309,"column":24},"end":{"line":318,"column":31}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":28},"end":{"line":75,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUnrecoverableError") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":28},"end":{"line":307,"column":39}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                                <div id=\"generic-error\" class=\"row\">\n                                    <div class=\"col col-12\">\n<span class=\"label reset-password-label alert-text-error\">\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":60,"column":4},"end":{"line":60,"column":20}}}) : helper)))
    + "\n</span>\n                                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"passwordHistory") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":36},"end":{"line":73,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"passwordHistoryPlacementTop") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":40},"end":{"line":72,"column":51}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"col col-12\">\n                                                <p id=\"password-history-size\" class=\"secondary alert-text-error\">\n                   <span>\n                       "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"localizedPasswordHistoryMessageKey") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0)) != null ? lookupProperty(stack1,"passwordHistorySize") : stack1),{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":68,"column":23},"end":{"line":68,"column":117}}}))
    + "\n                   </span>\n                                                </p>\n                                            </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"row\">\n                                    <div class=\"col col-12\">\n                                        <form id=\"reset-password-form\" method=\"post\" >\n\n                                            <input type='hidden' name='pageName' id='pageName' value='reset-password-page' />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locale") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":82,"column":44},"end":{"line":86,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tempAccessCode") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":44},"end":{"line":90,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userId") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":44},"end":{"line":94,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authTuid") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":44},"end":{"line":98,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authGpid") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":44},"end":{"line":102,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tpid") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":44},"end":{"line":106,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resetFor") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":44},"end":{"line":110,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminResetPassword") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":44},"end":{"line":114,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"companyId") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":44},"end":{"line":118,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"globalCompanyId") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":44},"end":{"line":122,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":44},"end":{"line":126,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"market") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":44},"end":{"line":130,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":44},"end":{"line":134,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"passedFromLogin") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":44},"end":{"line":138,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ra") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":44},"end":{"line":142,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cbl") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":44},"end":{"line":146,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"setPassword") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":44},"end":{"line":150,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":44},"end":{"line":154,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"accs") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":44},"end":{"line":158,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"processCallbackUrl") : depth0),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":160,"column":44},"end":{"line":162,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"firstTimeLogin") : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":44},"end":{"line":166,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"oAuthParameters") : depth0)) != null ? lookupProperty(stack1,"client_id") : stack1),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":44},"end":{"line":170,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"oAuthParameters") : depth0)) != null ? lookupProperty(stack1,"redirect_uri") : stack1),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":44},"end":{"line":173,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"oAuthParameters") : depth0)) != null ? lookupProperty(stack1,"response_type") : stack1),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":44},"end":{"line":176,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"oAuthParameters") : depth0)) != null ? lookupProperty(stack1,"scope") : stack1),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":44},"end":{"line":179,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"oAuthParameters") : depth0)) != null ? lookupProperty(stack1,"state") : stack1),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":44},"end":{"line":182,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":44},"end":{"line":185,"column":51}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resetPswdViaMFA") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":44},"end":{"line":188,"column":51}}})) != null ? stack1 : "")
    + "\n                                            <input type='hidden' name='confirmPassword' id='confirmPassword'  />\n\n\n                                            <div class=\"input-group\">\n                                                <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"egenciaLogin.user.name",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":194,"column":55},"end":{"line":194,"column":105}}}))
    + "</label>\n                                                <input id=\"displayUName\" name=\"displayUName\" type=\"text\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":195,"column":112},"end":{"line":195,"column":124}}}) : helper)))
    + "\" readonly=\"true\" class=\"replay-safe disabledField\">\n                                            </div>\n                                            <input id=\"userName\" name=\"userName\" type=\"text\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":197,"column":100},"end":{"line":197,"column":112}}}) : helper)))
    + "\" class=\"hiddenUserName\">\n                                            <div id=\"pswd-validate\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":48},"end":{"line":272,"column":55}}})) != null ? stack1 : "")
    + "                                            </div>\n\n                                            <div class=\"input-group input-icon-right\">\n                                                <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_new",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":276,"column":55},"end":{"line":276,"column":109}}}))
    + "<span class=\"alert-text-error\">*</span></label>\n                                                <input id=\"password\" name=\"password\" type=\"password\" value=\"\" autofocus>\n                                                <i id=\"password-visibility-toggle\" class=\"icon icon-viewedalt\" oncontextmenu=\"return false;\"></i>\n                                            </div>\n\n                                            <div class=\"caps-alert-warn displayNone \" data-control=\"alert\">\n                                                <i class=\"icon icon-warnalt\"></i>\n                                                <span class=\"alert-message\">\n                                                       <p> "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"egenciaLogin.user.passwordCapsLockWarning",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":284,"column":59},"end":{"line":284,"column":128}}}))
    + "</p>\n                                                   </span>\n                                            </div>\n                                            <p id=\"password-regex\" class=\" displayNone secondary alert-text-error\">\n                                                    <span>\n                                                        "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_regex_validation",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":289,"column":56},"end":{"line":289,"column":123}}}))
    + "\n                                                    </span>\n                                            </p>\n                                        </form>\n                                        <div class=\"input-group input-icon-right\">\n                                            <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_Reenter",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":294,"column":51},"end":{"line":294,"column":109}}}))
    + "<span\n                                                    class=\"alert-text-error\">*</span></label>\n                                            <input id=\"confirmPass\" name=\"confirmPass\" type=\"password\" value=\"\">\n                                            <i id=\"reenter-password-visibility-toggle\" class=\"icon icon-viewedalt\" oncontextmenu=\"return false;\"></i>\n                                            <span id=\"error-message\" class=\"input-message\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"reset_password_passwords_mismatch",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":298,"column":91},"end":{"line":298,"column":152}}}))
    + "</span>\n                                        </div>\n                                        <button class=\"btn btn-action btn-med\" type=\"submit\" target=\"_parent\" id=\"resetPasswordButton\" disabled=\"true\">\n                                            <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_save",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":301,"column":68},"end":{"line":301,"column":123}}}))
    + "</span>\n                                        </button>\n\n                                    </div>\n                                </div>\n                            </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='locale' id='locale' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":102},"end":{"line":83,"column":112}}}) : helper)))
    + "'/>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "                                                <input type='hidden' name='locale' id='locale' value='en_US'/>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"tempAccessCode\" id=\"tempAccessCode\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tempAccessCode") || (depth0 != null ? lookupProperty(depth0,"tempAccessCode") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tempAccessCode","hash":{},"data":data,"loc":{"start":{"line":89,"column":118},"end":{"line":89,"column":136}}}) : helper)))
    + "\" />\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"userId\" id=\"userId\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":93,"column":102},"end":{"line":93,"column":112}}}) : helper)))
    + "\" />\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"authTuid\" id=\"authTuid\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"authTuid") || (depth0 != null ? lookupProperty(depth0,"authTuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authTuid","hash":{},"data":data,"loc":{"start":{"line":97,"column":106},"end":{"line":97,"column":118}}}) : helper)))
    + "\" />\n";
},"30":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"authGpid\" id=\"authGpid\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"authGpid") || (depth0 != null ? lookupProperty(depth0,"authGpid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authGpid","hash":{},"data":data,"loc":{"start":{"line":101,"column":106},"end":{"line":101,"column":118}}}) : helper)))
    + "\" />\n";
},"32":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"tpid\" id=\"tpid\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tpid") || (depth0 != null ? lookupProperty(depth0,"tpid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tpid","hash":{},"data":data,"loc":{"start":{"line":105,"column":98},"end":{"line":105,"column":106}}}) : helper)))
    + "\" />\n";
},"34":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"resetFor\" id=\"resetFor\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resetFor") || (depth0 != null ? lookupProperty(depth0,"resetFor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resetFor","hash":{},"data":data,"loc":{"start":{"line":109,"column":106},"end":{"line":109,"column":118}}}) : helper)))
    + "\" />\n";
},"36":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"isAdminResetPassword\" id=\"isAdminResetPassword\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"isAdminResetPassword") || (depth0 != null ? lookupProperty(depth0,"isAdminResetPassword") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isAdminResetPassword","hash":{},"data":data,"loc":{"start":{"line":113,"column":130},"end":{"line":113,"column":154}}}) : helper)))
    + "\" />\n";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"companyId\" id=\"companyId\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":117,"column":108},"end":{"line":117,"column":121}}}) : helper)))
    + "\" />\n";
},"40":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"globalCompanyId\" id=\"globalCompanyId\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"globalCompanyId") || (depth0 != null ? lookupProperty(depth0,"globalCompanyId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"globalCompanyId","hash":{},"data":data,"loc":{"start":{"line":121,"column":120},"end":{"line":121,"column":139}}}) : helper)))
    + "\" />\n";
},"42":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"errorMessage\" id=\"errorMessage\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":125,"column":114},"end":{"line":125,"column":130}}}) : helper)))
    + "\" />\n";
},"44":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"market\" id=\"market\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"market") || (depth0 != null ? lookupProperty(depth0,"market") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"market","hash":{},"data":data,"loc":{"start":{"line":129,"column":102},"end":{"line":129,"column":112}}}) : helper)))
    + "\" />\n";
},"46":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"returnUrl\" id=\"returnUrl\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"returnUrl") || (depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"returnUrl","hash":{},"data":data,"loc":{"start":{"line":133,"column":108},"end":{"line":133,"column":121}}}) : helper)))
    + "\" />\n";
},"48":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"passedFromLogin\" id=\"passedFromLogin\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"passedFromLogin") || (depth0 != null ? lookupProperty(depth0,"passedFromLogin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"passedFromLogin","hash":{},"data":data,"loc":{"start":{"line":137,"column":120},"end":{"line":137,"column":139}}}) : helper)))
    + "\" />\n";
},"50":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"ra\" id=\"ra\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ra") || (depth0 != null ? lookupProperty(depth0,"ra") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ra","hash":{},"data":data,"loc":{"start":{"line":141,"column":94},"end":{"line":141,"column":100}}}) : helper)))
    + "\" />\n";
},"52":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"cbl\" id=\"cbl\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cbl") || (depth0 != null ? lookupProperty(depth0,"cbl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cbl","hash":{},"data":data,"loc":{"start":{"line":145,"column":96},"end":{"line":145,"column":103}}}) : helper)))
    + "\" />\n";
},"54":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type=\"hidden\" name=\"setPassword\" id=\"setPassword\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"setPassword") || (depth0 != null ? lookupProperty(depth0,"setPassword") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"setPassword","hash":{},"data":data,"loc":{"start":{"line":149,"column":112},"end":{"line":149,"column":127}}}) : helper)))
    + "\" />\n";
},"56":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='isAdmin' id='isAdmin' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"isAdmin") || (depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isAdmin","hash":{},"data":data,"loc":{"start":{"line":153,"column":104},"end":{"line":153,"column":115}}}) : helper)))
    + "' />\n";
},"58":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='accs' id='accs' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"accs") || (depth0 != null ? lookupProperty(depth0,"accs") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"accs","hash":{},"data":data,"loc":{"start":{"line":157,"column":98},"end":{"line":157,"column":106}}}) : helper)))
    + "' />\n";
},"60":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='processCallbackUrl' id='processCallbackUrl' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"processCallbackUrl") || (depth0 != null ? lookupProperty(depth0,"processCallbackUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"processCallbackUrl","hash":{},"data":data,"loc":{"start":{"line":161,"column":126},"end":{"line":161,"column":148}}}) : helper)))
    + "' />\n";
},"62":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='firstTimeLogin' id='firstTimeLogin' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"firstTimeLogin") || (depth0 != null ? lookupProperty(depth0,"firstTimeLogin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstTimeLogin","hash":{},"data":data,"loc":{"start":{"line":165,"column":118},"end":{"line":165,"column":136}}}) : helper)))
    + "' />\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='clientId' id='clientId' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"client_id") : stack1), depth0))
    + "' />\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='redirectUri' id='redirectUri' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"redirect_uri") : stack1), depth0))
    + "' />\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='responseType' id='responseType' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"response_type") : stack1), depth0))
    + "' />\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='scope' id='scope' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"scope") : stack1), depth0))
    + "' />\n";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='state' id='state' value='"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"oAuthParameters") : stack1)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "' />\n";
},"74":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='code' id='code' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reset-pass-data") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "' />\n";
},"76":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='resetPswdViaMFA' id='resetPswdViaMFA' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resetPswdViaMFA") || (depth0 != null ? lookupProperty(depth0,"resetPswdViaMFA") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resetPswdViaMFA","hash":{},"data":data,"loc":{"start":{"line":187,"column":120},"end":{"line":187,"column":139}}}) : helper)))
    + "' />\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <div class=\"row pswd-policy\">\n                                                        <div class=\"col col-12\">\n                                                            <p id=\"pswd-policy-heading\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_policies",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":202,"column":88},"end":{"line":202,"column":147}}}))
    + "</p>\n                                                        </div>\n                                                       </div>\n                                                        <div class=\"mobile-policy-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0)) != null ? lookupProperty(stack1,"passwordHistorySize") : stack1),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":206,"column":60},"end":{"line":220,"column":67}}})) != null ? stack1 : "")
    + "\n                                                            <p id=\"password-min-size\" class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"passwordLength") : stack1),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":222,"column":104},"end":{"line":222,"column":166}}})) != null ? stack1 : "")
    + "\">\n                                                                <span class=\"reset-check "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"passwordLength") : stack1),{"name":"if","hash":{},"fn":container.program(83, data, 0),"inverse":container.program(85, data, 0),"data":data,"loc":{"start":{"line":223,"column":89},"end":{"line":223,"column":163}}})) != null ? stack1 : "")
    + "\">\n                                                                  <i class=\"icon icon-success\"></i>\n                                                                  <i class=\"icon icon-close\"></i>\n                                                                </span>\n                                                                                                            <span>\n                                                                "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_password_minimum_size",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":228,"column":64},"end":{"line":228,"column":127}}}))
    + ": "
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0)) != null ? lookupProperty(stack1,"minPasswordLength") : stack1), depth0))
    + "\n                                                                </span>\n                                                            </p>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0)) != null ? lookupProperty(stack1,"useNumbers") : stack1),{"name":"if","hash":{},"fn":container.program(87, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":233,"column":60},"end":{"line":243,"column":67}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0)) != null ? lookupProperty(stack1,"useSpecialChar") : stack1),{"name":"if","hash":{},"fn":container.program(90, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":244,"column":60},"end":{"line":256,"column":67}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0)) != null ? lookupProperty(stack1,"useUpperCase") : stack1),{"name":"if","hash":{},"fn":container.program(95, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":257,"column":60},"end":{"line":268,"column":67}}})) != null ? stack1 : "")
    + "\n                                                        </div>\n\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"passwordHistoryPlacementTop") : depth0),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":64},"end":{"line":219,"column":71}}})) != null ? stack1 : "");
},"80":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                    <div id=\"password-history-size\" class=\"secondary pwd-history-info "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"passwordHistory") : stack1),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":134},"end":{"line":209,"column":133}}})) != null ? stack1 : "")
    + "\">\n\n                                                                        <i class=\"icon icon-info\"></i>\n\n                                                                        <div class=\"pwd-history-info-message\">\n\n                                                                            <p>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"localizedPasswordHistoryMessageKey") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"policyDetails") : depth0)) != null ? lookupProperty(stack1,"passwordHistorySize") : stack1),{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":215,"column":79},"end":{"line":216,"column":104}}}))
    + "</p>\n                                                                        </div>\n                                                                    </div>\n";
},"81":function(container,depth0,helpers,partials,data) {
    return "alert-text-error";
},"83":function(container,depth0,helpers,partials,data) {
    return "error ";
},"85":function(container,depth0,helpers,partials,data) {
    return "defaultSuccess";
},"87":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                <p id=\"password-use-num\" class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"numerals") : stack1),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":234,"column":107},"end":{"line":234,"column":163}}})) != null ? stack1 : "")
    + "\">\n                                                                   <span class=\"reset-check defaultSuccess"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"numerals") : stack1),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":106},"end":{"line":235,"column":151}}})) != null ? stack1 : "")
    + "\">\n                                                                     <i class=\"icon icon-success\"></i>\n                                                                     <i class=\"icon icon-close\"></i>\n                                                                   </span>\n                                                                    <span>\n                                                                        "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"resetPassword_password_use_num_policy1",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":240,"column":72},"end":{"line":240,"column":138}}}))
    + "\n                                                                    </span>\n                                                                </p>\n";
},"88":function(container,depth0,helpers,partials,data) {
    return "error";
},"90":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                <p id=\"password-special-char\"\n                                                                   class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"specialChars") : stack1),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":246,"column":85},"end":{"line":246,"column":145}}})) != null ? stack1 : "")
    + "\">\n                                                                        <span class=\"reset-check "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"specialChars") : stack1),{"name":"if","hash":{},"fn":container.program(83, data, 0),"inverse":container.program(85, data, 0),"data":data,"loc":{"start":{"line":247,"column":97},"end":{"line":247,"column":169}}})) != null ? stack1 : "")
    + "\">\n                                                                          <i class=\"icon icon-success\"></i>\n                                                                          <i class=\"icon icon-close\"></i>\n                                                                        </span>\n                                                                                                                        <span>\n                                                                        "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"resetPassword_password_special_char_policy1",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":252,"column":72},"end":{"line":252,"column":143}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"specialCharactersList") : depth0),{"name":"if","hash":{},"fn":container.program(91, data, 0),"inverse":container.program(93, data, 0),"data":data,"loc":{"start":{"line":252,"column":144},"end":{"line":253,"column":152}}})) != null ? stack1 : "")
    + "\n                                                                        </span>\n                                                                </p>\n";
},"91":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"specialCharactersList") || (depth0 != null ? lookupProperty(depth0,"specialCharactersList") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"specialCharactersList","hash":{},"data":data,"loc":{"start":{"line":252,"column":173},"end":{"line":252,"column":198}}}) : helper)));
},"93":function(container,depth0,helpers,partials,data) {
    return "\n                                                                                                                            ~!@#$%*-&{}?/:+=()[]_";
},"95":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                <p id=\"password-use-uppercase\"\n                                                                   class=\"secondary  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"upperCase") : stack1),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":259,"column":85},"end":{"line":259,"column":142}}})) != null ? stack1 : "")
    + "\">\n               <span class=\"reset-check defaultSuccess"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"policyViolations") : depth0)) != null ? lookupProperty(stack1,"upperCase") : stack1),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":260,"column":54},"end":{"line":260,"column":100}}})) != null ? stack1 : "")
    + "\">\n                 <i class=\"icon icon-success\"></i>\n                 <i class=\"icon icon-close\"></i>\n               </span>\n                                                                    <span>\n                                                                        "
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"resetPassword_password_upper_case_policy1",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":265,"column":72},"end":{"line":265,"column":141}}}))
    + "\n                                                                    </span>\n                                                                </p>\n";
},"97":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <br/>\n                            <p class=\"reset-message\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"statusMessage") || (depth0 != null ? lookupProperty(depth0,"statusMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusMessage","hash":{},"data":data,"loc":{"start":{"line":312,"column":32},"end":{"line":312,"column":49}}}) : helper)))
    + "\n                                <br/>\n                                <a id=\"redirectLink\" name=\"redirectLink\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"redirectUrl") || (depth0 != null ? lookupProperty(depth0,"redirectUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"redirectUrl","hash":{},"data":data,"loc":{"start":{"line":314,"column":79},"end":{"line":314,"column":94}}}) : helper)))
    + "\">\n                                    "
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"resetPassword_continue",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":315,"column":36},"end":{"line":315,"column":86}}}))
    + "\n                                </a>\n                            </p>\n";
},"99":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"label reset-password-message\"><font color='red' size=\"1px\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"authorizationErrorMessage") || (depth0 != null ? lookupProperty(depth0,"authorizationErrorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"authorizationErrorMessage","hash":{},"data":data,"loc":{"start":{"line":321,"column":96},"end":{"line":321,"column":125}}}) : helper)))
    + "<br/><br/></font> </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"content\" class=\"site-content narrow-content\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"passwordExpired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "\n\n    <div class=\"row\">\n        <div class=\"col col-12\">\n            <div class=\"box\">\n                <div class=\"box-padded\">\n\n                    <div class=\"row\">\n                        <div class=\"col col-12 align-center\">\n\n                            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resetPswdViaMFA") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":45,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n\n\n\n                <div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"authorizationErrorMessage") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":319,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authorizationErrorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(99, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":320,"column":20},"end":{"line":322,"column":27}}})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n\n</div>\n";
},"usePartial":true,"useData":true}));