Handlebars.registerPartial('partials/account-suggestion-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	\n			<div id=\"login-only-suggestion-modal\" class=\"center\">\n				<form onsubmit=\"return false\">\n					<h3>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_enterPassword",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":4,"column":9},"end":{"line":4,"column":54}}}))
    + "</h3>\n					<p class=\"modal-heading\">"
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + "</p>\n					\n					<div class=\"input-group inline-block-div\">\n						<input id=\"modalPassword\" class=\"login-only-password-field\" type=\"password\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_password",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":8,"column":95},"end":{"line":8,"column":135}}}))
    + "\" value=\"\" name=\"modalPassword\"></input>\n						<input type = \"hidden\" id = \"accountSuggestionUserId\" value = "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"userId") : depth0), depth0))
    + " ></input>\n						<input type = \"hidden\" id = \"accountSuggestionAccountId\" value = "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"accountId") : depth0), depth0))
    + " ></input>\n						<input type = \"hidden\" id = \"accountSuggestionLogin\" value = "
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"login") : depth0), depth0))
    + " ></input>\n					</div>\n\n					<p id=\"modalPasswordIncorrect\" class = \"passwordIncorrectModal\"></p>\n					\n					<button class=\"btn btn-action btn-med    \" onClick = \"mergeFunction(this)\" id=add_account type=\"submit\">\n		 				<span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_addAccounts",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":17,"column":31},"end":{"line":17,"column":74}}}))
    + "</span>\n		 			</button>\n	 			</form>\n	 		</div>\n	 	\n\n";
},"useData":true}));