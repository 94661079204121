// creating error type and omniture mapping
EG.Auth = EG.Auth || {};
EG.Auth.errorOmnitureMapping = {
		"invalidPassword": "Identity Management Login Failure Due To Wrong Password",
		"oldLogonUsed": "Identity Management Login Failure - Old Logon Used",
		"passwordLimitExceeded": "Identity Management Login Failure - Wrong Password Limit Exceeded",
		"userBlocked": "Identity Management Login Failure - User Blocked",
		"sessionExpired": "Identity Management Login Error - Session Timeout",
		"resetPasswordForbidden": "Identity Management Password Reset Error - Not allowed via email",
		"invalidEmail": "Identity Management Password Reset Error - Invalid email ID",
    	"invalidResetPasswordRequest": "Identity Management Password Reset Error - Invalid Request",
		"tempTokenExpired": "Identity Management Password Reset Error - Link Expired",
		"captchaFailure": "Identity Management Login Failure - Captcha validation failed",
		"noAccountFound": "Identity Management Password Reset Error - Account not found",
		"emailDisambig": "Identity Management Login Failure - Invalid Attempt Disambiguation Using Email",
		"invalidUser": "Identity Management Login Failure - Incorrect Username",
		"nonUniqueEmail": "Identity Management Password Reset Error - Admin non unique email",
		"invalidSecurityToken" : "Identity Management Login Failure - Invalid Security Token",
		"ssoidperror" : "Identity Management Login Failure - (SSO) Incorrect Configuration at IDP",
		"forgotPasswordExhausted":"Identity Management Password Reset Error - Password Reset Limit Exhausted",
		"invalidLogonType":"Identity Management Login Failure - (SSO) User trying standard login",
	    "switchedToCorrectLogonType":"Identity Management Login Failure - (SSO) User redirected to SSO flow",
	    "userVanityCorrected":"Identity Management Login Failure - (SSO) User vanity corrected",
	    "ssoIdNotFound":"Identity Management Login Failure - (SSO) Single Sign On Id not found",
	    "riskServiceRejectResponse": "Identity Management Login Failure Due To Risk Service Reject",
	    "invalidLoginTypeForReset": "Identity Management Password Reset Error -Invalid login type for password reset",
	    "tneLogonError": "Identity Management Login - Invalid attempt (TnE)",
	    "guestLogonError": "Identity Management Login - Invalid attempt (Guest)",
	    "adminLogonError": "Identity Management Login - Invalid attempt (Internal)",
	    "tnePasswordResetError": "Identity Management Forgot Password - Invalid attempt (TnE)",
	    "guestPasswordResetError": "Identity Management Forgot Password - Invalid attempt (Guest)",
	    "adminPasswordResetError": "Identity Management Forgot Password - Invalid attempt (Internal)",
		"resetPasswordForDisabledCompany": "Identity Management Forgot Password - Company Disabled",
		"resetPasswordForDeactivatedUser": "Identity Management Forgot Password - User Deactivated",
    "noLoginResetPassword": "Identity Management Forget Password - No login user",
    "noLoginLogon":"Identity Management Login Failure - No login user"
};

EG.Auth.alertMsgOmnitureMapping = {
    "passwordExpiryEmailTriggered": "Identity Management Password Expiry - user password expiry email triggered"
};

// Added for the prefetcher module in UITK; it allow us to pre-fetch assets for another page in the background as lowest priority.
EG.prefetchApps = ["home"];


//functions for globalLoginPage

//calls idp login
function callIdpLogin(clickEvent) {
    if(clickEvent) {
        sendClickAnalyticsData(this, "a", clickEvent);
    }
	document.forms[0].method = 'get';
	document.forms[0].action = './accessToSSOLogin';
	document.forms[0].submit();
}

// calls Egencia login
function callEgenciaLogin(clickEvent) {
    if(clickEvent) {
        sendClickAnalyticsData(this, "a", clickEvent);
    }
	document.forms[0].method = 'get';
	document.forms[0].action = "./accessEgenciaLogin";
	document.forms[0].submit();
}

function getUserIdOrPassword() {
    var host = window.location.hostname;
    var hostName = host.substring(host.lastIndexOf(".", host.lastIndexOf(".") - 1) + 1);
    var isLocalHost = hostName == "localhost" ? true : false;
	sendClickAnalyticsData(this, "a", "Auth.Login.ForgotUsernamePassword.Link.Clicked");
    document.forms[0].password.value = '';
	document.forms[0].method = 'post';
	document.forms[0].action = './forgot/password';
    if(isLocalHost) {
        document.forms[0].action += "?isLocalhost=true";
    }
	document.forms[0].submit();
}
var getAcceptableSecurityTokenExpiryWindow = function(securityTokenValidityServer, securityTokenValidityMinimum) {
    var securityTokenValidity = securityTokenValidityServer;

    var currentTime = new Date().getTime();
    var securityTokenValidityWindow = securityTokenValidityServer - currentTime;

    if (securityTokenValidityWindow < securityTokenValidityMinimum) {
        securityTokenValidity =  currentTime + securityTokenValidityMinimum;
    }

    return securityTokenValidity;
}

var reloadBeforeExpiryOfSToken = function (securityTokenExpiry, securityTokenUserInactivityRefreshWindow) {
    var timerId;
    var userNameElement = document.getElementById("userName");

    // setup user activity events to restart the user inactivity tracking timer
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer;
    document.onmousewheel = resetTimer;
    document.onkeypress = resetTimer;
    document.ontouchmove = resetTimer;

    startTimer();

    // Fill in the userName value which was typed by user earlier
    var userNameValue = sessionStorage.getItem("userName");
    if(userNameValue){
        userNameElement.value = userNameValue;
        sessionStorage.removeItem("userName");
	}

    // Move focus on to the element which was in focus earlier
    var activeElementName = sessionStorage.getItem("activeElement");
    if(activeElementName){
        var elements = document.getElementsByName(activeElementName);
        if(elements.length > 0) {
            elements[0].focus();
        }
        sessionStorage.removeItem("activeElement");
    }

    function refreshToken() {
        if(userNameElement) {
            sessionStorage.setItem("userName", userNameElement.value);
        }
        if(document.activeElement) {
            sessionStorage.setItem("activeElement", document.activeElement.name);
        }

        window.location.reload();
    }

    function startTimer() {
        var currentDate = new Date();
        var currentTime = currentDate.getTime();

        var expiryValue = securityTokenExpiry - currentTime - 5000; // Refresh 5 seconds before token expiry
        // If the user is active in vicinity of timer expiry, don't setup the page to be refreshed
        if(expiryValue > securityTokenUserInactivityRefreshWindow) {
            timerId = setTimeout(refreshToken, expiryValue)
        }
    }

    function resetTimer(e) {
        clearTimeout(timerId);
        startTimer();
    }

};

// To refresh the page once after selecting the Langauge/locale
// function refreshPage(){
// 	var locale = document.forms[0].languages.value;
// 	if (document.forms[0].locale.value != locale) {
// 		document.forms[0].locale.value = locale;
// 		document.forms[0].method = 'post';
// 		document.forms[0].action = "./refresh";
// 		document.forms[0].submit();
// 	}
// }

function refreshEgenciaPage(){
	var locale = document.forms[0].languages.value;
	if (document.forms[0].locale.value != locale) {
		document.forms[0].locale.value = locale;
		document.forms[0].method = 'post';
		document.forms[0].action = "./refreshEgencia";
		sendClickAnalyticsData(this, "a", "auth.language.selector");
		document.forms[0].submit();
	}
}

// ForgotPassword page functions

function redirectToGlobalLogin() {
	document.forms[0].method = 'get';
	document.forms[0].action = '../../home';
	document.forms[0].submit();
}

//To refresh the page once after selecting the Langauge/locale
function refreshPage(){
	var locale = document.forms[0].languages.value;
	if (document.forms[0].locale.value != locale) {
		document.forms[0].locale.value = locale;
		document.forms[0].method = 'post';
		var element = document.getElementById('passedFromLogin');
		if (typeof (element) != 'undefined' && element != null) {
			document.forms[0].action = '../v1/reset/password/refresh';
		} else {
			document.forms[0].action = './refresh';
		}
		document.forms[0].submit();
	}
}

// Welcome page helpers
function updateProfileLanguage(localeSelected) {

	if (document.forms[0].locale.value != localeSelected) {
		document.forms[0].locale.value = localeSelected;
	}
	document.forms[0].method = 'post';
	document.forms[0].action = './profileLanguageUpdate';
	document.forms[0].submit();

}

function redirectToSite() {

	document.forms[0].method = 'post';
	document.forms[0].action = './redirectToSite';
	document.forms[0].submit();

}

// for login page. This function accepts the errorType and returns the omniture tag corresponding to it
function getOmnitureTagFromErrorType(errorType) {

	if (EG.Auth.errorOmnitureMapping[errorType]) {
		return EG.Auth.errorOmnitureMapping[errorType];
	}

	return "Identity Management Failure";
}

function getOmnitureTagFromAlertMessageType(alertMessageType) {

    if (EG.Auth.alertMsgOmnitureMapping[alertMessageType]) {
        return EG.Auth.alertMsgOmnitureMapping[alertMessageType];
    }

    return "Identity Management Alert Message";
}

function openManageAccountModal(username, password) {
	sendPageInteraction(uitk, "Auth.DisambiguationLogin.ManageAccount");
	if (EG.disambigAccount.esiAccounts != null) {
		if  (EG.disambigAccount.esiAccounts.length == 1) {
			openManageAccountPage(username, password,EG.disambigAccount.isSSORequest, EG.disambigAccount.esiAccounts[0], true);
		} else if (EG.disambigAccount.esiAccounts.length > 1) {
			document.getElementById("esi-account-modal").click();
		}
	}
}

function openManageAccountPage(username, password, isSsoRequest) {
	sendClickAnalyticsData(this, "a", "Auth.Disambiguation.Link.Button");

	var index = $('input[name=esi-account-group]:checked').val();
	var gpid, tpid, tuid, accountId, disambigAccountIds, multiplePrimaryAccounts, nonLinkedAccounts, fromDisambig, returnUrl;
	gpid = (arguments[3] && arguments[3].gpid) || document.getElementById("esi-gpid" + index).value;
	tpid = (arguments[3] && arguments[3].tpid) || document.getElementById("esi-tpid" + index).value;
	tuid = (arguments[3] && arguments[3].tuid) || document.getElementById("esi-tuid" + index).value;
	accountId = (arguments[3] && arguments[3].accountId) || document.getElementById("esi-account-id" + index).value;
	disambigAccountIds = (arguments[3] && arguments[3].disambigAccountIds) || document.getElementById("disambigAccountIds").value;
	multiplePrimaryAccounts = (arguments[3] && arguments[3].multiplePrimaryAccounts) || document.getElementById("multiplePrimaryAccounts").value;
	nonLinkedAccounts = (arguments[3] && arguments[3].nonLinkedAccounts) || document.getElementById("nonLinkedAccounts").value;
	fromDisambig = arguments[4] || document.getElementById("fromDisambig").value;
	returnUrl = window.location.protocol + "//" + window.location.hostname + "/auth/v1/manage-accounts";

	document.getElementById('companyId').value = gpid;
	document.getElementById('disambigTpid').value = tpid;
	document.getElementById('tuid').value = tuid;
	document.getElementById('accountId').value = accountId;
	document.getElementById("returnUrl").value=btoa(returnUrl);

	if(isSsoRequest) {

		var form = document.createElement("form");
	    form.method = "POST";
	    form.action = "./switchProfile";

	    form.appendChild(getInputElement("fromDisambig", fromDisambig));
	    form.appendChild(getInputElement("tuid", tuid));
	    form.appendChild(getInputElement("callback-url", returnUrl));
	    form.appendChild(getInputElement("accountId", accountId));
	    form.appendChild(getInputElement("disambigAccountIds", disambigAccountIds));
	    form.appendChild(getInputElement("multiplePrimaryAccounts", multiplePrimaryAccounts));
	    form.appendChild(getInputElement("nonLinkedAccounts", nonLinkedAccounts));

	    document.body.appendChild(form)
	    form.submit();
	} else {
		document.getElementById("returnUrl").value=btoa(window.location.protocol + "//" + window.location.hostname + "/auth/v1/manage-accounts");
		document.getElementById('userName').value = username;
		document.getElementById('password').value = password;
		document.forms[0].submit();
	}
}

function getInputElement(name, value) {

	var element = document.createElement("input");
	element.name=name;
    element.value=value;
    element.type='hidden';
    return element;
}

function submitAdminPasswordUpdate() {

	document.forms[0].method = 'post';
	document.forms[0].action = window.location.protocol + "//" + window.location.hostname + "/auth/v1/update/password/admin";
	document.forms[0].submit();
}
function initializeAndDisplayGamAd(window, gamAdvertisementPath) {
    const ad = {
        height : 250,
        width : 300,
        path : gamAdvertisementPath,
        id: 'gam-ad'
    };
    uitk.utils.gamAdInvocation([ad]);
}

function redirectToManageAccount(context, redirectionUrl) {
	sendClickAnalyticsData(context, "a", "Auth.ESIPopup.Confirm");
	if(redirectionUrl) {
		redirectUserToGivenPage(redirectionUrl);
	} else {
		redirectUserToGivenPage(window.location.protocol + "//" + window.location.hostname + "/auth/v1/manage-accounts");
	}
}

function cancelRedirectToManageAccount(context, redirectionUrl) {
	sendClickAnalyticsData(context, "a", "Auth.ESIPopUp.Cancel");
	// Redirect to manage-account cancelled. Send user to the original redirection url
	redirectUserToGivenPage(redirectionUrl);
}

function redirectUserToGivenPage(redirectionUrl) {
    window.location.href = redirectionUrl;
}

function redirectUserToGivenTab(redirectionUrl) {
    window.open(redirectionUrl);
}

function postLoginMsgModalRedirectingUser(redirectionUrl,companyId) {
	uitk.logger._log('info', 'Post Login Msg Modal Shown and User has Acknowledged for companyId: '+companyId);
	redirectUserToGivenPage(redirectionUrl);
}


function selectCurrentLocale() {
	if (EG.Auth.currentLocale && EG.Auth.availableLanguages && EG.Auth.availableLanguages[EG.Auth.currentLocale]) {
		$("#languages").val(EG.Auth.currentLocale);
		$("#language-switcher-title").html(EG.Auth.availableLanguages[EG.Auth.currentLocale])
	}
}


function changeLanguageAndRefreshEgenciaPageNew(event) {
    var locale = $(event.target.closest("a")).data("locale");
    if(!locale){
        locale =  $(event.target.find("a")).data("locale");
    }
    var market =  document.forms[0].market && document.forms[0].market.value;
    var localeArray  = locale.split("_");
    localeArray[1]= market;
    var newLocale = localeArray.join("_");
    var pageName= document.forms[0].pageName.value ,action="./refreshEgencia";
    switch (pageName) {
        case 'global-login-page':
        case 'reset-password-page':
            action = './refresh';
            break;
    }
    if (EG.Auth.currentLocale != newLocale) {
        uitk.createCookie("egencia_locale", newLocale);
        document.forms[0].locale.value = newLocale.replace("_", "-");
        document.forms[0].method = 'post';
        document.forms[0].action = action;
        sendClickAnalyticsData(this, "a", "auth.language.selector");
        document.forms[0].submit();
    }
}

function changeLanguageAndRefreshEgenciaPage(locale) {
	if (EG.Auth.currentLocale != locale) {
		uitk.createCookie("egencia_locale", locale.replace("-", "_"));
		document.forms[0].locale.value = locale;
		document.forms[0].method = 'post';
		document.forms[0].action = "./refreshEgencia"   ;
		sendClickAnalyticsData(this, "a", "auth.language.selector");
		document.forms[0].submit();
	}
}

function checkCapsLock(e, eventFromBody) {
    var isCapsLockOn = false;
    var warningMsgField = $(".caps-alert-warn");
    if (e.getModifierState && typeof (e.getModifierState) === "function") {
        if (e.getModifierState("CapsLock")) {
            isCapsLockOn = true;
            if (!eventFromBody) {
                warningMsgField.removeClass("displayNone");
            }

        } else {
            warningMsgField.addClass("displayNone");
        }
    }
    e.stopPropagation();
    return isCapsLockOn;
}


function checkCapsLockOnFocus(isCapsLockOn){
    var warningMsgField = $(".caps-alert-warn");
    if(isCapsLockOn){
        warningMsgField.removeClass("displayNone");
    }
    else {
        warningMsgField.addClass("displayNone");
    }
}

function logJsErrors() {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        var string = msg.toLowerCase();
        var substring = "script error";
        if (stringNotEmpty(string) && string.indexOf(substring) > -1) {
            uitk.logger._log("error", string);
        } else {
            var message = [
                'Message: ' + msg,
                'URL: ' + url,
                'Line: ' + lineNo,
                'Column: ' + columnNo,
                'Error object: ' + JSON.stringify(error)
            ].join(' - ');
            uitk.logger._log("error", message);
        }
        return false;
    };
}
function changeLanguageAndRefreshPage(locale){
	if (EG.Auth.currentLocale != locale) {
        $("form[id='reset-password-form']").find("input").forEach(function(element,index){
            if(["confirmPassword","password"].indexOf(element && element.id) >=0){
                element.value="";
            }
        });
        uitk.createCookie("egencia_locale", locale.replace("-", "_"));
		document.forms[0].locale.value = locale;
		document.forms[0].method = 'post';
		document.forms[0].action = "./refresh";
        sendClickAnalyticsData(this, "a", "auth.language.selector");
		document.forms[0].submit();
	}
}

function stringNotEmpty(string) {
	return string && string.length && string.length>0;
}

function overRideCommentTag() {
    if(document.readyState !== 'complete') {
        document.addEventListener('DOMContentLoaded', function(){
            var commentElement=document.getElementById("PageId");
            if(commentElement){
                commentElement.parentNode.removeChild(commentElement);
            }
            $('head').append('<comment id="PageId" title="AUTH_WEBAPP_LOGIN"></comment>');
        });
    }
}

function createHelpCenterDrawer(url,contextualHelpLink,dontBindClickEvent) {

    var $body = $('body'),
        $html = $('html'),
        modalBackgroundClass = 'modal-background',
        contextualHelpPanel = uitk.modal.create({
            modalId: 'contextual-help',
            content: '<iframe class="contextual-help-content" src=' + url + '></iframe>',
            panel: true,
            panelSize: 'small',
            title: false,
            footer: false
        }),
        scrollableClass = 'scrollable',
        closeModalMessage = 'close-modal';
        $('.' + contextualHelpLink).unbind("click");
    	if(!dontBindClickEvent){
            bindClickEventOnHelp();
            // Click event for iFrame link, bind here to avoid loading iFrame content on page load
            function bindClickEventOnHelp(){
                $('.' + contextualHelpLink).on('click', function (e) {
                    e.preventDefault();
                    contextualHelpPanel.open();
                    $('.' + modalBackgroundClass).remove();
                    $html.addClass(scrollableClass);
                    sendClickAnalyticsData(this, "a", "auth.helpcenter.panel.defaultPage");
                });
            }
		}
    // Listener created for iFrame component to close the modal
    window.addEventListener('message', function (e) {
        if (e.data && e.data.message === closeModalMessage && isUrlSameOrigin(e.origin, EG.baseUrl)) {
            contextualHelpPanel.close('close-button', true, e);
            $html.removeClass(scrollableClass);
        }
    });

    function isUrlSameOrigin(sourceUrl, destinationUrl) {
        var source = document.createElement('a'),
            destination = document.createElement('a');
        source.href = sourceUrl;
        destination.href = destinationUrl;
        return source.origin === destination.origin;
    }
    return contextualHelpPanel;

}

function hideHelpLink() {
    var helpLink= $("ul a[id='help']");
    if(helpLink && helpLink.length >0){
        helpLink.addClass("displayNone");
    }
}

function isLocalStorgeExist() {
    if(window.localStorage !=undefined){
        return true;
    }
    return false;
}

function getAuthNameSpaceInLocalStorge() {
    var storage= window.localStorage;
    if(!storage.getItem("auth")) {
        initializeAuthNameSpace(storage);
    }
    return JSON.parse(storage.getItem("auth"));
}

function initializeAuthNameSpace(storage) {
   var authNameSpace = {
        'SSO_Users': {}
    };
    storage.setItem("auth",JSON.stringify(authNameSpace));
}

function getSSOUsersListFromLocalStorage(){
    var ssoUsers;
    var authNameSpace=getAuthNameSpaceInLocalStorge();
    if(authNameSpace){
        ssoUsers=authNameSpace["SSO_Users"];
    }
    return ssoUsers;
}

function  setAuthNameSpaceInLocalStorage(authNameSpace) {
    window.localStorage.setItem("auth",JSON.stringify(authNameSpace));
}

function setSSOUserInLocalStorage(ssoUserName,userData){
    var ssoUsers = getSSOUsersListFromLocalStorage();
    if(ssoUserName && userData){
    	userData['lastUpdatedAt']= new Date().getTime();
        ssoUsers[ssoUserName] = userData;
        var authNameSpace= getAuthNameSpaceInLocalStorge();
        authNameSpace['SSO_Users'] = ssoUsers;
        setAuthNameSpaceInLocalStorage(authNameSpace);
    }
}

function getDomainFromUrl(oldUrl) {
    var location = document.createElement('a');
    location.href = oldUrl;
    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.
    if (location.host === '') {
        location.href = location.href;
    }
    return location.hostname;
}

function replaceDomainInUrl(oldUrl, domain){
    var location = document.createElement('a');
    location.href = oldUrl;
    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.
    if (location.host === '') {
        location.href = location.href;
    }
    location.hostname = domain;
    return location.href;
}

function redirectToUserProfilePage(context, redirectionUrl,homePageUrl){
    sendClickAnalyticsData(context, "a", "Auth.MobileNumber.Update");
    redirectUserToGivenTab(redirectionUrl);
    redirectUserToGivenPage(homePageUrl);
}

function redirectToHomePage(context,homePageUrl){
    sendClickAnalyticsData(context, "a", "Auth.MobileNumber.Cancel");
    redirectUserToGivenPage(homePageUrl);
}

function updateUserMobileNumber(context, userId, homePageUrl) {
    var errorMsg,mobileNumber,mobileNumberEle,countyCode,phoneNumberRequest;
    var telContainer = $("#mobile-phone").closest('.input-group').parent();
    if(telContainer.hasClass("invalid")){
        return;
    }
    mobileNumberEle = $("#mobile-phone");
    countyCode = $(".iti__selected-dial-code") && $(".iti__selected-dial-code")[0] &&  $(".iti__selected-dial-code")[0].innerHTML;
    mobileNumber = mobileNumberEle.val().trim();
    if(mobileNumber){
        mobileNumber= mobileNumberEle.val();
        mobileNumber = mobileNumber.replace(/\D/g, '');
        sendClickAnalyticsData(context, "a", "Auth.MobileNumber.Update");
        phoneNumberRequest = {type_code: 10, number: mobileNumber,country_prefix:countyCode };
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: "/auth/v1/" + userId + "/contact",
            data: JSON.stringify(phoneNumberRequest),
        });
        redirectUserToGivenPage(homePageUrl);
    }
    else{
        errorMsg = I18n.t('mobile_mandatory_validation');
        telContainer.addClass('invalid');
        telContainer
            .find('.input-message')
            .html(errorMsg)
            .removeClass('hidden');
    }
}


function externalCancelDateOfBirthRedirection(context,redirectionPageUrl){
    $("#save").addClass("disabled");
    $("#cancel").addClass("disabled");
    sendClickAnalyticsData(this, "a", "Auth.DateOfBirth.Popin.Close.External.Clicked");
    redirectUserToGivenPage(redirectionPageUrl);
}
function updateDateOfBirth(context,userId, redirectionPageUrl){

    if(!$("#save").hasClass("disabled")){
        var date,month,year, dateOfBirthRequest;

        sendClickAnalyticsData(context, "a", "Auth.DateOfBirth.Save.Button.Clicked");

        $("#error-message").addClass("displayNone");

        const monthRange=moment.months();

        date = $("#date").val();
        month = monthRange.indexOf( $("#month").val())+1;
        year = $("#year").val();

        if(isValidDateOfBirth(date,$("#month").val(),year)){

            $("#save").addClass("disabled");

            if (date < 10) date = '0' + date;
            if (month < 10) month = '0' + month;

            dateOfBirth = year + '-' + month + '-' + date;
            if(dateOfBirth){
                dateOfBirthRequest = [{"op": "add","path":"/date_of_birth","value":dateOfBirth
                }];
                $.ajax({
                    type:'PATCH',
                    contentType: 'application/json-patch+json',
                    url: "/user-service/v2/users/" + userId +"/info",
                    data: JSON.stringify(dateOfBirthRequest),
                    success: function success(data) {
                        redirectUserToGivenPage(redirectionPageUrl);
                    },

                    error: function (req,status,err) {

                        uitk.logger._log("error","status "+ status + "error "+req.responseText);
                        $("#error-message").text(I18n.t('something_went_wrong'));
                        $("#error-message").removeClass("displayNone");
                        $("#save").addClass("disabled");

                    }
                });
            }
        }else {
            $("#error-message").text(I18n.t('update_invalid_date_of_birth_message'));
            $("#error-message").removeClass("displayNone");
        }
    }
}

function isValidDateOfBirth(date, month, year){
if( !(date) || !(month) || !(year)){
    return false;
}
return true;
}
