Handlebars.registerPartial('partials/post-login-msg-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<center>\n    <h2 style=\"color:#e75c5c\"><i class=\"icon icon-erroralt\" style=\"margin-right:10px\"></i>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"post_login_msg_modal_header",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":2,"column":90},"end":{"line":2,"column":145}}}))
    + "</h2>\n    <p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"post_login_msg_modal_description",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":3,"column":7},"end":{"line":3,"column":67}}}))
    + "</p>\n\n    <footer class=\"modal-footer cf\">\n        <div class=\"modal-footer-btns\">\n            <button id=\"post-login-msg-modal-confirm-button\" class=\"btn btn-utility btn-med\" onclick=\"postLoginMsgModalRedirectingUser('"
    + alias3(((helper = (helper = lookupProperty(helpers,"redirectUrl") || (depth0 != null ? lookupProperty(depth0,"redirectUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"redirectUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":136},"end":{"line":7,"column":151}}}) : helper)))
    + "','"
    + alias3(((helper = (helper = lookupProperty(helpers,"companyId") || (depth0 != null ? lookupProperty(depth0,"companyId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"companyId","hash":{},"data":data,"loc":{"start":{"line":7,"column":154},"end":{"line":7,"column":167}}}) : helper)))
    + "');\">\n                <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"post_login_msg_modal_confirm",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":96}}}))
    + "</span>\n            </button>\n        </div>\n    </footer>\n</center>";
},"useData":true}));