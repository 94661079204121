Handlebars.registerPartial('sso-details-search', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"emailCharsRegex") || (depth0 != null ? lookupProperty(depth0,"emailCharsRegex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emailCharsRegex","hash":{},"data":data,"loc":{"start":{"line":30,"column":70},"end":{"line":30,"column":91}}}) : helper))) != null ? stack1 : "")
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "\"[_A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\\\.[_A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type='hidden' name='sid' id='sid' value='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"securityToken") || (depth0 != null ? lookupProperty(depth0,"securityToken") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"securityToken","hash":{},"data":data,"loc":{"start":{"line":46,"column":60},"end":{"line":46,"column":77}}}) : helper)))
    + "' />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n    <head>\n        <meta content=IE=edge http-equiv=X-UA-Compatible>\n     	<link rel='dns-prefetch' href='//app-sjh.marketo.com' />\n     	<script type='text/javascript' src='//app-sjh.marketo.com/js/forms2/js/forms2.min.js?x63682'></script>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header"),depth0,{"name":"auth-header","data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n         <title>\n            "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"egenciaLogin.title",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":58}}}))
    + " | Egencia\n        </title>\n\n        <meta name=\"description\" content=\"\">\n        <meta name=\"author\" content=\"\">\n        <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/captcha-scripts"),depth0,{"name":"partials/captcha-scripts","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </head>\n\n    <body id=\"login-page\" class=\"wrap cf l-singlecol reset-password-page\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"auth-header-scripts"),depth0,{"name":"auth-header-scripts","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"auth-extended"},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":88}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"sso-details"},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":86}}})) != null ? stack1 : "")
    + "\n        <script language=\"JavaScript\" type=\"text/JavaScript\">\n\n            EG.Auth = EG.Auth || {};\n                    EG.Auth.emailCharsRegex = "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"emailCharsRegex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":30,"column":46},"end":{"line":30,"column":270}}})) != null ? stack1 : "")
    + ";\n\n            $(document).ready(function(){\n                EG.Auth.Presenters.SsoDetails();\n                sendPageNameToOmniture(uitk, \"page.auth.SsoDetailsSearch\");\n            });\n        </script>\n\n	    <input type='hidden' name='locale' id='locale' value='"
    + alias3(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":59},"end":{"line":38,"column":69}}}) : helper)))
    + "' />\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"egencia-global-header"),depth0,{"name":"egencia-global-header","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n	    <div id=\"login-base-display\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"v2/sso-vanity-search"),depth0,{"name":"v2/sso-vanity-search","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"securityToken") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </body>\n</html>\n";
},"usePartial":true,"useData":true}));