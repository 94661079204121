(function ($, EG) {
    'use strict';

    var $delegate, INTL_TEL_INPUT_CONST, DATA_FULL_NUM;

    $delegate = $('body');
    INTL_TEL_INPUT_CONST = 'iti';
    DATA_FULL_NUM = 'data-fullnum';

    function PhoneView() {}

    PhoneView.prototype = {
        constructor: PhoneView,
        initPhonePlugin: function (options) {
            var phoneType,
                self,
                allowDropdown;

            phoneType = options.inputField.attr('data-phoneType');
            self = this;
            allowDropdown = _.isUndefined(options.allowDropdown) ? true : options.allowDropdown;

            self.clearPhonePluginDOM(options.inputField);

            options.inputField.intlTelInput({
                allowDropdown: allowDropdown,
                initialCountry: options.country || '',
                nationalMode: true,
                numberType: phoneType,
                separateDialCode: true,
                customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                    return uitk.i18n.msg('phone_number_placeholder_eg_prefix') + ' ' + selectedCountryPlaceholder;
                },
            });

            //On focus out of phone field number should be validated and formatted
            $delegate.off('blur', '.phone-format').on('blur', '.phone-format', function (e, countryData) {
                var $field = $(this),
                    num = $field.val();
                $field.attr(DATA_FULL_NUM, $field.intlTelInput('getNumber'));
                $field
                    .closest('.phone-field')
                    .find('.number')
                    .val(num);
                self.validateAndFormatPhoneNumber($field);
            });

            //on change of country for phone, format phone based on country
            $delegate.off('countrychange', '.phone-format').on('countrychange', '.phone-format', function (e, countryData) {
                var $eTarget = $(e.target),
                    num = $eTarget.intlTelInput('getNumber'),
                    chosenIso2Code = $eTarget.intlTelInput('getSelectedCountryData').iso2,
                    elementId = $eTarget.attr('id'),
                    phoneLabel,
                    phoneTypeCode,
                    phoneModel;
                $eTarget.intlTelInput('setNumber', num);
            });
        },
         getNAPhoneAreacodeNumber(num) {
            return {
                areaCode: num.substr(0, 3),
                number: num.substr(3, 10)
            };
        },

        _updatePhoneHiddenFields: function ($field, telContainer) {
            var phoneVal = $field.val(),
                rawNum,
                countryCode,
                areaCode,
                number,
                fullI8nNum,
                countryData,
                tempNnum;
            if ($.trim(phoneVal)) {
                rawNum = phoneVal.replace(/\D/g, '');
                fullI8nNum = $field.intlTelInput('getNumber');
            } else {
                fullI8nNum = '';
            }
            countryData = $field.intlTelInput('getSelectedCountryData');
            if (EG.isNA) {
                if (rawNum) {
                    tempNnum = this.getNAPhoneAreacodeNumber(rawNum);
                    areaCode = tempNnum.areaCode;
                    number = tempNnum.number;
                }
                countryCode = countryData.dialCode;
                telContainer.find('.country_prefix').val(countryCode);
                telContainer.find('.area_code').val(areaCode);
                telContainer.find('.number').val(number);
            } else {
                telContainer.find('.number').val(fullI8nNum);
            }
        },

        validateAndFormatPhoneNumber: function ($field) {
            var phoneVal = $.trim($field.val()),
                telContainer = $field.closest('.input-group').parent(),
                errorMsg = I18n.t('auth_validation_phone_invalid');

            if (phoneVal) {
                if ($field.intlTelInput('isValidNumber')) {
                    var fullI8nNum = $field.intlTelInput('getNumber'),
                        countryData = $field.intlTelInput('getSelectedCountryData'),
                        telFormatedValue = intlTelInputUtils.formatNumber(
                            fullI8nNum,
                            countryData.iso2,
                            intlTelInputUtils.numberFormat.NATIONAL
                        );

                    // Truncate leading zero appended at start of area code due to formatting
                    if (telFormatedValue.charAt(0) === '0' && phoneVal.charAt(0) !== telFormatedValue.charAt(0)) {
                        telFormatedValue = telFormatedValue.slice(1);
                    }

                    $field.val(telFormatedValue);

                    telContainer.removeClass('invalid');
                    telContainer
                        .find('.input-message')
                        .html('')
                        .addClass('hidden');

                    this._updatePhoneHiddenFields($field, telContainer);

                    return;
                }
                telContainer.find('.number').val('INVALID_PHONE');
                telContainer.addClass('invalid');
                telContainer
                    .find('.input-message')
                    .html(errorMsg)
                    .removeClass('hidden');

                return;
            }

            telContainer.removeClass('invalid');
            telContainer
                .find('.input-message')
                .html('')
                .addClass('hidden');
        },

        clearPhonePluginDOM: function ($field) {
            var container = $field.parent();
            if (container.hasClass(INTL_TEL_INPUT_CONST)) {
                $field.removeAttr('placeholder').val($field.attr(DATA_FULL_NUM));
                container.before($field).remove();
            }
        },
    };

    EG.Auth.Presenters.PhoneView = new PhoneView();
})(jQuery, EG);
