Handlebars.registerPartial('layouts/default', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n<head>\n    <meta content=IE=edge http-equiv=X-UA-Compatible>\n    <!-- stylesheets -->\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-css"),depth0,{"name":"partials/uitk/core-css","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||alias2).call(alias1,{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"stylesheet":"a3app-core"},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":78}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||alias2).call(alias1,{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"stylesheet":"manage-accounts-core"},"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":12,"column":88}}})) != null ? stack1 : "")
    + "\n\n\n    <script>\n		window.EG = "
    + container.escapeExpression((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EG") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":16,"column":14},"end":{"line":16,"column":25}}}))
    + " || {};\n	</script>\n\n    "
    + ((stack1 = (lookupProperty(helpers,"block")||(depth0 && lookupProperty(depth0,"block"))||alias2).call(alias1,"title",{"name":"block","hash":{},"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "\n\n</head>\n\n<body id=\"defaultBody\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div id = \"main-pane\"> \n        "
    + ((stack1 = (lookupProperty(helpers,"block")||(depth0 && lookupProperty(depth0,"block"))||alias2).call(alias1,"content",{"name":"block","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":29}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/core-js"),depth0,{"name":"partials/uitk/core-js","data":data,"indent":"    \t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n        <!-- Your app's common scripts go here and each View's scripts comes through \"more-scripts\" below. -->\n        "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"auth-extended"},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":89}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"appHostedAssetPath") : stack1),"bundleName":"turnstileVerification"},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":97}}})) != null ? stack1 : "")
    + "\n\n        <!-- Each View has one Presenter and possibly other JavaScript needs. Those go in the \"more-scripts\" partial of each View, which then gets put here: -->\n        "
    + ((stack1 = (lookupProperty(helpers,"block")||(depth0 && lookupProperty(depth0,"block"))||alias2).call(alias1,"more-scripts",{"name":"block","hash":{},"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":36,"column":34}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/footer"),(depth0 != null ? lookupProperty(depth0,"hf") : depth0),{"name":"partials/uitk/footer","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "     </div>\n\n</body>\n\n</html>\n";
},"usePartial":true,"useData":true}));