Handlebars.registerPartial('msteams-close-popup', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                microsoftTeams.authentication.notifySuccess(\"\");\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                microsoftTeams.authentication.notifyFailure(\"\");\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n<html dir=\"ltr\">\n	<head>\n	    <title>Close Egencia sign in popup</title>\n	    <link rel=\"stylesheet\" type=\"text/css\" href=\"/styles/bot-auth.css\" media=\"screen\" >\n	    <link rel=\"stylesheet\" type=\"text/css\" href=\"/styles/msteams-16.css\" media=\"screen\" >\n	    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\" >\n	</head>\n    <body class=\"theme-light\">\n        <script src=\"https://statics.teams.cdn.office.net/sdk/v1.6.0/js/MicrosoftTeams.min.js\" integrity=\"sha384-mhp2E+BLMiZLe7rDIzj19WjgXJeI32NkPvrvvZBrMi5IvWup/1NUfS5xuYN5S3VT\" crossorigin=\"anonymous\"></script>\n        <script type=\"text/javascript\">\n            microsoftTeams.initialize();\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isSuccess") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "        </script>\n    </body>\n</html>";
},"useData":true}));