Handlebars.registerPartial('partials/recaptcha-content', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"recaptcha\">\n        <div class=\"g-recaptcha\"\n             data-sitekey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"recaptcha_client_key") || (depth0 != null ? lookupProperty(depth0,"recaptcha_client_key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recaptcha_client_key","hash":{},"data":data,"loc":{"start":{"line":7,"column":27},"end":{"line":7,"column":51}}}) : helper)))
    + "\"\n             data-callback=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"success_call_back") || (depth0 != null ? lookupProperty(depth0,"success_call_back") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"success_call_back","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":49}}}) : helper)))
    + "\"\n             data-expired-callback=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"expired_call_back") || (depth0 != null ? lookupProperty(depth0,"expired_call_back") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"expired_call_back","hash":{},"data":data,"loc":{"start":{"line":9,"column":36},"end":{"line":9,"column":57}}}) : helper)))
    + "\"\n        >\n        </div>\n        <input type='hidden' name='recaptcha-enabled' id='recaptcha-enabled' value='true' />\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"captchaFields") : depth0)) != null ? lookupProperty(stack1,"captchaEnabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true}));