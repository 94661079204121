function initializeEGForOmniture(EG, userId, companyId) {
	EG.user ={};
 	EG.authenticatedEntity = {};

 	if (userId == null) {
 		EG.user.user_id = -1;
 	} else {
 		EG.user.user_id = userId;
 	}

 	EG.user.bucket_id = -1;
 	EG.user.product_id = -1;

 	if (companyId == null) {
 		EG.user.company_id = -1;
 	} else {
 		EG.user.company_id = companyId;
 	}

 	EG.authenticatedEntity.user_id = -1;
 	EG.authenticatedEntity.company_id = -1;
 	EG.user.activeProfile = {};
 	return EG;
}


function sendPageNameToOmniture(uitk, pageName) {
	uitk.analytics.set("pageName",pageName);
	// set LOB
	uitk.analytics.set("prop2", "Auth");
	uitk.analytics.set("eVar2", "Auth");
	uitk.analytics.send();
}

function setPageNameAnalyticsData(uitk, pageName) {
	uitk.analytics.set("pageName",pageName);
	// set LOB
	uitk.analytics.set("prop2", "Auth");
	uitk.analytics.set("eVar2", "Auth");
}

function setFailedLoginAnalyticsDataSendingMarket(uitk, value, market) {
	setFailedLoginAnalyticsData(uitk, value + " " + market);
}
// function setFailedLoginAnalyticsData(uitk, value) {
// 	uitk.analytics.set("prop36", value);
// }


function setUserId(uitk, value) {
	uitk.analytics.set("eVar11", value);
	uitk.analytics.set("eVar14", value);
	uitk.analytics.set("prop11", value);
	uitk.analytics.set("prop14", value);
}

function clearUserRole(uitk) {
	uitk.analytics.set("eVar55", null);
	uitk.analytics.set("prop55", null);
}


function setCompanyId(uitk, value) {
	uitk.analytics.set("eVar22",value);
	uitk.analytics.set("prop17", value);
}

function sendSuccessfulLoginAnalyticsData(uitk) {
	trackEventAnalyticsData(uitk, 'event26', 'Auth.Successful.Login', "Successful Login");
}

function setFailedLoginAnalyticsData(uitk, value) {
	uitk.analytics.set("prop36", value);
}

function sendDataToOmniture(uitk) {
	uitk.analytics.send();
}

function sendCaptchaRefreshAnalyticsDataSendingMarket(uitk, value, market) {
	sendCaptchaRefreshAnalyticsData(uitk, value + " " + market);
}

function sendCaptchaRefreshAnalyticsData(uitk, value) {
	uitk.analytics.set("eVar28",value);
	uitk.analytics.set("prop16", value);
	uitk.analytics.send();
}

function setCaptchaRefreshAnalyticsDataSendingMarket(uitk, value, market) {
	setCaptchaRefreshAnalyticsData(uitk, value + " " + market);
}

function setCaptchaRefreshAnalyticsData(uitk, value) {
	uitk.analytics.set("eVar28",value);
	uitk.analytics.set("prop16", value);
}

function sendClickAnalyticsData(context, linkType, rfrrId) {
	uitk.analytics.track(linkType, {linkObj: context, linkId: rfrrId})
}

function sendClickAnalyticsDataWithMarketingCloudId(context, linkType, rfrrId) {
	uitk.analytics.set("eVar100", getUniqueId(rfrrId));
	uitk.analytics.track(linkType, {
		linkObj: context,
		linkId: rfrrId,
		linkTrackVars: "eVar100",
	});
	uitk.analytics.set("eVar100" , null);
}

function getUniqueId(omnitureTag) {
	const sExp = s_exp ;
	let uniqueSearchID = '';
	if(sExp) {
		const marketingCloudVisitorID = sExp.marketingCloudVisitorID
        uniqueSearchID = marketingCloudVisitorID + Date.now();
	} else {
		uniqueSearchID = 'Err_InitUniqueID_' + omnitureTag;
	}
	return uniqueSearchID;
}

function sendUserRoleTypeFromTokenAnalyticsData(userId, linkName, tokenJson) {
	if(userId && linkName && tokenJson && tokenJson.user && tokenJson.user.profiles) {
		var user = _.find(tokenJson.user.profiles, function(profile) {return userId == profile.user_id})
		if(user && user.role_type) {
			var options = [{prop:"prop55", value:user.role_type}, {prop:"eVar55", value:user.role_type}];
			sendCustomVarAnalyticsData(linkName, options);
		}
	}
}

/**
 *  Used to track any custom data. Refer uitk doc site for details
 *
 *  linkName - custom name to track
 *  propValues - array of values of the form {prop:"prop55", value:"abc"}
 *
 */
function sendCustomVarAnalyticsData( linkName, propValues, context) {
	var trackVars = [];
	if(propValues) {
		for(var i=0; i<propValues.length;i++) {
			if(propValues[i] && propValues[i].prop && propValues[i].value) {
				uitk.analytics.set(propValues[i].prop, propValues[i].value);
				trackVars.push(propValues[i].prop);
			}
		}
		var options = {linkName: linkName, linkTrackVars:trackVars.join(",")};
		if(context) {
			options[linkObj] = context;
		}
		uitk.analytics.track("o", options);
	}
}

/**
 * sends the given page interaction to Omniture
 * @param uitk
 * @param value
 */
function sendPageInteraction(uitk, value) {
	uitk.analytics.set("eVar28",value);
	uitk.analytics.set("prop16", value);
}

/**
 * sets the given page interaction to Omniture
 * @param uitk
 * @param value
 */
function setPageInteraction(uitk, value) {
    uitk.analytics.set("eVar28",value);
    uitk.analytics.set("prop16", value);
}

/**
 * Method to set page status to Omniture
 * @param displayTag
 */
function setPageStatus(displayTag) {
	uitk.analytics.set("prop18", displayTag);
	uitk.analytics.set("eVar26", displayTag);
}

/**
 * Method to send page status to Omniture
 * @param linkName
 * @param displayTag
 */
function sendPageStatus(linkName, displayTag) {
	setPageStatus(displayTag);
	uitk.analytics.track("o", {
		linkName: linkName,
		linkTrackVars: "prop18,eVar26"
	});
}

function trackEventAnalyticsData(uitk, eventName, pageStatus, linkName) {
	uitk.analytics.set('events', eventName);
	setPageStatus(pageStatus);
	uitk.analytics.track('o', {
		linkName: linkName,
		linkTrackVars: "prop18,eVar26",
		linkTrackEvents: eventName
	});
}
