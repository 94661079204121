Handlebars.registerPartial('partials/email-disambiguation-modal', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    \n            <div class=\"center\">\n                <form onsubmit=\"return false\">\n                    <h3>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_noemail",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":63}}}))
    + "</h3>\n                    \n                    <div>\n                        <div class=\"input-group\">\n                            <input id=\"emailModalUsername\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userName",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":8,"column":84},"end":{"line":8,"column":124}}}))
    + "\" value=\""
    + alias3(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "\" name=\"emailModalUsernameName\"  class=\"replay-safe\"></input>\n                        </div>    \n                        \n                        <div class=\"input-group\">\n                            <input id=\"emailModalPassword\" type=\"password\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_password",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":12,"column":88},"end":{"line":12,"column":128}}}))
    + "\" value=\"\" name=\"emailModalPasswordName\"></input>\n                        </div>                   \n                        \n                        <div class=\"input-group\">     \n                            <input id=\"emailModalEmail\" type=\"email\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_email",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":16,"column":82},"end":{"line":16,"column":119}}}))
    + "\" value=\"\" name=\"emailModalEmailName\"  class=\"replay-safe\"></input>\n                        </div>    \n                    </div>\n                \n                    <p id=\"emailModelError\" class = \"emailModelErrorMessage\"></p>\n                    \n                    <button class=\"btn btn-action btn-med    \" onClick = \"emailModalMerge(this)\" id=email_modal_button type=\"submit\">\n                        <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_addAccounts",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":23,"column":48},"end":{"line":23,"column":91}}}))
    + "</span>\n                    </button>\n                </form>\n            </div>\n        \n\n";
},"useData":true}));