Handlebars.registerPartial('partials/vanity-redirection-info-modal', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<img src="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"imagePath") || (depth0 != null ? lookupProperty(depth0,"imagePath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imagePath","hash":{},"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":23}}}) : helper)))
    + " alt=\"redirection_img\" id=\"vanity-redirection-modal-image\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "	<img src=\"images/redirection-image.png\" alt=\"rediretion_img\" id=\"vanity-redirection-modal-image\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h4 id=\"vanity-redirection-info-modal-sub-heading\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"vanity_redirection_modal_sub_heading",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":14,"column":75},"end":{"line":14,"column":125}}}))
    + "</h4>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imagePath") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "\n    <div class=\"nested-grid\">\n        <div id=\"redirection-info-modal-div\">\n            <div class=\"row\">\n                <div class=\"col col-12\"  id=\"vanity-redirection-modal-heading-div\">\n                    <span class=\"loader loading loader-secondary\"></span><h3 id=\"vanity-redirection-info-modal-heading\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"vanity_redirection_modal_heading",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":12,"column":120},"end":{"line":12,"column":166}}}))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSubHeading") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n     </div>\n</div>\n";
},"useData":true}));