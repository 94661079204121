Handlebars.registerPartial('v2/login-form', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"input-group hp-field\">\n                                <input id=\"hp-login\" name=\"hp-login\" type=\"text\" value=\"\">\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"site-content\" id=\"login-form-container\">\n    <div class=\"auth-header-wrapper-fullwidth align-center\">\n        <h1 class=\"font-light\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"login_page_header",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":87}}}))
    + "</h1>\n    </div>\n    <div class=\"auth-content-wrapper\">\n        <div class=\"box\">\n            <div class=\"box-padded\">\n                <div class=\"egencia-login-page\">\n                    <form method=\"post\" action=\"javascript:void(0);\" novalidate>\n                        <p class=\"alert-text-error\" id=\"login-error-message\">"
    + ((stack1 = alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"errorMessage") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                        <div class=\"input-group\">\n                            <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_userName",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":12,"column":35},"end":{"line":12,"column":100}}}))
    + "</label>\n                            <input id=\"userName\" name=\"userName\" type=\"text\" autofocus value=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"userName") : stack1), depth0))
    + "\"\n                            maxlength="
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"userNameMaxLength") : stack1), depth0))
    + " class=\"replay-safe\">\n                        </div>\n                        <div class=\"input-group input-icon-right\">\n                            <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_password",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":100}}}))
    + "</label>\n                            <input id=\"password\" name=\"password\" type=\"password\" value=\"\">\n                            <i id=\"password-visibility-toggle\" class=\"icon icon-viewedalt\"\n                               oncontextmenu=\"return false;\"></i>\n                        </div>\n\n                        <div class=\"caps-alert-warn displayNone \" data-control=\"alert\">\n                            <i class=\"icon icon-warnalt\"></i>\n                            <span class=\"alert-message\">\n\n                                        <p> "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"passwordCapsLockWarning",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":27,"column":44},"end":{"line":27,"column":106}}}))
    + "</p>\n                                    </span>\n                        </div>\n\n                        <div id=\"email-disambig\" class=\"input-group hidden\">\n                            <label>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"manageYourAccount_email",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":32,"column":35},"end":{"line":32,"column":97}}}))
    + "</label>\n                            <input id=\"disambigEmailAddress\" name=\"disambigEmailAddress\" type=\"email\" value=\"\"\n                                   class=\"replay-safe\">\n                            <input type='hidden' name='disambigEmailShown' id='disambigEmailShown' value='false'/>\n                        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"honeypotEnabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":42,"column":31}}})) != null ? stack1 : "")
    + "\n                        <div id=\"recaptcha-block\">\n\n                        </div>\n\n                        <div id=\"turnstile-block\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/turnstile-content"),depth0,{"name":"partials/turnstile-content","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n\n                        <div id=\"captcha-parent-login\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/captcha-content"),depth0,{"name":"partials/captcha-content","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n\n\n                        <button class=\"btn btn-action btn-med\" target=\"_parent\" type=\"submit\" id=\"authLoginSubmit\">\n                            <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"egenciaLogin_login",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":58,"column":52},"end":{"line":58,"column":109}}}))
    + "</span>\n                        </button>\n\n                        <div class=\"captcha-error-message displayNone\">\n                            <p class=\"alert-text-error \" id=\"captcha-error-message\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"captcha_empty_response_error",{"name":"i18nMsg","hash":{"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":62,"column":84},"end":{"line":63,"column":82}}}))
    + "</p>\n                        </div>\n\n\n                        <p>"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"egenciaLogin_sso_label","sso-login","javascript:void(0)","login-links",{"name":"i18nMsg","hash":{"locale":((stack1 = (depth0 != null ? lookupProperty(depth0,"loginModel") : depth0)) != null ? lookupProperty(stack1,"locale") : stack1)},"data":data,"loc":{"start":{"line":67,"column":27},"end":{"line":68,"column":63}}}))
    + "\n                        </p>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/gam-advertisement-content"),depth0,{"name":"partials/gam-advertisement-content","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </form>\n\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true}));