//This function renders the date in the given locale and replaces the content of the given id with the new date
function dateRender(date, id, locale) {
	if (date == null) {
		return;
	}
    var inputDate;
	// In safari the given format doesn't work
    if(window.safari && window.safari.pushNotification){
    	if(typeof date === 'string'){
			date = date.replace(/-/g, "/");
			date= date.replace(/T/," ");
		}
		inputDate = new Date(date);
	}
	else{
        inputDate = new Date(date);
	}

	if (inputDate == null || inputDate.toString() == "Invalid Date") {
		document.getElementById(id).innerHTML = date;
		return;
	}
	var localDate = inputDate.toLocaleDateString("en-us");
	var dateParts = localDate.split(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
	//var isoLocaleDate = dateParts[3] + "-" + dateParts[1] + "-" + dateParts[2];
	localisedDate = uitk.i18n.date(dateParts[3],dateParts[1],dateParts[2], {style : 'medium', locale : locale })

	var localisedTime = inputDate.toLocaleTimeString();
	if (localisedTime == null || localisedTime == "Invalid Date") {
		localisedTime = "";
	} else {
		var timeString = inputDate.toTimeString();
		var  timeParts = timeString.split(':');
		localisedTime = uitk.i18n.time(timeParts[0],timeParts[1], {locale : locale});

		if (localisedTime == null || localisedTime == "Invalid Date") {
			localisedTime = inputDate.getHours() + ":";
			if (inputDate.getMinutes() < 10) {
				localisedTime += "0";
			}
			localisedTime += inputDate.getMinutes();
			localisedTime = uitk.i18n.time(localisedTime, {locale : locale});
		}
	}
	document.getElementById(id).innerHTML = localisedDate + " " + localisedTime + " " + inputDate.toString().split("(")[1].split(")")[0];
}

function localizeAndFormatDate(date,id) {
	var inputDate,localisedDate,localisedTime,locale;
	locale = EG.currentLocale;
	if (date === null || date === undefined || date ===  '') {
		return;
	}
	inputDate = new Date(date);
	if (inputDate === null || inputDate.toString() === 'Invalid Date') {
		return date;
	}
	localisedDate = uitk.i18n.date(inputDate.getFullYear(),inputDate.getMonth()+1,inputDate.getDate(), {style : 'medium', locale : locale })
	localisedTime = uitk.i18n.time(inputDate.getHours(),inputDate.getMinutes(), {locale : locale});
	if(localisedDate && localisedTime){
		document.getElementById(id).innerHTML = localisedDate + " " + localisedTime + " " + inputDate.toString().split("(")[1].split(")")[0];
	}
	else{
		document.getElementById(id).innerHTML = inputDate;
	}
}

function getRenderedDate(date) {
	if (!date) {
		return;
	}
	var inputDate = new Date(date);
	if (inputDate.toString() == "Invalid Date") {
		return;
	}
	var locale = getLocaleFromCookie();
	var localDate = inputDate.toLocaleDateString("en-us");
	var dateParts = localDate.split(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
	var isoLocaleDate = dateParts[3] + "-" + dateParts[1] + "-" + dateParts[2];
	localisedDate = uitk.i18n.date(isoLocaleDate, {style : 'medium', locale : locale })

	var localisedTime = inputDate.toLocaleTimeString();
	if (localisedTime == null || localisedTime == "Invalid Date") {
		localisedTime = "";
	} else {
		localisedTime = uitk.i18n.time(inputDate.toLocaleTimeString(), {locale : locale});

		if (localisedTime == null || localisedTime == "Invalid Date") {
			localisedTime = inputDate.getHours() + ":";
			if (inputDate.getMinutes() < 10) {
				localisedTime += "0";
			}
			localisedTime += inputDate.getMinutes();
			localisedTime = uitk.i18n.time(localisedTime, {locale : locale});
		}
	}
	return (localisedDate + " " + localisedTime + " " + inputDate.toString().split("(")[1].split(")")[0]);
}

function getLocaleFromCookie() {
	if (uitk.readCookie("egencia_locale")) {
		return uitk.readCookie("egencia_locale");
	}
	return "en_US";
}

function showTermsOfUse() {
	var termsModal = uitk.modal.create({
	    modalId: 'termsOfUseModal',
	    title :uitk.i18n.msg("terms_and_policy_term_link_title"),
	    closeBtn : true}, true
	);

	termsModal.open();
}

function showPrivacyPolicy() {
	var privacyModal = uitk.modal.create({
	    modalId: 'privacyPolicyModal',
	    title : uitk.i18n.msg("egenciaLogin_privacy_policy_title"),
	    closeBtn : true}, true
	);

	privacyModal.open();
}

// This function removes a specific URL param from the URL
function removeParamater(paramTobeRemoved, sourceURL) {
    var newUrl = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === paramTobeRemoved) {
                params_arr.splice(i, 1);
            }
        }
        newUrl = newUrl + "?" + params_arr.join("&");
    }
    return newUrl;
}

function disableButton(buttonId) {
	$("#" + buttonId).attr("disabled", true);
}

function addLoaderClass(elementId) {
	if (elementId) {
		var element = $("#"+elementId);
		if (element) {
			element.addClass("loader loading");
		}
	}
};

function removeLoaderClass(elementId) {
	if (elementId) {
		var element = $("#"+elementId);
		if (element) {
			element.removeClass("loader loading");
		}
	}
};

function enableElement(elementId) {
	if (elementId) {
		var element = $("#"+elementId);
		if (element) {
			element.prop("disabled", false);
		}
	}
};

function disableElement(elementId) {
	if (elementId) {
		var element = $("#"+elementId);
		if (element) {
			element.prop("disabled", true);
		}
	}
};

function validateEmail(email) {
	var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(email);
};
