Handlebars.registerPartial('partials/gtm-analytics', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "     <!-- Google Tag Manager -->\n     <script>\n         (function(w, d, s, l, i) {\n             w[l] = w[l] || [];\n             w[l].push({\n                 'gtm.start': new Date().getTime(),\n                 event: 'gtm.js'\n             });\n             var f = d.getElementsByTagName(s)[0],\n                 j = d.createElement(s),\n                 dl = l != 'dataLayer' ? '&l=' + l : '';\n             j.async = true;\n             j.defer=true;\n             j.src =\n                 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;\n             f.parentNode.insertBefore(j, f);\n         })(window, document, 'script', 'dataLayer', 'GTM-TDDX2G');\n     \n     </script>\n     <!-- End Google Tag Manager -->\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"skipAnalytics") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":30,"column":11}}})) != null ? stack1 : "");
},"useData":true}));